import * as urls from "../../api/C4S Decommission - Replacement/url";
import { getRequest } from "../../../services/RestServices";

export const getRejectReasonsData = async () => {
  try {
    return await getRequest(urls.REJECT_REASONS_DATA + `?category=${"SO_REJECT"}&application=${"C360"}`);
  } catch (err) {
    return err;
  }
};
