import React, { Fragment, useState, useEffect, useContext } from "react";
import { css } from "@emotion/react";
import styled from "styled-components";
import { Modal, ModalBody, ModalFooter } from "@vds/modals";
import { TextLink } from "@vds/buttons";
import { Title } from "@vds/typography";
import { Icon } from "@vds/icons";
import { Table, TableHead, TableBody, TableRow, Cell, TableHeader } from "@vds/tables";
import { Loader } from "@vds/loaders";
import { DropdownSelect } from "@vds/selects";
import searchIcon from "../../assets/icons/search_blk.png";
import { uniq, fill, groupBy, update, max, sortBy } from "lodash";
import { InputNumber } from "primereact/inputnumber";
import { SLButton } from "../../components/core/Controls";
import { MediumText, SmallText, FlexRow } from "../../components/core/Utility/index";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { getJobDuration } from "../../services/scheduler/getJobDuration";
import { getProcessingCodes } from "../../services/scheduler/getProcessingCodes";
import { jobDurationFormatter } from "../../utility/sessionData";
import JobCalculatorNotification from "./JobCalculatorNotification";
import "./JobLengthModal.css";

export const CustomisedInput = css`
  border: 0.0625rem solid #d8dada;
  width: 85%;
  height: 2rem;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto !important;
    margin-top: 0px !important;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
`;

const StyledModal = css`
  overflow: hidden;
  height: 560px;
  min-height: 560px;
  padding: 1rem;
  width: 670px;
  max-width: 100%;
  @media (max-width: 767px) {
    // width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 46px 1rem;
    // padding-right: 2px;
  }
  & span {
    padding-right: 5px;
  }
  & div:nth-of-type(2) {
    & span[class^="Wrapper"] > div {
      width: calc(100% - -36px) !important;
      height: calc(-4px + 70vh) !important;
    }
  }
  & div:nth-of-type(2) {
    overflow-y: unset;
    & span {
      & div {
        & div {
          padding-right: 5px !important;
        }
        & span:nth-of-type(2) {
          & span {
            width: 4px !important;
          }
        }
      }
    }
  }
  div[data-testid="modal-body"] {
    padding: 0px;
    div {
      ::-webkit-scrollbar {
        display: block !important;
      }
      ::-webkit-scrollbar-thumb {
        display: inline-block !important;
        background-color: #c0c0c0 !important;
      }
      ::-webkit-scrollbar-track {
        background: #f1f1f1 !important;
      }
      ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
      }
    }
  }
  & footer {
    padding: 1rem 0px 0px 0px;
    //margin-left: 1.3rem;
    //border-top: 1px solid black;
  }
  & mark {
    background-color: transparent !important;
  }
`;
const CustomTable = styled(Table)`
  margin-bottom: 0px;
  table-layout: fixed;
  thead {
    border-bottom: 2px solid #000000;
    margin-bottom: 3px;
    th {
      padding: 1rem 0rem 0.75rem 0rem;
    }
  }
  tbody {
    tr {
      td {
        padding: 8px 2px 5px 0px;
      }
      table {
        margin-bottom: 0rem;
        thead {
          border-bottom: 2px solid black;
          th {
            padding-top: 0rem;
          }
        }
        tbody {
          tr:last-child {
            border-bottom: 1.5px solid black;
          }
        }
      }
    }
  }
`;

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    height: 2rem;
    & span {
      & span {
        & div {
          & select {
            padding: 0.2rem 1rem 0.2rem 0.5rem;
            font-size: 12px;
            color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
            text-wrap: wrap;
            line-height: 0.8rem;
            &:disabled {
              -webkit-text-fill-color: #000000;
              background-color: #ffffff;
              border-bottom: 1px solid #000000;
              opacity: ${(props) => (props.disabled ? "0.4" : "")};
            }
          }
          & span {
            top: 0.35rem;
            right: 0.5rem;
            & svg {
              width: 0.8rem;
              height: 0.8rem;
              min-width: 0.8rem;
              min-height: 0.8rem;
              display:${(props) => (props.hideErrorImg ? "none !important" : "")};
            }
          }
        }
        & div {
          & div {
            & p {
              display: none;
            }
          }
        }
      }
      }
    }
  }
`;

export const CustomIcon = styled.div`
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  width: 1rem;
  height: 1rem;
  & svg {
    outline: none;
  }
`;

export const accordionView = css`
  color: red;
  max-height: 35vh;
  transition: max-height 0.5s ease;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  height: 30px;
  border-bottom: 1px solid black;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

const CSPJobLengthModal = ({
  cspJobLengthModal,
  setCSPJobLengthModal,
  setApptLength,
  jobId,
  isNewJob,
  salesOrgId,
  setExistingJobQuantityList,
  notification,
  isAddServices,
  scheduleType,
}) => {
  const { products, jobDurationDetails, jobLengthHours, jobLengthMinutes } = useContext(AccountDetailsContext);
  const [jobDurationList, setJobDurationList] = useState([]);
  const [jobItemsList, setJobItemsList] = useState([]);
  const [calculate, setCalculate] = useState(true);
  const [handle, setHandle] = useState(true);
  const [clear, setClear] = useState(true);
  const [totalJobLength, setTotalJobLength] = useState(jobLengthHours.jobLengthHours);
  const [totalJobLengthMin, setTotalJobLengthMin] = useState(jobLengthMinutes.jobLengthMinutes);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedJob, setExpandedJob] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [processingCodes, setProcessingCodes] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    getJobDurationFields();
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setCSPJobLengthModal(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    expandedJob && setExpandedJob(null);
    let timerId;
    if (jobItemsList.length) {
      timerId = setTimeout(() => {
        let jobsDurationList = [...jobItemsList];
        jobsDurationList = searchText
          ? jobsDurationList.filter(
              (job) =>
                job.mmId.materialId.includes(searchText.toLowerCase()) || job.mmId.materialName.toLowerCase().includes(searchText.toLowerCase())
            )
          : jobsDurationList;
        setJobDurationList(jobsDurationList);
      }, 100);
    }
    return () => {
      timerId && clearTimeout(timerId);
    };
  }, [searchText]);

  const getJobDurationFields = async () => {
    setIsLoading(true);
    let processCodesResponse = await getProcessingCodes("Install");
    if (processCodesResponse.status === 200 && processCodesResponse.data?.data?.length) {
      let processCodes = processCodesResponse.data.data.map((code) => ({ ...code, processCode: `${code.code} - ${code.description}` }));
      setProcessingCodes(processCodes);
      processCodes = processCodes.reduce((processCodesObj, code) => ({ ...processCodesObj, [code["code"]]: code }), {});
      let jobDurationList = jobDurationDetails.jobDurationDetails,
        productsList = products.products;
      if (jobDurationList.length) {
        jobDurationDetailsHandler(jobDurationList, productsList, true, processCodes);
        setIsLoading(false);
      } else {
        let sapIdList = uniq(productsList.map((product) => product.SAPProductID)).join();
        let response =
          isAddServices || isNewJob ? await getJobDuration(null, salesOrgId, sapIdList) : await getJobDuration(jobId, salesOrgId, sapIdList);
        if (response.status === 200 && response?.data?.data?.length) {
          jobDurationList = response.data.data;
          if (!isNewJob) {
            setExistingJobQuantityList(jobDurationList.filter((item) => item.quantity));
          }
          let jobDetails = response.data.data.map((job) => ({
            ...job,
            techRefQuantity: job.quantity,
          }));
          jobDurationDetails.setJobDurationDetails(jobDetails);
          jobDurationDetailsHandler(jobDurationList, productsList, false, processCodes);
        } else {
          setCSPJobLengthModal(false);
          notification("Unable to fetch Job Duration Details due to an unexpected error.");
        }
        setIsLoading(false);
      }
    } else {
      setCSPJobLengthModal(false);
      notification("Unable to fetch Processing Codes due to an unexpected error.");
    }
  };

  const jobDurationDetailsHandler = (jobDurationList, productsList, existing, processCodes) => {
    let finalList = [];
    jobDurationList.map((materialId) => {
      let materialIdList = materialId;
      let products = productsList.filter((product) => product.SAPProductID === materialIdList.sapId);
      products = products.length
        ? products.map((product) => {
            let matchedTechRefId = materialIdList.techRefId && materialIdList.techRefId === product.TechRefId ? materialIdList : null;
            return {
              ...product,
              techRefQuantity: existing ? matchedTechRefId?.techRefQuantity ?? 0 : matchedTechRefId?.quantity ?? 0,
              techRefLineId: matchedTechRefId?.materialLineId ?? null,
            };
          })
        : [
            {
              TechRefId: null,
              OrderDetailType: null,
              techRefQuantity: existing ? materialIdList?.techRefQuantity ?? 0 : materialIdList?.quantity ?? 0,
              techRefLineId: materialIdList?.materialLineId ?? null,
            },
          ];
      let index = finalList.findIndex((data) => {
        return data?.mmId.materialId === materialIdList.materialId && data?.mmId.sapId === materialIdList.sapId;
      });
      if (index === -1) {
        finalList.push({
          mmId: {
            ...materialIdList,
            duration: materialIdList.duration ?? 0,
            processingCode: materialIdList.quantity ? materialIdList.processingCode : "ZSPO",
            processCode: materialIdList.quantity ? processCodes[materialIdList.processingCode]?.processCode ?? "ZSPO - Service" : "ZSPO - Service",
            processCodeError: false,
            quantity: materialIdList.quantity,
            techRefId: null,
            techRefQuantity: 0,
            materialLineId: null,
          },
          products,
        });
      } else {
        let data = finalList[index];
        let productIndex = data.products.findIndex((record) => {
          return record.TechRefId === materialIdList.techRefId;
        });
        if (productIndex !== -1) {
          finalList[index].products[productIndex].techRefQuantity = existing ? materialIdList?.techRefQuantity ?? 0 : materialIdList?.quantity ?? 0;
          finalList[index].products[productIndex].techRefLineId = materialIdList?.materialLineId ?? null;
        }
      }
    });
    finalList = sortBy(finalList, "mmId.sapId");
    setJobDurationList(finalList);
    setJobItemsList(finalList);
  };

  const quantityChangeHandler = (quantity, index, productIndex, job) => {
    let updatedJobList = [...jobDurationList];
    quantity = quantity > 100 || quantity === null ? 0 : quantity;
    job = update(job, `products[${productIndex}].techRefQuantity`, () => quantity);
    job = update(job, `mmId.quantity`, () => job.products.reduce((totalQuantity, product) => totalQuantity + product.techRefQuantity, 0));
    // if (!quantity && job.mmId.processCodeError) {
    //   job = update(job, "mmId.processCodeError", () => false);
    // } else if (quantity && !job.mmId.processingCode) {
    //   job = update(job, "mmId.processCodeError", () => true);
    // }
    updatedJobList = [...fill(updatedJobList, job, index, index + 1)];
    setJobDurationList(updatedJobList);
    let updatedJobItemList = [...jobItemsList],
      jobItemIndex = updatedJobItemList.findIndex(
        (jobItem) => jobItem.mmId.materialId === job.mmId.materialId && jobItem.mmId.sapId === job.mmId.sapId
      );
    updatedJobItemList = [...fill(updatedJobItemList, job, jobItemIndex, jobItemIndex + 1)];
    setJobItemsList(updatedJobItemList);
    calculate && setCalculate(false);
    !handle && setHandle(true);
    clear && setClear(false);
  };

  const processingCodeHandler = (value, index, job) => {
    let updatedJobList = [...jobDurationList];
    job = update(job, "mmId.processCode", () => value);
    job = update(job, "mmId.processingCode", () => value.split(" -")[0]);
    if (job.mmId.processCodeError) {
      job = update(job, "mmId.processCodeError", () => false);
    }
    updatedJobList = [...fill(updatedJobList, job, index, index + 1)];
    setJobDurationList(updatedJobList);
    let updatedJobItemList = [...jobItemsList],
      jobItemIndex = updatedJobItemList.findIndex((jobItem) => jobItem.mmId.materialId === job.mmId.materialId);
    updatedJobItemList = [...fill(updatedJobItemList, job, jobItemIndex, jobItemIndex + 1)];
    setJobItemsList(updatedJobItemList);
    calculate && handleDoneDisableBtn(totalJobLength);
    clear && setClear(false);
  };

  const handleCalculate = () => {
    !calculate && setCalculate(true);
    let jobQuantityList = jobItemsList.filter((job) => job["mmId"].quantity);
    let totalDurationMin = jobQuantityList.reduce((totalDuration, job) => totalDuration + job["mmId"].duration * job["mmId"].quantity, 0);
    setTotalJobLengthMin(totalDurationMin);
    // setTotalJobLength(Math.round(totalDurationMin / 60));
    if (totalDurationMin <= 60) {
      setTotalJobLength(Math.ceil(totalDurationMin / 60));
      handleDoneDisableBtn(Math.ceil(totalDurationMin / 60));
    } else {
      let hours = parseInt(totalDurationMin / 60);
      let remainingminutes = totalDurationMin % 60;
      let minutesToAdd = 0;
      if (remainingminutes > 15 && remainingminutes < 46) {
        minutesToAdd = 30;
      } else if (remainingminutes > 45 && remainingminutes < 60) {
        minutesToAdd = 60;
      }
      let totalmins = hours * 60 + minutesToAdd;
      setTotalJobLength(totalmins / 60);
      handleDoneDisableBtn(totalmins / 60);
    }
  };

  const handleDoneDisableBtn = (totalLength) => {
    if (!isLoading && totalLength > 0) {
      handle && jobItemsList.every((job) => !job.mmId.processCodeError) && setHandle(false);
    } else {
      !handle && jobItemsList.some((job) => job.mmId.processCodeError) && setHandle(true);
    }
  };

  const handleDone = () => {
    setCSPJobLengthModal(false);
    setApptLength(totalJobLength);
    jobLengthMinutes.setJobLengthMinutes(totalJobLengthMin);
    jobLengthHours.setJobLengthHours(totalJobLength);
    let durationList = [];
    jobItemsList.map((job) => {
      job.products.map((techRef) => {
        return durationList.push({
          ...job["mmId"],
          orderType: techRef.OrderDetailType,
          techRefId: techRef.TechRefId,
          techRefQuantity: techRef.techRefQuantity,
          materialLineId: techRef.techRefLineId,
        });
      });
    });
    // let materialLineId = max(durationList.map((job) => (job.materialLineId ? Number(job.materialLineId) : 0)));
    // durationList = durationList.map((job) => {
    //   let materialUniqueId;
    //   if (job.materialLineId) {
    //     materialUniqueId = job.materialLineId;
    //   } else if (job.techRefQuantity) {
    //     materialLineId += 10;
    //     materialUniqueId = materialLineId.toString().padStart(6, "0");
    //   } else {
    //     materialUniqueId = null;
    //   }
    //   return {
    //     ...job,
    //     materialLineId: materialUniqueId,
    //   };
    // });
    setJobDurationList(durationList);
    setJobItemsList(durationList);
    jobDurationDetails.setJobDurationDetails(durationList);
  };

  const handleClear = () => {
    !calculate && setCalculate(true);
    !handle && setHandle(true);
    !clear && setClear(true);
    setTotalJobLength(0);
    setSearchText("");
    let durationList = jobItemsList.map((job) => ({
      ...job,
      mmId: {
        ...job.mmId,
        quantity: 0,
        processingCode: "ZSPO",
        processCode: "ZSPO - Service",
        processCodeError: false,
        techRefId: null,
        techRefQuantity: 0,
        materialLineId: null,
      },
      products: job.products.map((techRefId) => ({
        ...techRefId,
        techRefQuantity: 0,
      })),
    }));
    setJobDurationList(durationList);
    setJobItemsList(durationList);
  };

  return (
    <Modal
      role="dialog"
      ariaLabel="Job Length Calculator"
      opened={cspJobLengthModal}
      disableOutsideClick
      css={StyledModal}
      id={scheduleType === "AUTO" && totalJobLength > 10 ? "job-length-modal" : ""}
      closeButton={
        <>
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <Title size="medium">Job Length Calculator</Title>
            <div
              onClick={() => {
                !isLoading && setCSPJobLengthModal(false);
              }}
              style={{ marginTop: "0.3rem" }}
            >
              <Icon
                role="img"
                aria-label="close"
                alt="close-icon"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        </>
      }
    >
      <ModalBody>
        <JobCalculatorNotification scheduleType={scheduleType} totalJobLength={totalJobLength} />
        <FlexRow css={{ marginTop: "1rem", justifyContent: "space-between", alignItems: "flex-end" }}>
          <SearchInputWrapper>
            <SearchInput value={searchText} type="text" onChange={(event) => setSearchText(event.target.value)} placeholder="Search"></SearchInput>
            <img src={searchIcon} height="20px" width="20px"></img>
          </SearchInputWrapper>
          <SmallText css={{ marginRight: "1.4rem" }}>
            <TextLink
              role="link"
              aria-label={totalJobLength ? `Recalculate` : `Calculate`}
              css={{ fontSize: "12px", fontWeight: "700", fontFamily: "Verizon-NHG-eTX" }}
              disabled={calculate}
              onClick={handleCalculate}
            >
              {totalJobLength ? `Recalculate` : `Calculate`}
            </TextLink>
          </SmallText>
        </FlexRow>
        {/* <SmallText style={{ marginTop: "1rem", marginLeft: "85%" }}>
          <TextLink
            role="link"
            aria-label={totalJobLength ? `Recalculate` : `Calculate`}
            css={{ fontSize: "12px", fontWeight: "700", fontFamily: "Verizon-NHG-eTX" }}
            disabled={calculate}
            onClick={handleCalculate}
          >
            {totalJobLength ? `Recalculate` : `Calculate`}
          </TextLink>
        </SmallText> */}
        <CustomTable tabIndex={0} role="table" topLine="none">
          <TableHead role="row">
            <TableHeader tabIndex={0} role="columnheader" width="17%">
              <SmallText bold>MMID</SmallText>
            </TableHeader>
            <TableHeader tabIndex={0} role="columnheader" width="32%">
              <SmallText bold>Install Type</SmallText>
            </TableHeader>
            <TableHeader tabIndex={0} role="columnheader" width="18%">
              <SmallText bold>Contract ID</SmallText>
            </TableHeader>
            <TableHeader tabIndex={0} role="columnheader" width="27%">
              <SmallText bold>Processing Code</SmallText>
            </TableHeader>
            <TableHeader role="columnheader" width="6%"></TableHeader>
          </TableHead>
        </CustomTable>
        {/* </Table>
         <div css={{ height: "35vh", overflowY: "scroll" }}>
          <Loader active={isLoading} fullscreen={false} />
          <Table topLine="none" striped={false} css={CustomTableHeader}> */}
        <div css={{ height: "35vh", overflowY: "scroll" }}>
          <CustomTable topLine="none" role="table">
            {/* <div css={{ height: "35vh", overflowY: "scroll" }}> */}
            <Loader active={isLoading} fullscreen={false} />
            <TableBody role="rowgroup">
              {jobDurationList.map((job, index) => {
                let materialIdData = job.mmId,
                  techRefIdList = job.products;
                let hasQty =
                  techRefIdList?.length > 0 && techRefIdList?.filter((product) => product.techRefQuantity <= 100 && product.techRefQuantity > 0);
                let color;
                let fontWeight;
                if (hasQty.length > 0) {
                  color = "#004B1C";
                  fontWeight = "bold";
                }
                return (
                  <Fragment key={index}>
                    <TableRow
                      role="row"
                      css={{
                        borderBottom: materialIdData.materialId === expandedJob && "none",
                        borderTop: materialIdData.materialId === expandedJob && "1.5px solid #000",
                      }}
                    >
                      <Cell tabIndex={0} role="cell" width="17%">
                        <SmallText bold={fontWeight} color={color}>
                          {materialIdData.materialId}
                        </SmallText>
                      </Cell>
                      <Cell tabIndex={0} role="cell" width="32%">
                        <SmallText bold={fontWeight} color={color} css={{ paddingRight: "1rem" }}>
                          {materialIdData.materialName}
                        </SmallText>
                      </Cell>
                      <Cell tabIndex={0} role="cell" width="18%">
                        <SmallText bold={fontWeight} color={color}>
                          {materialIdData.sapId || "--"}
                        </SmallText>
                      </Cell>
                      <Cell tabIndex={0} role="cell" width="27%">
                        <CustomDropdown
                          type="text"
                          tabIndex={0}
                          css={{ color: color, fontWeight: fontWeight }}
                          ariaLabel="processing code"
                          value={materialIdData.processCode || "Please Select"}
                          onChange={(e) => processingCodeHandler(e.target.value, index, job)}
                          error={materialIdData.processCodeError}
                          hideErrorImg={materialIdData.processCodeError}
                          errorText="Please select processing code"
                        >
                          <>
                            {processingCodes.map((code) => (
                              <option key={code.code}>{code.processCode}</option>
                            ))}
                          </>
                        </CustomDropdown>
                      </Cell>
                      {materialIdData.materialId === expandedJob && currentIndex === index ? (
                        <Cell tabIndex={0} role="cell" width="6%">
                          <CustomIcon
                            onClick={() => {
                              setExpandedJob(null);
                              setCurrentIndex(null);
                            }}
                          >
                            <Icon name="up-caret" aria-label="up-caret" alt="up-caret" size="small" color="#000000" />
                          </CustomIcon>
                        </Cell>
                      ) : (
                        <Cell role="cell" tabIndex={0} width="6%">
                          <CustomIcon
                            onClick={() => {
                              setExpandedJob(materialIdData.materialId);
                              setCurrentIndex(index);
                            }}
                          >
                            <Icon name="down-caret" aria-label="down-caret" alt="down-caret" size="small" color="#000000" />
                          </CustomIcon>
                        </Cell>
                      )}
                    </TableRow>
                    {materialIdData.materialId === expandedJob && currentIndex === index && techRefIdList.length > 0 && (
                      <TableRow role="row" css={{ borderBottom: "none" }}>
                        <Cell role="cell" colSpan={5}>
                          <Table role="table" topLine="none">
                            <TableHead role="row">
                              <TableHeader role="columnheader" width="17%">
                                <SmallText bold>Quantity</SmallText>
                              </TableHeader>
                              <TableHeader role="columnheader" width="32%">
                                <SmallText bold>TechRef ID</SmallText>
                              </TableHeader>
                              <TableHeader role="columnheader" width="51%">
                                <SmallText bold>Order Detail Type</SmallText>
                              </TableHeader>
                            </TableHead>
                            <TableBody role="rowgroup">
                              {techRefIdList.map((product, productIndex) => (
                                <TableRow role="row" key={product.TechRefId || productIndex}>
                                  <Cell role="cell" width="16.5%">
                                    <InputNumber
                                      value={product.techRefQuantity <= 100 ? product.techRefQuantity : 0}
                                      aria-label={product.techRefQuantity <= 100 ? product.techRefQuantity : 0}
                                      min={0}
                                      tabIndex={0}
                                      max={100}
                                      showButtons
                                      onChange={(e) => quantityChangeHandler(e.value, index, productIndex, job)}
                                      css={CustomisedInput}
                                    ></InputNumber>
                                  </Cell>
                                  <Cell role="cell" width="32.5%">
                                    <SmallText>{product.TechRefId || "--"}</SmallText>
                                  </Cell>
                                  <Cell role="cell" width="51%">
                                    <SmallText>{product.OrderDetailType || "--"}</SmallText>
                                  </Cell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </Cell>
                      </TableRow>
                    )}
                  </Fragment>
                );
              })}
            </TableBody>
            {/* </div> */}
          </CustomTable>
        </div>
      </ModalBody>
      <footer
        style={{ borderTop: "1px solid #000000" }}
        id={scheduleType === "AUTO" && totalJobLength > 10 ? "sticky-footer-bottom" : "sticky-footer-bottom"}
      >
        <div>
          <FlexRow css={{ justifyContent: "space-between", width: "100%" }}>
            <div>
              <SmallText tabIndex={0} bold>
                Job Length (Hours)
              </SmallText>
              <MediumText tabIndex={0}>{jobDurationFormatter(totalJobLength)}</MediumText>
            </div>
            <FlexRow>
              <SLButton ariaLabel="Done" primary style={{ paddingRight: "48px", marginRight: "20px" }} disabled={handle} onClick={handleDone}>
                Done
              </SLButton>
              <SLButton ariaLabel="Clear" secondary style={{ paddingRight: "48px", marginRight: "0px" }} onClick={handleClear} disabled={clear}>
                Clear
              </SLButton>
            </FlexRow>
          </FlexRow>
        </div>
      </footer>
    </Modal>
  );
};

export default CSPJobLengthModal;
