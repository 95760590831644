import { Button } from "@vds/buttons";
import { Grid, Row, Col } from "@vds/grids";
import styled from "styled-components";
import React, { useState, useEffect, lazy, useContext } from "react";
import { CompactCol, FlexRow, SmallText, SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/Utility";
import { viewBatch } from "../../services/Aggregator/viewBatch";
import { UPDATE_ACTIVE_UNITS_CARRIER_DROPDOWN_VALUES, VIEW_BATCH_ACTIVATION_COL } from "../../constants/UpdateActiveUnitsConstants";
import { getProperty } from "../../utility/getProperty";
import { getProfilesForAtt } from "../../services/updateActiveUnits/getProfilesForAtt";
import { getRatePlansForVerizon } from "../../services/updateActiveUnits/getRatePlansForVerizon";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { UploadContext } from "../../context/uploadContext";
import { performActivation } from "../../services/updateActiveUnits/performActivation";
import { CheckStatusMetadata } from "../CheckStatusPage";
import { addPage } from "../../utility/navigation";
import { Header, SLDropDown } from "../../components/core/Controls";
import { Loader } from "@vds/loaders";
import { getProfilesForVodafone } from "../../services/updateActiveUnits/getProfilesForVodafone";
const LocationStyle = styled.div`
  margin-top: 10px;
  padding-left: 20px;
`;

const CustomRow = styled(Row)`
  > div {
    margin-left: 0px !important;
    padding-left: 0px !important;
  }
`;

export const StyledCol1 = styled(Col)`
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 0;
`;

const UnitInfo = styled.div`
  background-color: var(--unit-info-box-bg-color);
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.4);
  overflow: auto;
  padding: 10px;
`;

const ViewBatch = () => {
  const attRP = [
    { key: "Telogis - 1MB Intl Plan", value: "Telogis - 1MB Intl Plan" },
    { key: "Telogis - 25MB Intl Plan", value: "Telogis - 25MB Intl Plan" },
    { key: "Telogis - 100MB Intl Plan", value: "Telogis - 100MB Intl Plan" },
    { key: "Fleetmatics 1MB Pooled 3G Plan", value: "FLTK" },
    { key: "Fleetmatics NA 3MB Pooled Plan", value: "FL03" },
  ];

  const attProfiles = [
    {
      custPrefix: "FLT",
      networkTypeName: "Orange",
      default: false,
      Profile_Name: "AJ",
    },
    {
      custPrefix: "FLT",
      networkTypeName: "Orange",
      default: false,
      Profile_Name: "21",
    },
    {
      custPrefix: "FLT",
      networkTypeName: "Orange",
      default: false,
      Profile_Name: "20",
    },
    {
      custPrefix: "FLT",
      networkTypeName: "Orange",
      default: false,
      Profile_Name: "19",
    },
  ];

  const verizonRegionOptions = [
    { key: "US", value: "US" },
    { key: "Canada", value: "Canada" },
  ];

  const Carriers = {
    Verizon: "Verizon",
    ATT: "ATT",
    Telstra: "Telstra",
    Vodafone: "Vodafone",
  };

  const [batchMetadata, setBatchMetadata] = useState({});
  const [vehiclesList, setVehiclesList] = useState([]);
  const [error, setError] = useState("");

  const [verizonRatePlan, setVerizonRatePlan] = useState([]);
  const [vodafoneProfile, setVodafoneProfile] = useState([]);
  const [attRatePlan] = useState(attRP);
  const [attProfile, setAttProfile] = useState(attProfiles);
  const [selectedRatePlanList, setSelectedRatePlanList] = useState([]);
  const [selectedProfileList, setSelectedProfileList] = useState([]);
  const [selectedEsnList, setSelectedEsnList] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState("");
  const [selectedRatePlan, setSelectedRatePlan] = useState("");
  const [selectedProfile, setSelectedProfile] = useState("");
  const [carrierList, setCarrierList] = useState([]);
  const [notificationErr, setNotificationErr] = useState("");
  const { batchNumber } = useContext(UploadContext);
  const { batchNumber: bnumber } = batchNumber;
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verizonRatePlanLoader, setVerizonRatePlanLoader] = useState(false);
  const [attProfilesLoader, setAttProfilesLoader] = useState(false);
  const [vodafoneProfilesLoader, setVodafoneProfilesLoader] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("US");
  const [verizonRegions, setVerizonRegions] = useState(verizonRegionOptions);

  const activate = async (payload) => {
    setLoading(true);
    let response = await performActivation(payload);
    if (response.status == 200) {
      addPage(CheckStatusMetadata.id);
    } else {
      setNotificationErr(response.response?.data.message ?? "Error performing Activation");
    }
    setLoading(false);
  };

  const handleActivate = async () => {
    try {
      var isSuccess = validateDataforActivation();
      if (isSuccess) {
        const request = {
          esnList: selectedEsnList,
          carrier: selectedCarrier,
          ratePlan: selectedRatePlan,
          profile: selectedProfile,
        };
        if (selectedCarrier === Carriers.Verizon && checkVerizonVDDOrEATRatePlan()) {
          request.region = selectedRegion;
        } else {
          request.region = "US";
        }
        activate(request);
      }
    } catch {
      setError("Unexpected error");
    }
  };

  const validateDataforActivation = () => {
    var isValidationSuccess = true;
    if (selectedEsnList.length == 0) {
      setNotificationErr("No units were checked, nothing to activate");
      isValidationSuccess = false;
    } else if (selectedCarrier == "ATT") {
      for (var device of selectedUnits) {
        if (device.iccid == null || device.iccid == "") {
          setNotificationErr("Device with ESN " + device.esn + " does not have an ICCID. Batch Activation Failed.");
          isValidationSuccess = false;
          break;
        }
      }
    }
    return isValidationSuccess;
  };

  const BATCH_DISPLAY_LEFT = {
    "Batch : ": batchMetadata.batchNumber,
    "Brand : ": batchMetadata.brand,
    "Filename : ": batchMetadata.batchFilename,
    "Upload Date : ": batchMetadata.uploadDate,
    "Uploaded by User : ": batchMetadata.uploadByUser,
  };

  const BATCH_DISPLAY_RIGHT = {
    "Location : ": batchMetadata.location,
    "Box Type : ": batchMetadata.boxName,
    "Box Model : ": batchMetadata.boxModel,
    "Units Type : ": batchMetadata.unitType,
    "Units Count : ": batchMetadata.unitsCount,
  };

  const handleCarrierSelect = (event) => {
    setSelectedCarrier(event.target.value);
    switch (event.target.value) {
      case Carriers.ATT:
        setProfileRatePlansAndRegion(attRatePlan, attProfile);
        break;
      case Carriers.Telstra:
        setProfileRatePlansAndRegion([], []);
        break;
      case Carriers.Verizon:
        setProfileRatePlansAndRegion(verizonRatePlan, []);
        break;
      case Carriers.Vodafone:
        setProfileRatePlansAndRegion([], vodafoneProfile);
        break;
      default:
        setProfileRatePlansAndRegion([], []);
    }
  };

  const setProfileRatePlansAndRegion = (ratePlan, profile) => {
    setSelectedRatePlanList(mapToRadioBox(ratePlan));
    setSelectedProfileList(mapProfileToRadioBox(profile));
    setSelectedRatePlan(ratePlan.length > 0 ? ratePlan[0].value : "");
    setSelectedProfile(profile.length > 0 ? profile[0].Profile_Name : "");
    setSelectedRegion("US");
  };

  const mapToRadioBox = (dataToBeMapped) => {
    return dataToBeMapped.map((data) => {
      return {
        id: data.value,
        text: data.key,
        subtext: " ",
        value: data.value,
      };
    });
  };

  const mapProfileToRadioBox = (dataToBeMapped) => {
    return dataToBeMapped.map((data) => {
      return {
        id: data.Profile_Name,
        text: data.Profile_Name,
        subtext: " ",
        value: data.Profile_Name,
      };
    });
  };

  const getViewBatchData = async () => {
    let response = await viewBatch({
      batchNumber: bnumber,
    });
    let err = getProperty(response, "error", "");
    if (err == "") {
      response.batch.uploadDate = new Date(response.batch.uploadDate).toLocaleString();
      setBatchMetadata(response.batch);
      setVehiclesList(response.vehicles);
    } else {
      setNotificationErr("Batch is Empty");
    }
  };

  useEffect(() => {
    getProfilesAndRatePlans();
    getViewBatchData();
    setSelectedCarrier(UPDATE_ACTIVE_UNITS_CARRIER_DROPDOWN_VALUES[0].value);
    setCarrierList(
      UPDATE_ACTIVE_UNITS_CARRIER_DROPDOWN_VALUES.map((carrier) => {
        return {
          id: carrier.value,
          text: carrier.key,
          subtext: " ",
          value: carrier.value,
        };
      })
    );
    setProfileRatePlansAndRegion(attRatePlan, attProfile);
  }, []);

  useEffect(() => {
    if (attProfilesLoader && verizonRatePlanLoader && vodafoneProfilesLoader) {
      setLoading(false);
      setProfileRatePlansAndRegion(attRatePlan, attProfile);
    }
  }, [attProfilesLoader, verizonRatePlanLoader, vodafoneProfilesLoader]);

  const getProfilesAndRatePlans = () => {
    setLoading(true);
    getVerizonRatePlans();
    getAttProfiles();
    getVodafoneProfiles();
  };

  const getVodafoneProfiles = async () => {
    let response = await getProfilesForVodafone();
    if (response.status == 200) {
      setVodafoneProfile(
        response.data.data.map((profile) => {
          return {
            Profile_Name: profile.customerServiceProfileName,
          };
        })
      );
    } else {
      setNotificationErr("Error Fetching Vodafone profiles");
    }
    setVodafoneProfilesLoader(true);
  };

  const getRegionDropdown = () => {
    return (
      <SLDropDown
        width="100%"
        label="Select Region"
        onChange={(e) => {
          setSelectedRegion(e.target.value);
        }}
        value={selectedRegion}
      >
        {verizonRegions.map((region, index) => {
          return (
            <option key={index} value={region.value}>
              {region.key}
            </option>
          );
        })}
      </SLDropDown>
    );
  };
  const checkVerizonVDDOrEATRatePlan = () => {
    if (selectedRatePlan === "4gMeridianPlan" || selectedRatePlan === "98126 4g VZC Plan") {
      return true;
    }
    return false;
  };

  const getVerizonRatePlans = async () => {
    let response = await getRatePlansForVerizon();
    if (response.status == 200) {
      setVerizonRatePlan(response.data.data);
    } else {
      setNotificationErr("Error Fetching Verizon Rate plans");
    }
    setVerizonRatePlanLoader(true);
  };

  const getAttProfiles = async () => {
    let response = await getProfilesForAtt();
    if (response.status == 200) {
      setAttProfile(response.data.data);
    } else {
      setNotificationErr("Error Fetching ATT profiles");
    }
    setAttProfilesLoader(true);
  };

  const getSelectedItemsCallback = (selectedItems) => {
    setSelectedUnits(selectedItems);
    setSelectedEsnList(
      selectedItems.map((item) => {
        return item.esn;
      })
    );
  };

  const getProfilesDropDown = () => {
    return (
      selectedProfileList.length > 0 && (
        <SLDropDown width="100%" label="Select Profiles" onChange={(e) => setSelectedProfile(e.target.value)}>
          {selectedProfileList.map((profile, index) => {
            return (
              <option key={index} value={profile.value}>
                {profile.text}
              </option>
            );
          })}
        </SLDropDown>
      )
    );
  };

  const getRatePlansDropDown = () => {
    return (
      selectedRatePlanList.length > 0 && (
        <SLDropDown
          width="100%"
          label="Select Rate Plan"
          onChange={(e) => {
            setSelectedRatePlan(e.target.value);
          }}
        >
          {selectedRatePlanList.map((rateplan, index) => {
            return (
              <option key={index} value={rateplan.value}>
                {rateplan.text}
              </option>
            );
          })}
        </SLDropDown>
      )
    );
  };

  return (
    <>
      <Header>View Batch - {bnumber}</Header>
      <LocationStyle>
        {notificationErr != "" ? (
          <SLNotification
            type="error"
            title
            onCloseButtonClick={() => setNotificationErr("")}
            subtitle={notificationErr}
            fullBleed={false}
            inlineTreatment={false}
          />
        ) : (
          <></>
        )}
        <Loader active={loading} />
        <SLGridLayout>
          <SLRowLayout>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
              <UnitInfo>
                {Object.keys(BATCH_DISPLAY_LEFT).map((batch, index) => {
                  return (
                    <>
                      <strong key={index}>
                        <SmallText css={{ display: "inline-block" }} bold>
                          {batch}{" "}
                        </SmallText>
                      </strong>
                      <SmallText css={{ display: "inline-block" }} key={index}>
                        {BATCH_DISPLAY_LEFT[batch]}
                      </SmallText>
                      <br />
                    </>
                  );
                })}
              </UnitInfo>
            </CompactCol>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
              <UnitInfo>
                {Object.keys(BATCH_DISPLAY_RIGHT).map((batch, index) => {
                  return (
                    <>
                      <strong key={index}>
                        <SmallText css={{ display: "inline-block" }} bold>
                          {batch}{" "}
                        </SmallText>
                      </strong>
                      <SmallText css={{ display: "inline-block" }} key={index}>
                        {BATCH_DISPLAY_RIGHT[batch]}
                      </SmallText>
                      <br />
                    </>
                  );
                })}
              </UnitInfo>
            </CompactCol>
          </SLRowLayout>
        </SLGridLayout>

        <GridComponent
          isSelectionEnabled={true}
          isExportEnabled={true}
          noDataMessage="No data available to display"
          rows={vehiclesList}
          columns={VIEW_BATCH_ACTIVATION_COL}
          title="Select ESN's for Activation"
          getSelectedItems={getSelectedItemsCallback}
        />
        <br />
        <FlexRow justifyContent="space-between">
          <div css={{ flexGrow: 1 }}>
            <SLDropDown width="100%" label="Select Carrier" onChange={(e) => handleCarrierSelect(e)}>
              {carrierList.map((carrier, index) => {
                return (
                  <option key={index} value={carrier.value}>
                    {carrier.text}
                  </option>
                );
              })}
            </SLDropDown>
          </div>
          <div css={{ paddingLeft: "40px", flexGrow: 1 }}>{getRatePlansDropDown()}</div>
          {selectedProfileList.length > 0 && <div css={{ paddingLeft: "40px", flexGrow: 1 }}>{getProfilesDropDown()}</div>}
          {selectedCarrier === Carriers.Verizon && checkVerizonVDDOrEATRatePlan() && (
            <div css={{ paddingLeft: "40px", flexGrow: 1 }}>{getRegionDropdown()}</div>
          )}
        </FlexRow>
        <SLRowLayout>
          <StyledCol1 colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
            <Button size="small" secondary={false} onClick={handleActivate}>
              Activate
            </Button>
          </StyledCol1>
        </SLRowLayout>
      </LocationStyle>
    </>
  );
};

export default ViewBatch;

export const ActivateViewBatchMetadata = {
  name: "View Activate Batch",
  id: "view activate batch",
  component: lazy(() => import("./ViewBatch")),
  route: "/",
};
