import { getRequest } from "../../../services/RestServices";
import * as urls from "../../api/scheduler/url";

export const getReasonsList = async () => {
  try {
    return await getRequest(urls.REASONS_DATA + `?category=${"DE_RE"}&application=${"C360"}`);
  } catch (err) {
    return err;
  }
};
