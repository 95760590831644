import { useState, useEffect, useContext, lazy, useCallback } from "react";
import styled from "@emotion/styled";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { Loader } from "@vds/loaders";
import { VIEW_SERVICE_REQUESTS_INFO_COL } from "../../constants/CheckInConstants";
import { CheckinContext } from "../../context/checkinContext";
import { getLineItems } from "../../services/checkin/getLineItems";
import { EditWorkTicketPage } from "./EditWorkTicket";
import { addPage } from "../../utility/navigation";
import { SLNotification } from "../../components/core/Utility";
import { CheckEsnStatusPage } from "./CheckEsnStatus";

const ContentWrapper = styled.div`
  padding: 10px 10px;
`;

export default function ViewServiceRequestsInfo({ vtu }) {
  const { workTicket, esn } = useContext(CheckinContext);
  const { workTicket: workTicketValue } = workTicket;
  const [errValue, setErrValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { setEsn: setEsnValue } = esn;
  const allInstallations = [];
  switch (process.env.REACT_APP_ENV) {
    case "uat":
      allInstallations.push(
        "1300000064",
        "1300000068",
        "1300000024",
        "1300000042",
        "1300000011",
        "1300000032",
        "1300000000",
        "1300000020",
        "1300000062",
        "1300241215",
        "1300241218",
        "1300241219",
        "1300241220",
        "1300000017",
        "1300000016",
        "1300000061",
        "1300000060"
      );
      break;
    case "prod":
      allInstallations.push(
        "1300000064",
        "1300000068",
        "1300000115",
        "1300000024",
        "1300000042",
        "1300000126",
        "1300000113",
        "1300000032",
        "1300000000",
        "1300000011",
        "1300000020",
        "1300000062",
        "1300241215",
        "1300241218",
        "1300241219",
        "1300241220",
        "1300000016",
        "1300000061",
        "1300000060",
        "1300000019",
        "1300000017"
      );
      break;
    default:
      allInstallations.push(
        "1300000064",
        "1300000068",
        "1300000102",
        "1300000024",
        "1300000126",
        "1300000042",
        "1300000094",
        "1300000092",
        "1300000032",
        "1300000000",
        "1300000011",
        "1300000020",
        "1300000062",
        "1300000192",
        "1300000191",
        "1300000181",
        "1300000174",
        "1300000016",
        "1300000061",
        "1300000060",
        "1300000019",
        "1300000017"
      );
      break;
  }
  const fetchData = useCallback(async () => {
    setIsLoading(true);

    try {
      const response = await getLineItems({
        workItemId: workTicketValue?.WorkTicketID,
        esn: vtu?.esn,
      });
      if (response.lineItems.data == null) {
        setErrValue("We couldn't find line items for workTicket:", workTicketValue?.WorkTicketID || "");
      } else {
        const serviceRequestItems = response?.lineItems?.data?.ServiceRequestItems || [];

        const baseData = {
          workTicketNumber: response?.lineItems?.data?.workTicketNumber || "",

          vendor: response?.lineItems?.data?.vendor || "",

          installationAddress: response?.lineItems?.data?.installationAddress || "",

          crmReferenceId: response?.lineItems?.data?.crmReferenceId || "",

          status: response?.lineItems?.data?.status || "",

          workTicketType: response?.lineItems?.data?.workTicketType || "",

          ESN: response?.lineItems?.data?.ESN || "",

          Address: response?.lineItems?.data?.Address || "",
        };

        let poducts = [];
        let prObj = { prodName: [], prodId: "", objectId: "", peripherals: [] };
        serviceRequestItems.forEach((x) => {
          if (allInstallations.indexOf(x.productId) > -1) {
            poducts.push({ lineItem: x.productName });
            prObj.prodName = poducts;
            prObj.prodId = x.productId;
            prObj.objId = x.objectId;
          } else {
            if (x.productName !== "") prObj.peripherals.push(x.productName);
          }
        });

        const transformedData = [
          {
            ...baseData,
            objectId: prObj.objId,
            productId: prObj.prodId,
            productName: prObj.prodName,
            nonBasePeripherals: prObj.peripherals.join(", ") || "",
          },
        ];
        setTableData(transformedData);
      }
    } catch (error) {
      setErrValue(error);
    } finally {
      setIsLoading(false);
    }
  }, [workTicketValue, vtu]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleClickCallback = (data) => {
    if ((vtu && vtu?.esn !== null) || vtu === undefined) {
      setEsnValue(vtu?.esn);
      addPage(EditWorkTicketPage.id, {
        input: vtu,
        workTicketNr: workTicketValue?.WorkTicketID,
        productID: tableData.map((item) => item.productId),
        objectId: tableData.filter((item) => item.productId == data.productId)[0].objectId,
      });
    } else {
      addPage(CheckEsnStatusPage.id, {
        input: data,
        workTicketNr: workTicketValue?.WorkTicketID,
        productID: tableData.map((item) => item.productId),
        objectId: tableData.filter((item) => item.productId == data.productId)[0].objectId,
      });
    }
  };

  return (
    <ContentWrapper>
      <Header>Work Ticket - {workTicketValue?.WorkTicketID}</Header>
      {errValue != "" ? (
        <SLNotification type="error" title onCloseButtonClick={() => setErrValue("")} subtitle={errValue} fullBleed={false} inlineTreatment={false} />
      ) : (
        <></>
      )}
      {isLoading ? (
        <Loader active={isLoading} />
      ) : (
        <GridComponent
          rows={tableData}
          isExportEnabled={true}
          columns={VIEW_SERVICE_REQUESTS_INFO_COL}
          title="Line Items"
          hyperLinkClicked={handleClickCallback}
          noDataMessage="No Line Items associated with this ESN"
        />
      )}
    </ContentWrapper>
  );
}
export const ViewServiceRequestsInfoPage = {
  name: "View Service Requests Info",
  id: "viewservicerequestinfopage",
  component: lazy(() => import("./ViewServiceRequestsInfo")),
  route: "/viewservicerequestsinfo",
};
