import { lazy } from "react";
import { GridComponent } from "../../components/core/Table/GridComponent";
import styled from "styled-components";
import { Grid, Row } from "@vds/grids";
import { Header, SLButton, SLInput } from "../../components/core/Controls";
import { CompactCol, SmallText, SLGridLayout, SLRowLayout } from "../../components/core/Utility";
import { UPDATE_WORK_ORDER_DETAILS } from "../../constants/UpdateWorkOrderDetails";

const UnitInfo = styled.div`
  background-color: var(--unit-info-box-bg-color);
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.4);
  overflow: auto;
  padding: 10px;
`;

const WORKORDER_DISPLAY_RIGHT = {
  "Account: ": "1001489554",
  "Account Name : ": "Symphony Coatings",
  "Technical ID : ": "0986444.975478",
  "Work Ticket : ": "98776",
  "Scanned Count : ": "8",
};

const WorkOrder = () => {
  return (
    <>
      <Header>Work Order</Header>
      <SLGridLayout>
        <SLRowLayout>
          <CompactCol colSizes={{ mobile: 6, tablet: 6, desktop: 6 }}>
            <SLInput type="text" label="Camera ESN" />
            <br></br>
            <SLButton size="small">Add</SLButton>
          </CompactCol>
          <CompactCol colSizes={{ mobile: 6, tablet: 6, desktop: 6 }}>
            <UnitInfo>
              {Object.keys(WORKORDER_DISPLAY_RIGHT).map((order, index) => {
                return (
                  <>
                    <strong key={index}>
                      <SmallText css={{ display: "inline-block" }} bold>
                        {order}{" "}
                      </SmallText>
                    </strong>
                    <SmallText css={{ display: "inline-block" }} key={index}>
                      {WORKORDER_DISPLAY_RIGHT[order]}
                    </SmallText>
                    <br />
                  </>
                );
              })}
            </UnitInfo>
          </CompactCol>
        </SLRowLayout>
        <GridComponent
          isSelectionEnabled={true}
          isExportEnabled={true}
          noDataMessage="Add an ESN to display it in the grid"
          rows={[]}
          columns={UPDATE_WORK_ORDER_DETAILS}
          title="Select ESN's for Activation"
        />
        <SLRowLayout>
          <SLButton size="small">Send to Reveal</SLButton>
          <SLButton size="small">Show errors</SLButton>
          <SLButton size="small">Save</SLButton>
        </SLRowLayout>
      </SLGridLayout>
    </>
  );
};
export default WorkOrder;

export const WorkOrderData = {
  name: "Work Order",
  id: "workorder",
  component: lazy(() => import("./WorkOrder")),
  route: "/WorkOrder",
};
