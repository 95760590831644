import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "@vds/modals";
import { Input } from "@vds/inputs";
import { DropdownSelect } from "@vds/selects";
import { Title } from "@vds/typography";
import Icon from "@vds/icons";
import { css } from "@emotion/react";
import { ESNS_REGEX, LETTERS_SC_REGEX } from "../../constants/ValidationConstants";
import styled from "styled-components";
import { CustomSLButton, FlexRow, SmallText } from "../../components/core/utility";
import { PROCESSING_TYPE_VALUES } from "./utils";
import { MultiSelect } from "primereact/multiselect";
import "./dropdown.css";

const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export const CustomisedMultiDropdown = styled(MultiSelect)`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  width: 100%;
  height: 2.75rem;
  box-shadow: none !important;
  border-radius: 0;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 8px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000 !important;
    background-color: transparent;
  }
  .p-multiselect-label {
    font-size: 0.75rem;
    white-space: wrap;
  }
`;
const CustomisedInput = css`
  margin-top: 0.2rem;
  border: 0.0625rem solid #d8dada;
  width: 30%%;
  height: 2.75rem;
  margin-right: 0.5rem;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active {
    font-size: 12px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin-top: 0px;
    position: relative;
    border: none;
    color: #000000;
    background-color: transparent;
  }
`;
const CustomLabel = css`
  font-size: 0.75rem;
  font-weight: 400;
  font-family: Verizon-NHG-eTX, Helvetica, Ariel, Sans-serif;
`;
const CustomDropdown = styled(DropdownSelect)`
  & div {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
    & label {
      & p {
        font-size: 12px;
        color: ${(props) => (props.disabled ? "#d8dada" : "")};
        & span {
          &::after {
            color: #747676;
          }
        }
      }
    }
  }
  }
  & select {
    font-size: 12px;
    color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      // color: ${(props) => (props.disabled || props.disableValue ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
`;
export const CustomInput = styled(Input)`
  padding-bottom: 0.5rem;
  height: auto;
  width: ${(props) => (props.formWidth ? props.formWidth : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  & label {
    & p {
      font-size: 12px;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & span {
    & span {
      & span {
        width: ${(props) => (props.boxWidth ? props.boxWidth : "")};
      }
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;

export default function ReturnTicketModal({
  returnTicketModalData,
  returnTicketList,
  setReturnTicketModalData,
  saveHandler,
  rtnReson,
  esitEsn,
  retnQty,
  editReturnMaterialProcessingType,
  defaultReturnType,
  editReturnData,
}) {
  const { workTicketData, index } = returnTicketModalData;
  // const mmidDropdownList = workTicketData.filter((item) => item?.salesOrder && item?.MMID?.substring(0, 2) !== "13");
  const mmidDropdownList = workTicketData
    .filter((item) => item?.salesOrder && item?.MMID?.substring(0, 2) !== "13")
    .map((wTData) => {
      let returnQuty = returnTicketList
        ?.map((returnWT) => {
          return returnWT?.returnMaterial?.filter((returnMat) => returnMat.parentWMID === wTData?.id);
        })
        .filter((mmidret) => mmidret?.length > 0)
        .map((item) => {
          return item?.reduce((sum, ritem) => sum + ritem.returnQty, 0);
        })
        .reduce((sum, reItem) => sum + reItem, 0);
      let returnEsn = returnTicketList
        ?.map((returnWT) => {
          return returnWT?.returnMaterial?.filter((returnMat) => returnMat.parentWMID === wTData?.id);
        })
        .filter((mmidret) => mmidret?.length > 0)
        .flat(1)
        .map((item) => {
          return [...(item?.esn?.split(/[|\s]/g) || [])].map((esns) => esns.trim()).filter((esn) => esn !== "");
        })
        .filter((esns) => esns.length > 0)
        .join()
        .split(",");
      return { ...wTData, sumReturnTicketQty: returnQuty, returnTicketEsns: returnEsn };
    });
  const [mmid, setMmid] = useState({
    success: true,
    error: false,
    errorText: "",
    selectedMMIDQty: workTicketData[index].quantity,
    id: workTicketData[index].id,
    mmid: workTicketData[index].MMID,
    mmidPgiDate: workTicketData[index].MmidPgiDate,
    itemDescription: workTicketData[index].itemDescription,
    wtMaterialId: workTicketData[index].wtMaterialId,
    sapId: workTicketData[index].sapId,
    contractId: workTicketData[index].contractId,
    techRefId: workTicketData[index].techRefId,
    esns: workTicketData[index].esns,
    customValue:
      mmidDropdownList[index].sumReturnTicketQty >= mmidDropdownList[index].totalQty
        ? "Please select"
        : `${workTicketData[index].MMID} - ${workTicketData[index].sapId} - ${workTicketData[index].techRefId}`,
    contractName: workTicketData[index].contractItem,
    sumReturnTicketQty: mmidDropdownList[index].sumReturnTicketQty,
  });
  const [returnReason, setReturnReason] = useState({
    success: false,
    error: false,
    errorText: "",
    reason: "",
    requiredEsn: false,
  });
  let esnArray = [...(mmid?.esns[0]?.esn.split(/[|\s]/g) || [])].map((item) => item.trim()).filter((esn) => esn !== "");
  const [esnList, setEsnList] = useState(esnArray ? esnArray : []);
  let esnLists = [];
  const [quantity, setQuantity] = useState({
    success: esitEsn.length == 1 || esnList.length == 1 ? true : false,
    error: false,
    errorText: "",
    value: esitEsn.length == 1 || esnList.length == 1 ? 1 : "",
  });
  const [esns, setEsns] = useState({
    success: true,
    error: false,
    errorText: "",
    value:
      esitEsn && esitEsn.length && esitEsn.length == 1
        ? [esitEsn[0]]
        : esitEsn && esitEsn.length
        ? esitEsn
        : esnList && esnList.length && esnList.length == 1
        ? [esnList[0]]
        : esnList && esnList.length
        ? esnList
        : [],
  });
  const [processingType, setProcessingType] = useState({
    success: true,
    error: false,
    errorText: "",
    value: "",
    description: "",
  });

  const [saveDisability, setSaveDisability] = useState(true);
  const returnReasonList = [
    {
      reason: "Customer Lost (Lost)",
      requiredEsn: true,
    },
    {
      reason: "Lost in Shipment (Lost)",
      requiredEsn: false,
    },
    {
      reason: "Defective Device (Return)",
      requiredEsn: false,
    },
    {
      reason: "Incorrect Equipment (Return)",
      requiredEsn: false,
    },
    {
      reason: "Partial Cancellation",
      requiredEsn: true,
    },
    {
      reason: "Full Cancellation",
      requiredEsn: false,
    },
    {
      reason: "Error with Order",
      requiredEsn: false,
    },
  ];

  useEffect(() => {
    const handleEscape = (e) => e.key === "Escape" && closeHandler;
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    if (returnTicketModalData?.type === "edit") {
      let sapId = editReturnData.sapId || null;
      let techRefId = editReturnData.techRefId || null;
      let mmidSelected = mmidDropdownList.filter((data) => {
        let datasapId = data.sapId || null;
        let datatechRefId = data.techRefId || null;
        return (data = data.MMID === editReturnData.mmid && datasapId === sapId && datatechRefId === techRefId);
      });
      setMmid((prevState) => ({
        ...prevState,
        selectedMMIDQty: mmidSelected[0]?.quantity,
        id: mmidSelected[0]?.id,
        mmid: mmidSelected[0]?.MMID,
        mmidPgiDate: mmidSelected[0]?.MmidPgiDate,
        itemDescription: mmidSelected[0]?.itemDescription,
        wtMaterialId: mmidSelected[0]?.wtMaterialId,
        sapId: mmidSelected[0]?.sapId,
        techRefId: mmidSelected[0]?.techRefId,
        contractId: mmidSelected[0]?.contractId,
        customValue: `${mmidSelected[0]?.MMID} - ${mmidSelected[0]?.sapId} - ${mmidSelected[0]?.techRefId}`,
        contractName: mmidSelected[0]?.contractItem,
      }));
    }
    if (returnTicketModalData?.type === "edit" && editReturnMaterialProcessingType) {
      let retnEditProcess = PROCESSING_TYPE_VALUES.filter((procType) => procType?.code === editReturnMaterialProcessingType);
      setProcessingType((prevState) => ({
        value: retnEditProcess[0]?.code,
        description: retnEditProcess[0]?.description,
        error: false,
        errorText: "",
        success: true,
      }));
    } else if (returnTicketModalData?.type === "add") {
      let defaultType = PROCESSING_TYPE_VALUES.filter((prosType) => prosType?.code === workTicketData[index]?.defaultReturnType);
      setProcessingType((prevState) => ({
        value: defaultType[0]?.code,
        description: defaultType[0]?.description,
        error: false,
        errorText: "",
        success: true,
      }));
    }
  }, [returnTicketModalData, editReturnData]);

  const handleFields = (field, value) => {
    switch (field) {
      case "MMID": {
        let mmid = JSON.parse(value.target[value.target.selectedIndex].getAttribute("data-mmid"));
        setMmid((prevState) => ({
          ...prevState,
          selectedMMIDQty: mmid.quantity,
          id: mmid.id,
          mmid: mmid.MMID,
          mmidPgiDate: mmid.MmidPgiDate,
          itemDescription: mmid.itemDescription,
          wtMaterialId: mmid.wtMaterialId,
          sapId: mmid.sapId,
          techRefId: mmid.techRefId,
          contractId: mmid.contractId,
          customValue: `${mmid.MMID} - ${mmid.sapId} - ${mmid.techRefId}`,
          contractName: mmid.contractItem,
          sumReturnTicketQty: mmid.sumReturnTicketQty,
        }));
        // handleFields("Quantity", quantity.value, mmid.quantity);
        const esnArray = [...(mmid?.esns[0]?.esn.split(/[|\s]/g) || [])].map((item) => item.trim()).filter((esn) => esn !== "");
        setEsnList(esnArray);
        if (esnArray.length == 1) {
          setEsns((prevState) => ({
            ...prevState,
            success: true,
            error: false,
            value: [esnArray[0]],
          }));
          setQuantity({
            success: true,
            error: false,
            errorText: "",
            value: esnArray.length,
          });
        } else {
          setEsns({ success: false, error: false, errorText: "", value: [] });
          setQuantity({
            success: false,
            error: false,
            errorText: "",
            value: "",
          });
        }
        // handleFields("ESNs", mmid.esn);
        let defaultType = PROCESSING_TYPE_VALUES.filter((prosType) => prosType.code === mmid.defaultReturnType);
        setProcessingType((prevState) => ({
          value: defaultType[0]?.code,
          description: defaultType[0]?.description,
          error: false,
          errorText: "",
          success: true,
        }));
        break;
      }
      case "Return Reason": {
        let reason = returnReasonList.find((reason) => reason.reason === value);
        setReturnReason((prevState) => ({ ...prevState, reason: reason.reason, requiredEsn: reason.requiredEsn, success: true }));
        // if (!esns.value) {
        //   setEsns((prevState) => ({ ...prevState, success: false, error: false, value: [] }));
        // }
        // setEsns((prevState) => ({ ...prevState, success: false, error: false, errorText: "", value: "" }));
        break;
      }
      case "Quantity": {
        let numVal = parseInt(value);
        let existingQuantity = 0;
        if (returnTicketModalData?.type === "add") {
          existingQuantity = mmid.selectedMMIDQty - mmid?.sumReturnTicketQty;
        } else {
          existingQuantity = mmid.sumReturnTicketQty == mmid.selectedMMIDQty ? retnQty : mmid.selectedMMIDQty - mmid?.sumReturnTicketQty + retnQty;
        }
        if (value && numVal <= existingQuantity) {
          setQuantity((prevState) => ({
            ...prevState,
            success: true,
            error: false,
            errorText: "",
            value: numVal === 0 ? 1 : numVal,
          }));
        } else if (value === "") {
          setQuantity((prevState) => ({ ...prevState, success: false, error: true, errorText: "Please provide quantity", value: "" }));
        } else {
          setQuantity((prevState) => ({
            ...prevState,
            success: false,
            error: true,
            errorText: `Quantity provided should not exceed the selected MMID's quantity (${existingQuantity})`,
            value: "",
          }));
        }

        break;
      }
      case "ESNs": {
        if (value && value.length) {
          value?.map((val) => {
            esnLists.push(val);
          });
          setEsns({ value: esnLists, error: false, errorText: "", success: true });
        } else {
          setEsns({ value: esnLists, error: true, errorText: "Select feature", success: false });
        }
        handleFields("Quantity", esnLists.length);
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (returnTicketModalData?.type === "edit") {
      if (rtnReson || retnQty) {
        let reason = returnReasonList.filter((reason) => reason.reason === rtnReson);
        setReturnReason((prevState) => ({ reason: reason[0].reason, requiredEsn: reason[0].requiredEsn, success: true }));
        setQuantity((prevState) => ({
          ...prevState,
          success: true,
          error: false,
          errorText: "",
          value: retnQty,
        }));
        setEsns({
          success: true,
          error: false,
          errorText: "",
          value:
            esitEsn && esitEsn.length && esitEsn.length == 1
              ? [esitEsn[0]]
              : esitEsn && esitEsn.length
              ? esitEsn
              : esnList && esnList.length && esnList.length == 1
              ? [esnList[0]]
              : esnList && esnList.length
              ? esnList
              : [],
        });
      }
    }
  }, [rtnReson, esitEsn, retnQty]);

  useEffect(() => {
    if (returnReason.reason !== rtnReson || returnTicketModalData?.type !== "edit") {
      if (esitEsn?.length > 1 || esnList?.length > 1) {
        setEsns((prevState) => ({ success: false, error: false, value: [] }));
        setQuantity({
          success: false,
          error: false,
          errorText: "",
          value: "",
        });
      } else {
        setEsns((prevState) => ({
          success: true,
          error: false,
          value:
            esitEsn && esitEsn.length && esitEsn.length == 1
              ? [esitEsn[0]]
              : esitEsn && esitEsn.length
              ? esitEsn
              : esnList && esnList.length && esnList.length == 1
              ? [esnList[0]]
              : esnList && esnList.length
              ? esnList
              : [],
        }));
        setQuantity({
          success: esitEsn.length == 1 || esnList.length == 1 ? true : false,
          error: false,
          errorText: "",
          value: esitEsn.length == 1 || esnList.length == 1 ? 1 : "",
        });
      }
    } else {
      setEsns({
        success: true,
        error: false,
        errorText: "",
        value:
          esitEsn && esitEsn.length && esitEsn.length == 1
            ? [esitEsn[0]]
            : esitEsn && esitEsn.length
            ? esitEsn
            : esnList && esnList.length && esnList.length == 1
            ? [esnList[0]]
            : esnList && esnList.length
            ? esnList
            : [],
      });
      setQuantity({
        success: true,
        error: false,
        errorText: "",
        value: esitEsn.length == 1 || esnList.length == 1 ? 1 : retnQty,
      });
    }
  }, [returnReason]);

  // const handleESNValidation = (value) => {
  //   if (value) {
  //     if (LETTES_REGEX.test(value)) {
  //       return setEsns((prevState) => ({ ...prevState, success: false, error: true, errorText: "Please provide valid ESN(s)" }));
  //     } else if (ESNS_REGEX.test(value)) {
  //       return setEsns((prevState) => ({ ...prevState, success: true, error: false, value }));
  //     } else {
  //       if (value.length > 11) {
  //         let numbers = value,
  //           numberRegex = /\d+/g;
  //         let numArr = numbers.match(numberRegex);
  //         let len = numArr.length;
  //         if (numArr[len - 1].length === 10) {
  //           value = numArr.join(", ");
  //           return setEsns((prevState) => ({ ...prevState, success: true, error: false, errorText: "Please provide valid ESN(s)", value }));
  //         } else if (numArr[len - 1].length === 11) {
  //           let n1 = numArr[len - 1];
  //           numArr.pop();
  //           n1 = n1.slice(0, 10) + ", " + n1.slice(10);
  //           value = numArr.join(", ") + ", " + n1;
  //         }
  //         return setEsns((prevState) => ({ ...prevState, success: false, error: true, errorText: "Please provide valid ESN(s)", value }));
  //       } else if (value.length === 11) {
  //         value = value.slice(0, 10) + ", " + value.slice(10);
  //       }
  //       return setEsns((prevState) => ({ ...prevState, success: false, error: true, errorText: "Please provide valid ESN(s)", value }));
  //     }
  //   } else {
  //     return setEsns((prevState) => ({ ...prevState, success: false, error: true, errorText: "Please provide ESN(s)", value: "" }));
  //   }
  // };

  const closeHandler = () => {
    setReturnTicketModalData((prevData) => ({ ...prevData, isShown: false }));
  };

  useEffect(() => {
    if (
      mmid.mmid &&
      mmid.selectedMMIDQty &&
      quantity.success &&
      returnReason.success &&
      (returnReason.requiredEsn && esnList.length > 0 ? esns.success : true)
    ) {
      saveDisability && setSaveDisability(false);
    } else if (!saveDisability) {
      setSaveDisability(true);
    }
  }, [mmid.mmid, mmid.selectedMMIDQty, returnReason.success, esns.value, quantity.value]);

  const handleSave = () => {
    let dataToBeSaved = {
      mmid: mmid.mmid,
      itemDescription: mmid.itemDescription,
      mmidQuantity: mmid.selectedQuantity,
      returnReason: returnReason.reason,
      quantity: quantity.value,
      esns: esns.value.toString(),
      processingType: processingType.value,
      type: returnTicketModalData.type,
      wtMaterialId: mmid.wtMaterialId,
      returnId: returnTicketModalData.workTicketData[0].returnId,
      sapId: mmid.sapId || "",
      techRefId: mmid.techRefId || "",
      contractId: mmid.contractId || "",
      contractName: mmid.contractName || "",
      parentWMID: mmid.id || "",
    };
    saveHandler(dataToBeSaved);
  };
  const onShow = () => {
    let selectAllCheckbox = document.querySelector(".p-multiselect-header > .p-checkbox");
    selectAllCheckbox?.after(" Select All");
  };

  let retnEditProcess = PROCESSING_TYPE_VALUES.filter((procType) => procType?.code === editReturnMaterialProcessingType);

  return (
    <Modal
      role="dialog"
      ariaLabel="Return Item"
      hideCloseButton={false}
      opened={true}
      disableOutsideClick
      css={StyledModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <div css={{ paddingLeft: "1.5rem" }}>
            <Title size="medium">Return Item</Title>
          </div>
          <div onClick={closeHandler} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
            <Icon
              aria-label="close"
              alt="close-icon"
              role="img"
              name="close"
              size="medium"
              color="#000000"
              style={{ cursor: "pointer", outline: "none" }}
            />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
        <CustomDropdown
          label="MMID"
          aria-label="MMID"
          error={mmid.error}
          errorText={mmid.errorText}
          success={mmid.success}
          value={mmid.customValue}
          onChange={(e) => handleFields("MMID", e)}
        >
          <option disabled>Please select</option>
          {mmidDropdownList.map((item) => (
            <option
              key={item.MMID}
              data-mmid={JSON.stringify(item)}
              value={`${item.MMID} - ${item.sapId} - ${item.techRefId}`}
              disabled={item.sumReturnTicketQty >= item.totalQty}
            >
              {item.sapId && item.techRefId ? `${item.MMID} - ${item.sapId} - ${item.techRefId}` : item.MMID}
            </option>
          ))}
        </CustomDropdown>
        <CustomInput
          label="Item Description"
          aria-label="Item Description"
          readOnly={true}
          required={true}
          disabled={false}
          error={false}
          errorText=""
          success={false}
          value={mmid.itemDescription}
          marginTop="1rem"
          marginBottom="1rem"
        />
        <FlexRow css={{ marginTop: "1rem" }} justifyContent="space-between">
          <CustomDropdown
            width="45%"
            label="Return Reason"
            aria-label="Return Reason"
            error={returnReason.error}
            errorText={returnReason.errorText}
            success={returnReason.success}
            value={returnReason.reason || "Please select"}
            onChange={(e) => handleFields("Return Reason", e.target.value)}
            //   paddingRight="5px"
            //   marginTop="1rem"
          >
            {returnTicketModalData?.type === "edit" && rtnReson ? (
              <>
                <option value={rtnReson}>{`${rtnReson}`}</option>
                {returnReasonList
                  .filter((filtReson) => filtReson.reason.toLowerCase() != rtnReson.toLowerCase())
                  .map((reason) => (
                    <option
                      key={reason.reason}
                      value={reason.reason}
                      // name={reason.reason + "-" + reason.requiredEsn}
                    >
                      {reason.reason}
                    </option>
                  ))}
              </>
            ) : (
              <>
                <option disabled>Please select</option>
                {returnReasonList.map((reason) => (
                  <option
                    key={reason.reason}
                    value={reason.reason}
                    // name={reason.reason + "-" + reason.requiredEsn}
                  >
                    {reason.reason}
                  </option>
                ))}
              </>
            )}
          </CustomDropdown>
          <CustomInput
            formWidth="45%"
            type="text"
            label="Processing Type"
            aria-label="Processing Type"
            value={`${processingType.value} - ${processingType.description}`}
            error={processingType.error}
            errorText={processingType.errorText}
          />
        </FlexRow>
        <FlexRow css={{ marginTop: "1rem" }} justifyContent="space-between">
          <CustomInput
            formWidth="45%"
            type="text"
            label="Quantity"
            aria-label="Quantity"
            disabled={esnList.length > 0 || mmid.customValue === "Please select"}
            aria-disabled={esnList.length > 0}
            value={quantity.value}
            error={quantity.error}
            errorText={quantity.errorText}
            success={quantity.success}
            onChange={(e) => handleFields("Quantity", e.target.value)}
          />
          {
            <div style={{ width: "50%", marginTop: "2px" }}>
              <SmallText
                color={mmid.customValue === "Please select" ? "#D8DADA !important" : "#000000"}
                css={{ marginTop: "0px", color: mmid.customValue === "Please select" ? "#D8DADA !important" : "#000000" }}
              >
                ESNs
              </SmallText>
              <CustomisedMultiDropdown
                label="ESNs"
                aria-label="ESNs"
                value={esns?.value}
                error={esns.error}
                errorText={esns.errorText}
                success={esns.success}
                placeholder="Please select"
                onShow={onShow}
                disabled={mmid.customValue === "Please select"}
                onChange={(e) => handleFields("ESNs", e.value)}
                options={
                  returnTicketModalData?.type === "add"
                    ? esnList?.filter((esn) => !mmidDropdownList.filter((mmidList) => mmidList.id === mmid.id)[0].returnTicketEsns.includes(esn))
                    : esnList
                        ?.filter((esn) => !mmidDropdownList.filter((mmidList) => mmidList.id === mmid.id)[0].returnTicketEsns.includes(esn))
                        .concat(esitEsn)
                        .sort()
                }
              ></CustomisedMultiDropdown>
            </div>
          }
        </FlexRow>
        <FlexRow css={{ marginTop: "1rem" }}>
          <CustomSLButton
            aria-label="Save"
            role="button"
            css={{ padding: "0 10px 0 10px" }}
            primary
            disabled={saveDisability}
            aria-disabled={saveDisability}
            onClick={handleSave}
          >
            Save
          </CustomSLButton>
          <CustomSLButton aria-label="Cancel" role="button" secondary onClick={closeHandler}>
            Cancel
          </CustomSLButton>
        </FlexRow>
      </ModalBody>
    </Modal>
  );
}
