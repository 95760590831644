import { lazy, useRef, useState } from "react";
import { Header, SLDropDown } from "../../components/core/Controls";
import { SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/Utility";
import styled from "@emotion/styled";
import { Loader } from "@vds/loaders";
import { Body } from "@vds/typography";
import { Button } from "@vds/buttons";
import { uploadDataManualActivation } from "../../services/Aggregator/uploadDataManualActivation";
import { Col, Row } from "@vds/grids";
import { generate } from "shortid";
const ContentWrapper = styled.div`
  padding: 10px 10px;
`;
const FlexDiv = styled.div`
  font-size: 12px;
  width: 30rem;
`;
const FormGroup = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;
const FormUpload = styled.div`
  display: block;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 12px;
`;
export const StyledCol1 = styled(Col)`
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
`;
const FormSelect = styled.div`
  display: block;
  border: ${(props) => {
    if (props.className === "empty") {
      return "2px solid grey";
    } else {
      return "2px solid green";
    }
  }};
  color: black;
  cursor: pointer;
  height: 34px;
  line-height: 34px;
  text-align: left;
  background: #ffffff;
  overflow: hidden;
  position: relative;
`;
const FormSelectButton = styled.div`
  background: ${(props) => {
    if (props.className === "empty") {
      return "#DCE4EC";
    } else {
      return "green";
    }
  }};
  color: ${(props) => {
    if (props.className === "empty") {
      return "black";
    } else {
      return "white";
    }
  }};
  padding: 0 10px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
`;
const FormSelectName = styled.div`
  line-height: 34px;
  display: inline-block;
  padding: 0 10px;
`;
const FileInput = styled.input`
  padding-bottom: 5px;
  padding-top: 5px;
  visibility: hidden;
`;
const Carriers = {
  Verizon: "Verizon",
  ATT: "ATT",
  Telstra: "Telstra",
  Vodafone: "Vodafone",
};

export default function UpdateStatus(props) {
  const [loading, setLoading] = useState(false);
  const [fileUpload, setFileUpload] = useState(null);
  const [fileName, setFileName] = useState("empty");
  const [fileFormat, setFileFormat] = useState("");
  const [carrier, setCarrier] = useState("");
  const [notification, setNotification] = useState();
  let hiddenFileInput = useRef();
  const formData = new FormData();
  formData.append("file", fileUpload);
  const handleFiles = () => {
    if (hiddenFileInput.current) hiddenFileInput.current.click();
  };
  const fileHandler = (event) => {
    setFileUpload(event.target.files[0]);
    setFileName(event.target.files[0].name);
    setFileFormat(event.target.files[0].type);
  };
  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
    setTimeout(() => {
      setNotification(null);
    }, 5000);
  };

  const handleManualActivation = async () => {
    try {
      setLoading(true);
      if (fileName === "empty" || fileUpload === null) {
        notify("Please Upload File ", "error");
      } else if (fileFormat !== "text/csv") {
        notify("Please Upload File only in CSV Format ", "error");
      } else {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const res = await uploadDataManualActivation(formData, carrier, config);
        if (res?.status === 200) {
          notify("Successfully activated", "info");
        } else {
          notify(res?.message ?? "Unexpected error occurred while uploading the file", "error");
        }
      }
    } catch (e) {
      notify("Unexpected error occurred while uploading the file", "error");
    } finally {
      setFileUpload(null);
      setFileName("empty");
      if (hiddenFileInput.current) hiddenFileInput.current.value = "";
      setLoading(false);
    }
  };

  return (
    <>
      <Header>Manually Update Units - Update Active Units</Header>
      {notification !== null ? notification : null}
      {loading ? (
        <Loader active={loading} />
      ) : (
        <ContentWrapper>
          <SLRowLayout>
            <StyledCol1 colSizes={{ mobile: 4, tablet: 12, desktop: 12 }}>
              <FlexDiv>
                <FormGroup>
                  <Body size="large" primitive="span" bold>
                    Carriers
                  </Body>
                  <SLDropDown onChange={(e) => setCarrier(e.target.value)}>
                    <option disabled selected hidden>
                      Please Select Carrier
                    </option>
                    {Object.keys(Carriers).map((key) => {
                      return (
                        <option key={key} value={key} selected={carrier == key}>
                          {Carriers[key]}
                        </option>
                      );
                    })}
                  </SLDropDown>
                  <FormUpload onClick={handleFiles}>
                    <FormSelect className={fileName} {...props}>
                      <FormSelectButton className={fileName} {...props}>
                        Choose File
                      </FormSelectButton>
                      {fileName === "empty" && fileUpload === null ? (
                        <FormSelectName>No file chosen...</FormSelectName>
                      ) : (
                        <FormSelectName>{fileName}</FormSelectName>
                      )}
                      <FileInput type="file" accept=".csv" ref={hiddenFileInput} onChange={fileHandler}></FileInput>
                    </FormSelect>
                  </FormUpload>
                </FormGroup>
              </FlexDiv>
              <Button size="large" onClick={handleManualActivation}>
                Upload and Update
              </Button>
            </StyledCol1>
          </SLRowLayout>
        </ContentWrapper>
      )}
    </>
  );
}

export const UpdateManualActivationMetaData = {
  name: "Update Manual Activation",
  id: "updatemanualactivation",
  component: lazy(() => import("./UpdateManualActivation")),
  route: "/updatemanualactivation",
};
