import React, { lazy, useContext, useEffect, useState } from "react";
import { HARDWARE_LIST_COL, VEHICLE_INTAKE_COL } from "../../constants/VehicleListConstants";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { CustomSLButton, FlexRow, Spacer, UltraMediumText, SLNotification } from "../../components/core/utility";
import ReviewViewFlyOutMenu from "./ReviewViewFlyOutMenu";
import edit from "../../assets/icons/edit.png";
import { Loader } from "@vds/loaders";

import { BreadcrumbItem, Breadcrumbs } from "@vds/breadcrumbs";
import { generate } from "shortid";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { C4SContext } from "../../context/c4sContext";
import { getInstallType, handleAddPage, handleChangePage } from "./utils";
import { EditDetailsMetadata } from "./AddAVehicle";
import styled from "styled-components";
import FailedIcon from "../../assets/icons/ErrorIcon.svg";
import DeleteVehicle from "./DeleteVehicle";
import _, { groupBy, uniq } from "lodash";
import { ATPSummaryCheckMetaData } from "./ATPSummaryCheck";
import { getVehicleIntakeList } from "../../services/C4S Decommission - Replacement/getVehicleIntakeList";
import ConfirmDeliveryDateModal from "./ConfirmDeliveryDateModal";
import { AddHardwareMetadata } from "./AddHardware";
import { getHardwareTableList } from "../../services/C4S Decommission - Replacement/getHardwareList";
import searchIcon from "../../assets/icons/search_blk.png";
import HardwareListFlyOutMenu from "./HardwareListFlyOutMenu";
import { validateItemQuantity } from "../../services/C4S Decommission - Replacement/validateItemQuantity";
import { fetchSapId } from "../../services/C4S Decommission - Replacement/fetchSapId";
import { hasPrivilege } from "../../../utility/privileges";
import { Privileges } from "../../../constants/PrivilegeConstants";
import "../../assets/C4SCustomestyles.css";
import { Tooltip } from "primereact/tooltip";
import useOutsideClick from "../../customHook/use-outside-click";
import { TextLink } from "@vds/buttons";

const StyledIconContainer = styled.div`
  display: flex;
`;

const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid black;
  width: 220px;
`;

const SearchInput = styled.input`
  outline: none;
  border: none;
  height: 25px;
  width: 75%;
`;

const IconWrapper = styled.div`
  height: 24px;
  width: 24px;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
`;

export default function VehicleListTable(props) {
  //added it as part of VZC42148-106
  const vehicleFilterDropDownData = {
    shippingAddress: { placeholder: "Search for address", data: [] },
  };

  //added it as part of VZC42148-106
  const hardwareFilterDropDownData = {
    shippingAddress: { placeholder: "Search for address", data: [] },
  };

  const { cspDetails, notification, recordsUploaded, isSourceCSP, limitWTCreation, quantityPayload } = useContext(C4SContext);
  const [isLoading, setLoader] = useState(false);
  const [vehicleIntakeList, setVehicleIntakeList] = useState([]);
  const [selectedVINs, setSelectedVINs] = useState([]);
  const [isCheckAtpDateDisabled, setIsCheckAtpDateDisabled] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [deleteVINModal, setDeleteVINModal] = useState(false);
  const [confirmDeliveryDateModal, setConfirmDeliveryDateModal] = useState(false);
  const [hardwareList, setHardwareList] = useState([]);
  const [selectedHardware, setSelectedHardware] = useState([]);
  const [selectedVINRecord, setSelectedVINRecord] = useState([]);
  const [globalSearchValue, setGlobalSearchValue] = useState("");
  const [additionalVehicleGlobalFilterFields, setAdditionalVehicleGlobalFilterFields] = useState([]);
  const [additionalHardwareGlobalFilterFields, setAdditionalHardwareGlobalFilterFields] = useState([]);
  //added the below two lines as part of VZC42148-106
  const [customVehicleFilterDropDownData, setCustomVehicleFilterDropDownData] = useState(vehicleFilterDropDownData);
  const [customHardwareFilterDropDownData, setCustomHardwareFilterDropDownData] = useState(hardwareFilterDropDownData);
  const installTypes = ["CMI", "VMI"];
  const tempFetchSapIdInfo = [];

  let vehicleIntakeInfoForQty;
  let quantityValPayload;
  let sapIdHashMap = new Map();
  const handleClickOutside = () => {
    notification.setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    isSourceCSP.isSourceCSP ? getVehicleList() : getHardwareList();
  }, []);

  useEffect(() => {
    getSelectedItemsCallback();
  }, [selectedHardware, selectedVINRecord]);

  const getVehicleList = async () => {
    updateNewrelicStats();
    setLoader(true);
    let payLoad = {
      accountId: cspDetails.cspDetails?.accountId,
      cspId: cspDetails.cspDetails?.cspId,
      caseId: cspDetails.cspDetails?.caseId ? cspDetails.cspDetails.caseId : null,
      vzcSegment: cspDetails.cspDetails?.vztSegment,
    };
    let additionalGlobalFilterFields = [];
    let response = Object.keys(props).length ? props.vehicleListData : await getVehicleIntakeList(payLoad);
    recordsUploaded.setRecordsUploaded({ ...recordsUploaded.recordsUploaded, totalVIN: response?.data?.data?.length });
    if (response?.status === 200 && response?.data) {
      let data = response.data.data
        .map((responseData, index) => {
          let ymm = [responseData.year?.toString(), responseData.make, responseData.model].filter((val) => val).join(", ");
          let shippingAddress = [
            responseData.addressOne?.trim(),
            responseData.addressTwo?.trim(),
            responseData.city?.trim(),
            responseData.stateCode?.trim(),
            responseData.zipCode?.trim(),
          ]
            .filter((val) => val)
            .join(", ")
            ?.trim();
          let installType = getInstallType(responseData.installType);
          let mainData = {
            id: responseData.id,
            vin: responseData.vin,
            status: responseData.status,
            year: responseData.year?.toString(),
            make: responseData.make,
            model: responseData.model,
            installType: installType,
            addressOne: responseData.addressOne?.trim(),
            addressTwo: responseData.addressTwo?.trim(),
            city: responseData.city?.trim(),
            stateCode: responseData.stateCode?.trim(),
            country: responseData.country?.trim(),
            zipCode: responseData.zipCode?.trim(),
            timezone: responseData.timezone,
            addressByPass: responseData.addressByPass,
            locationFound: responseData.locationFound,
            distributionChannel: responseData.distributionChannel,
            division: responseData.division,
            features: [...responseData.features],
            materials: responseData.materials,
            shippingAddress: shippingAddress || "--",
            atpCheck: `${shippingAddress}, ${installType}, ${responseData.salesOrg}, ${cspDetails.cspDetails?.customerName}, ${cspDetails.cspDetails?.customerNumber}`,
            ymm: ymm || "--",
            vinCount: responseData.vinCount,
            errorYMM: responseData.errorYMM,
            salesOrg: responseData.salesOrg,
            vinCountError: responseData.vinCount > 1 ? "Duplicate Vin" : "",
            ymmError: responseData.errorYMM ? "Error in Year/Make/Model" : "",
            locationError: !responseData.locationFound ? "Invalid Location " : "",
            installTypeError: !installTypes.includes(responseData.installType) ? "InstallType not found" : " ",
          };

          mainData.mmIDError = responseData.materials.some((material) => !material.validMMID) ? "Invalid MMID" : "";
          mainData.vmfSapIdValError = responseData.materials.some((material) => !material.vmfSapIdVal && !material.upsell)
            ? "Invalid contract item"
            : "";
          mainData.orderTypeError = responseData.materials.some((material) => !material.orderType) ? "Order type is empty " : "";
          mainData.techRefIdError = responseData.materials.some((material) => material.upsell == false && !material.techRefId)
            ? "Invalid Tech Ref Id"
            : "";

          let materialData = {};
          for (let i = 0; i < responseData.materials.length; i++) {
            let materialId = "materialData" + i + "materialId";
            let materialName = "materialData" + i + "materialName";
            materialData = { ...materialData, ...{ [materialId]: responseData.materials[i]?.materialId } };
            additionalGlobalFilterFields.push(materialId, materialName);
          }
          let featuresData = {};
          for (let i = 0; i < responseData.features.length; i++) {
            let featureId = "features" + i + "featureId";
            let feature = "features" + i + "feature";
            materialData = { ...materialData, ...{ [featureId]: responseData.features[i]?.featureId, [feature]: responseData.features[i]?.feature } };
            additionalGlobalFilterFields.push(featureId, feature);
          }
          return { ...mainData, ...materialData, ...featuresData };
        })
        .sort((a, b) => {
          if (a.vinCount > 1 || a.errorYMM || !a.locationFound) {
            return -1;
          } else if (b.vinCount > 1 || b.errorYMM || !b.locationFound) {
            return 1;
          } else {
            return 0;
          }
        });
      setAdditionalVehicleGlobalFilterFields(additionalGlobalFilterFields);
      setVehicleIntakeList(data);
      let newFilterDropDownData = {
        ...customVehicleFilterDropDownData,
        ...{ shippingAddress: { placeholder: "Search for Address", data: [...new Set(data.map((item) => item.shippingAddress))] } },
      };
      setCustomVehicleFilterDropDownData(newFilterDropDownData);
      setLoader(false);
    } else {
      setLoader(false);
      setVehicleIntakeList([]);
      notification.setNotification(
        <SLNotification key={generate()} type="error" title="We are currently experiencing issues, please try again" fullBleed={true} />
      );
    }
    getHardwareList();
  };

  const getHardwareList = async () => {
    let payload = {
      accountId: cspDetails.cspDetails.accountId,
      caseId: isSourceCSP.isSourceCSP ? "" : cspDetails.cspDetails.caseId,
      cspId: isSourceCSP.isSourceCSP ? cspDetails.cspDetails.cspId : "",
    };
    if (payload.accountId === "" || payload.accountId === undefined || payload.accountId === null) {
      return notification.setNotification(<SLNotification key={generate()} type="error" title="Account ID is missing" fullBleed={true} />);
    }
    setLoader(true);
    let additionalGlobalFilterFields = [];
    let response = Object.keys(props).length ? props.hardwareListData : await getHardwareTableList(payload, isSourceCSP.isSourceCSP);
    if (response?.status === 200 && response?.data) {
      let data = response.data.data.hardwareDTO.map((responseData) => {
        let shippingAddress = [
          responseData.addressOne?.trim(),
          responseData.addressTwo?.trim(),
          responseData.city?.trim(),
          responseData.stateCode?.trim(),
          responseData.zipcode?.trim(),
        ]
          .filter((val) => val)
          .join(", ")
          ?.trim();
        let installType = getInstallType(responseData.installType);
        let mainData = {
          id: responseData.id,
          installType: installType,
          addressOne: responseData.addressOne,
          addressTwo: responseData.addressTwo,
          city: responseData.city,
          stateCode: responseData.stateCode,
          zipCode: responseData.zipcode,
          country: responseData.country,
          addressByPass: responseData.addressByPass,
          locationFound: responseData.locationFound,
          shippingAddress: shippingAddress,
          atpCheck: `${shippingAddress}, ${installType}, ${responseData.salesOrgId}, ${cspDetails.cspDetails?.customerName}, ${cspDetails.cspDetails?.customerNumber}`,
          materials: responseData.materials,
          salesOrg: responseData.salesOrgId,
          distributionChannel: responseData.distributionChannel,
          division: responseData.division,
        };
        let materialData = {};
        for (let i = 0; i < responseData.materials.length; i++) {
          let materialId = "materialData" + i + "materialId";
          let materialName = "materialData" + i + "materialName";
          materialData = {
            ...materialData,
            ...{ [materialId]: responseData.materials[i]?.materialId, [materialName]: responseData.materials[i]?.materialName },
          };
          additionalGlobalFilterFields.push(materialId, materialName);
        }
        return { ...mainData, ...materialData };
      });
      setAdditionalHardwareGlobalFilterFields(additionalGlobalFilterFields);
      setHardwareList(data);
      let newFilterDropDownData = {
        ...customHardwareFilterDropDownData,
        ...{ shippingAddress: { placeholder: "Search for Address", data: [...new Set(data.map((item) => item.shippingAddress))] } },
      };
      setCustomHardwareFilterDropDownData(newFilterDropDownData);
      setLoader(false);
    } else {
      setLoader(false);
      setHardwareList([]);
      notification.setNotification(
        <SLNotification key={generate()} type="error" title="We are currently experiencing issues, please try again" fullBleed={true} />
      );
    }
  };

  const rowExpansionTemplate = (data) => <ReviewViewFlyOutMenu rowData={data} />;

  const hardwareRowExpansionTemplate = (data) => <HardwareListFlyOutMenu rowData={data} />;

  const navigate = (data) => {
    notification.setNotification(null);
    handleAddPage(EditDetailsMetadata.id, { vehicleData: data });
  };

  const onRowEditTemplate = (data) => {
    return (
      <img
        style={{ width: "15px", cursor: "pointer" }}
        alt="edit-icon"
        aria-label="Edit Icon"
        tabIndex="0"
        src={edit}
        aria-controls="overlay_panel"
        onClick={() => navigate(data)}
      />
    );
  };

  const onHardwareRowEdit = (data) => {
    return (
      <img
        style={{ width: "15px", cursor: "pointer" }}
        alt="more-icon"
        aria-label="Edit Icon"
        tabIndex="0"
        src={edit}
        aria-controls="overlay_panel"
        onClick={() => {
          notification.setNotification(null), handleAddPage(AddHardwareMetadata.id, { props: data });
        }}
      />
    );
  };

  const getSelectedItemsCallback = () => {
    let selectedHardwares = selectedHardware.length ? _.map(selectedHardware, (item) => ({ ...item, isHardwareRecord: true })) : [];
    let selectedItems = [...selectedVINRecord, ...selectedHardwares];
    let mmIDs;
    let inValidMMIDList = [];
    if (selectedItems.length) {
      setIsButtonDisabled(false);
      if (!selectedItems.every((item) => item.country && item.stateCode)) {
        setIsCheckAtpDateDisabled(true);
        return notification.setNotification(
          <SLNotification
            key={generate()}
            type="error"
            title={"Cannot proceed with ATP date check. Selected item(s) do not have country/state in the address."}
            fullBleed={true}
          />
        );
      }
      //Start of invalid MMID Validation
      // let isInvalidMMID = false;
      selectedItems.map((items) => {
        let matValid = items.materials.filter((material) => material.validMMID === false);
        matValid.map((list) => {
          if (inValidMMIDList && !inValidMMIDList?.includes(list.materialId)) inValidMMIDList.push(list.materialId);
        });
        mmIDs = inValidMMIDList.join();
        //  isInvalidMMID = matValid.length > 0 ? true : false;
      }); //End of invalid MMID Validation
      let isMaterialAdded = selectedItems.every((item) => item.materials.length > 0);
      let selectedItemsGroupByAtpCheck = Object.values(groupBy(selectedItems, "atpCheck"));
      let isDivisionAndDistributionValidated = checkDivisionDistributionChannelEquality(selectedItemsGroupByAtpCheck);
      let isProcessingCodesValid = isProcessingCodeCombined(selectedItemsGroupByAtpCheck);
      let isCheckAtpDateDisabled = !isMaterialAdded || inValidMMIDList.length > 0 || !isDivisionAndDistributionValidated || !isProcessingCodesValid;
      let uploadCsvCheck = false;
      let records = [];
      selectedVINRecord.map((items) => {
        let materialList = items.materials.filter((material) => {
          return (material.upsell === false && !material.techRefId) || (material.upsell === false && !material.vmfSapIdVal) || !material.orderType;
        });
        records.push(...materialList);
      });
      if (isSourceCSP.isSourceCSP) {
        selectedHardware.map((items) => {
          let materialList = items.materials.filter((material) => {
            return (material.upsell === false && !material.techRefId) || (material.upsell === false && !material.sapIdVal) || !material.orderType;
          });
          records.push(...materialList);
        });
      }
      uploadCsvCheck = records.length > 0 ? true : false;
      setIsCheckAtpDateDisabled(isCheckAtpDateDisabled);
      setSelectedVINs([...selectedItems]);
      if (isCheckAtpDateDisabled) {
        inValidMMIDList &&
          inValidMMIDList?.length &&
          setTimeout(() => {
            notification.setNotification(
              <SLNotification
                key={generate()}
                type="error"
                title={"Cannot proceed with ATP date check. Selected item(s) has invalid MMID (" + mmIDs + ")."}
                fullBleed={true}
              />
            );
          }, 1);
        !isMaterialAdded &&
          setTimeout(() => {
            notification.setNotification(
              <SLNotification key={generate()} type="error" title="All VINs must have an MMID to proceed with ATP date check" fullBleed={true} />
            );
          }, 1);
        !isDivisionAndDistributionValidated &&
          setTimeout(() => {
            notification.setNotification(
              <SLNotification
                key={generate()}
                type="error"
                title="Cannot proceed with ATP date check. Selected records should contain similar Division & Distribution Channel"
                fullBleed={true}
              />
            );
          }, 1);
        !isProcessingCodesValid &&
          setTimeout(() => {
            let kbLink = "https://vzt-kb.vtitel.com/Systems_and_Tools/Connect_360/Connect_360_Processes/Reveal_Hardware_Return_Process";
            notification.setNotification(
              <SLNotification
                key={generate()}
                type="error"
                title={
                  <>
                    <span>
                      Cannot proceed with ATP date check. Hardware and accessory shipments can not be combined with exchanges on the same work ticket.
                      Please refer to{" "}
                      <TextLink
                        href={kbLink}
                        size="small"
                        type="inline"
                        tabIndex="0"
                        role="link"
                        ariaLabel="kb article"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        KB Article
                      </TextLink>
                      .
                    </span>
                  </>
                }
                fullBleed={true}
              />
            );
          }, 1);
      } else {
        uploadCsvCheck ? handleUploadCsv(uploadCsvCheck) : notification.setNotification(null);
      }
    } else {
      setIsCheckAtpDateDisabled(true);
      !limitWTCreation.limitWTCreation && !hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY) && notification.setNotification(null);
      setIsButtonDisabled(true);
      setSelectedVINs([]);
    }
  };

  const checkDivisionDistributionChannelEquality = (data) => {
    for (const items of data) {
      const firstDivision = items[0].division,
        firstDistributionChannel = items[0].distributionChannel;
      if (items.some((obj) => obj.division !== firstDivision || obj.distributionChannel !== firstDistributionChannel)) return false;
    }
    return true;
  };

  const renderImage = (data, rowData) => {
    let showErrorIcon =
      rowData.vinCount > 1 ||
      rowData.errorYMM ||
      !rowData.locationFound ||
      !installTypes.includes(rowData.installType) ||
      rowData.materials.some((material) => {
        if (material.upsell) {
          !material.validMMID || !material.orderType;
        } else {
          !material.validMMID ||
            (material.upsell === false && !material.techRefId) ||
            !material.vmfSapIdVal ||
            !material.orderType ||
            !material.vmfSapId;
        }
      });

    let tempMessage =
      rowData.vinCountError +
      "\n" +
      rowData.ymmError +
      "\n" +
      rowData.locationError +
      "\n" +
      rowData.installTypeError +
      "\n" +
      rowData.mmIDError +
      "\n" +
      rowData.vmfSapIdValError +
      "\n" +
      rowData.orderTypeError +
      "\n" +
      rowData.techRefIdError;

    const tooltipString = tempMessage.split("\n").filter((tooltipString) => tooltipString.trim() !== "");
    const tooltipMessage = tooltipString.join("\n");
    return (
      <>
        <StyledIconContainer>
          <IconWrapper>
            <Tooltip target=".errorlogo" mouseTrack mouseTrackLeft={10} />

            <img
              alt="Error icon"
              className="errorlogo"
              src={FailedIcon}
              height="16px"
              width="16px"
              css={{ marginRight: "8px", visibility: showErrorIcon ? "visible" : "hidden" }}
              aria-label="Error"
              data-pr-tooltip={tooltipMessage}
            ></img>
          </IconWrapper>
        </StyledIconContainer>
      </>
    );
  };

  const renderImageHardware = (data, rowData) => {
    let showErrorIcon = !rowData.locationFound;
    return (
      <>
        <StyledIconContainer>
          <IconWrapper>
            <Tooltip target=".errorlogo" mouseTrack mouseTrackLeft={10} />
            <img
              alt="Error icon"
              src={FailedIcon}
              height="16px"
              width="16px"
              css={{ marginRight: "8px", visibility: showErrorIcon ? "visible" : "hidden" }}
              aria-label="Location Error"
              className="errorlogo"
              data-pr-tooltip={"Invalid Location"}
            />
          </IconWrapper>
        </StyledIconContainer>
      </>
    );
  };
  const groupSelectedRecords = (selectedRecords) => {
    let sorted = [];
    Object.keys(selectedRecords)
      // .sort((a, b) => Number(a) - Number(b))
      .reduce((idx, record) => {
        let mmid = {};
        if (record) {
          mmid[record] = selectedRecords[record];
          sorted.push(mmid);
        }
      }, {});
    return sorted;
  };

  const handleATPCheck = (requestedDeliveryDate) => {
    setConfirmDeliveryDateModal(false);
    let groupedByAddressInstallType = groupBy(selectedVINs, "atpCheck");
    handleAddPage(ATPSummaryCheckMetaData.id, { data: groupSelectedRecords(groupedByAddressInstallType), requestedDeliveryDate });
  };

  const onGlobalSearchChange = (e) => {
    setGlobalSearchValue(e.target.value);
  };

  /* Changes are done as part of VZC42148-82 & VZC42148-292 */
  const onClickCheckATPDate = () => {
    /* 1. Validate if selected items has valid MMIDs (This is handled on selection of items) */
    /* 2. Check if all MMIDs have SAP ID */
    /* 3. If any of the MMIDs doesn't have SAP ID, check if the similar MMID in
     selected items has SAP ID mapped to it. If so, map that SAP ID to all other similar MMIDs. */
    /* 4. Trigger fetchSapId API call with the list of MMIDs for which SAP ID is null */
    /* 5. Display an Error Message if fetchSapId call returns multiple SAP IDs in the response. */
    /* 6. Group the MMIDs with the mapped SAP ID and sum up the quantity */
    /* 7. Trigger validateItemQuantity API call to ensure that correct amount of equipment is ordered */
    /* 8. Display an Error Message if validateItemQuantity API response returns quantity exceeded information.
    If not, open confirmation model of check atp date */

    handleVINandHardwareData();
  };

  const isProcessingCodeCombined = (atpData) => {
    const ZZHS_ZZAE = new Set(["ZZHS", "ZZAE"]);
    const ZHWS_ZHWA = new Set(["ZHWS", "ZHWA"]);
    for (const records of atpData) {
      let processingCodes = [];
      for (const record of records) {
        const fieldName = record.isHardwareRecord ? "type" : "vmfType";
        processingCodes = [...processingCodes, ...record.materials.map((material) => material?.[fieldName])];
      }
      if (processingCodes.some((code) => ZZHS_ZZAE.has(code)) && processingCodes.some((code) => ZHWS_ZHWA.has(code))) return false;
    }
    return true;
  };

  const handleVINandHardwareData = () => {
    if (!isSourceCSP.isSourceCSP) {
      setConfirmDeliveryDateModal(true);
      setLoader(false);
    } else {
      let records = selectedVINs;
      /* Using Hash Map - MMID (key) & SAP ID (value) */
      Object.values(records).map((record) => {
        record.materials.map((material) => {
          let sapId = material.vmfSapIdVal ? material.vmfSapIdVal : material.sapIdVal;
          if (!sapIdHashMap.get(material.materialId)) {
            sapIdHashMap.set(material.materialId, sapId);
          }
        });
      });

      let materialsArr = [];
      Object.values(records).map((record) => {
        record.materials.map((materialInfo) => {
          /* Start - Copy if SAP ID already exists for same MMID in VIN/Hardware List */
          if (typeof materialInfo.vmfSapIdVal != "undefined" && (materialInfo.vmfSapIdVal == null || materialInfo.vmfSapIdVal == ""))
            materialInfo.vmfSapIdVal = sapIdHashMap.get(materialInfo.materialId) ? sapIdHashMap.get(materialInfo.materialId) : null;
          else if (materialInfo.sapIdVal == null || materialInfo.sapIdVal == "")
            materialInfo.sapIdVal = sapIdHashMap.get(materialInfo.materialId) ? sapIdHashMap.get(materialInfo.materialId) : null;
        }); /* End */
        materialsArr.push(...record.materials);
      });
      let groupMMIDs = groupedByMMID(materialsArr); /* Group the MMIDs by summing up the quantity */
      tempFetchSapIdInfo.push(groupMMIDs);
      vehicleIntakeInfoForQty = structuredClone(tempFetchSapIdInfo); /* Clone the data to frame qty validation payload */

      let materialsIdsList = [];
      Object.values(tempFetchSapIdInfo).map((info) => {
        info.map((material) => {
          /* Find MMIDs without SAP ID and push it in materialsIdsList */
          if (material.vmfSapIdVal === null && material.sapIdVal === null && material.upsell === false) materialsIdsList.push(material.materialId);
        });
        info.materials = materialsIdsList;
      });
      /* Make an API Call to fetch Sap Ids */
      if (materialsIdsList.length > 0) fetchSapIdCall(tempFetchSapIdInfo);
      else quantityValidation(vehicleIntakeInfoForQty);
    }
  };

  const groupedByMMID = (materialsArr) => {
    return [
      ...materialsArr
        .reduce((idx, materials) => {
          let type = materials.vmfType === undefined ? materials.type : materials.vmfType ?? "";
          let uniqueMatInfo = materials?.materialId + "_" + materials?.techRefId + "_" + materials?.upsell + "_" + type;
          !idx.has(uniqueMatInfo) &&
            idx.set(materials?.materialId + "_" + materials?.techRefId + "_" + materials?.upsell + "_" + type, { ...materials, orderQty: 0 });
          let material = idx.get(materials?.materialId + "_" + materials?.techRefId + "_" + materials?.upsell + "_" + type);
          material.orderQty += materials?.orderQty ? materials.orderQty : 1;
          return idx;
        }, new Map())
        .values(),
    ];
  };

  const fetchSapIdCall = async (requestParams) => {
    let selectedItem = [...selectedVINRecord, ...selectedHardware];
    let fetchSapIdPayLoad = requestParams.map((param) => {
      return {
        distribution: selectedItem[0].distributionChannel,
        division: selectedItem[0].division,
        salesOrg: cspDetails?.cspDetails?.cspData?.data?.data?.CSP.filter((csp) => csp.DefaultCSP === "1")[0].Opp_Sales_Org,
        mmIdList: param.materials,
      };
    });
    notification.setNotification(null);
    setLoader(true);
    updateNewrelicStats();
    let response = await fetchSapId(fetchSapIdPayLoad);
    if (response?.status === 200 && response?.data?.data) {
      setLoader(false);
      let sapIdResp = response.data.data;
      let mmIdsWithMultiSapId = [];
      sapIdResp.map((resp) => {
        resp.sapData.map((sapInfo) => {
          /* Capture MMIDs with multiple SAP IDs */
          if (sapInfo.sapIdList.length > 1) mmIdsWithMultiSapId.push(sapInfo.mmId);
          /* Update SAP ID if MMID of vehicleInfo matches with the MMID of response */
          /* Update the value if a single SAP ID is returned */
          if (sapInfo.sapIdList.length === 1) {
            Object.values(vehicleIntakeInfoForQty).map((vehicleInfo) => {
              vehicleInfo.map((material) => {
                if (material.materialId == sapInfo.mmId) {
                  if (material.vmfSapIdVal === null) material.vmfSapIdVal = sapInfo.sapIdList[0];
                  else if (material.sapIdVal === null) material.sapIdVal = sapInfo.sapIdList[0];
                  let sapId = material.vmfSapIdVal ? material.vmfSapIdVal : material.sapIdVal;
                  /* Setting it in the map to reuse it for qty validation error message */
                  sapIdHashMap.set(material.materialId, sapId);
                  Object.values(selectedVINs).map((record) => {
                    record.materials.map((materialInfo) => {
                      /* Start - Copy if SAP ID already exists for same MMID in VIN/Hardware List */
                      if (typeof materialInfo.vmfSapIdVal != "undefined" && (materialInfo.vmfSapIdVal == null || materialInfo.vmfSapIdVal == ""))
                        materialInfo.vmfSapIdVal = sapIdHashMap.get(materialInfo.materialId) ? sapIdHashMap.get(materialInfo.materialId) : null;
                      else if (materialInfo.sapIdVal == null || materialInfo.sapIdVal == "")
                        materialInfo.sapIdVal = sapIdHashMap.get(materialInfo.materialId) ? sapIdHashMap.get(materialInfo.materialId) : null;
                    });
                  });
                }
              });
            });
          }
        });
      });
      if (mmIdsWithMultiSapId.length > 0) {
        setIsButtonDisabled(true);
        setSelectedVINs([]);
        setIsCheckAtpDateDisabled(true);
        let mmIDs = mmIdsWithMultiSapId.join();
        notification.setNotification(
          <SLNotification
            css={{ whiteSpace: "nowrap" }}
            key={generate()}
            type="error"
            title={
              "Selected item(s) has more than one SAP ID mapped to its MMID (" + mmIDs + "). Proceed further by updating SAP ID using edit icon."
            }
            fullBleed={true}
          />
        );
      } else {
        /* Trigger this call if at all, all MMIDs have SAP ID */
        quantityValidation(vehicleIntakeInfoForQty);
      }
    } else {
      notification.setNotification(
        <SLNotification key={generate()} type="error" title="We are currently experiencing issues, please try again" fullBleed={true} />
      );
      setIsCheckAtpDateDisabled(true);
      setIsButtonDisabled(true);
      setSelectedVINs([]);
      setLoader(false);
    }
  };

  const quantityValidation = async (vehicleIntakeInfoForQty) => {
    notification.setNotification(null);
    updateNewrelicStats();
    let transaction = [];
    let VZCSegement = cspDetails?.cspDetails?.cspData?.data?.data?.VZCSegement.split(" ");
    let government = VZCSegement[0];
    let product = cspDetails?.cspDetails?.cspData?.data?.data?.Product?.map((prod) => {
      return prod?.SAPProductID;
    });
    let materialInfo;
    vehicleIntakeInfoForQty.map((lineItemInfo) => {
      lineItemInfo.map((materialData) => {
        let sapId = materialData.vmfSapIdVal ? materialData.vmfSapIdVal : materialData.sapIdVal;
        let cspProduct = cspDetails?.cspDetails?.cspData?.data?.data?.Product?.filter(
          (prod) => prod?.SAPProductID === sapId && prod?.TechRefId === materialData?.techRefId
        );
        let sfdcQty = cspProduct?.length > 0 ? cspProduct[0]?.Quantity : 0; /*SFDC Qty is what's left after previous orders*/
        materialInfo = {
          sfdcQty: sfdcQty,
          sapId: sapId ? sapId : "",
          orderedQty: materialData.orderQty,
          upsell: materialData.upsell ? materialData.upsell : false /* Default as false if no upsell info */,
          techRefId: materialData.techRefId,
          materialId: materialData.materialId,
          processingCode: materialData.vmfType === undefined ? materialData.type : materialData.vmfType ?? "",
        };
        transaction.push(materialInfo);
      });
    });
    quantityValPayload = {
      cspId: cspDetails?.cspDetails?.cspData?.data?.data?.CSP.filter((csp) => csp.DefaultCSP === "1")[0].CSPID,
      createdBy: `${cspDetails.cspDetails.firstName} ${cspDetails.cspDetails.lastName}`,
      crmReferenceId: cspDetails?.cspDetails?.cspData?.data?.data?.CSP[0]?.CRM_Ref_ID,
      isGovernment: government.toLowerCase() === "government" ? true : false,
      product,
      transaction,
    };
    let materialsWithMissingData = transaction.filter((data) => data.sapId === null && data.upsell === false).map((data) => data.materialId);
    if (materialsWithMissingData.length > 0) {
      return notification.setNotification(
        <SLNotification
          key={generate()}
          type="error"
          title={"SAP ID is missing for the following material(s) [" + materialsWithMissingData.join() + "]"}
          fullBleed={true}
        />
      );
    } else if (quantityValPayload.cspId === null)
      return notification.setNotification(
        <SLNotification
          key={generate()}
          type="error"
          title={
            "Unable to proceed with Quantity Validation due to missing information. Kindly contact IT team for support. Missing information: CSP ID"
          }
          fullBleed={true}
        />
      );

    setLoader(true);
    let response = await validateItemQuantity(quantityValPayload);
    quantityPayload.setQuantityValPayload(quantityValPayload);
    if (response?.status === 200 && response?.data?.data) {
      setLoader(false);
      /**
       * Validates the quantity of selected items against the contract subscription.
       * If the quantity exceeds the contract subscription for any selected item,
       * it displays an error notification with the MMIDs of the affected items.
       */
      let qtyValidationResp = response?.data?.data;
      let qtyExceededMmIds = qtyValidationResp?.validationList?.filter((material) => material.valid === false);
      let qtyExceededMmIdsList = uniq(qtyExceededMmIds?.map((material) => material.materialId))?.join();
      if (qtyExceededMmIdsList.length > 0) {
        notification.setNotification(
          <SLNotification
            key={generate()}
            type="error"
            title={"Quantity exceeds contract subscription for the selected item(s) with MMID (" + qtyExceededMmIdsList + ")"}
            fullBleed={true}
          />
        );
        setIsCheckAtpDateDisabled(true);
        setIsButtonDisabled(true);
        // setSelectedVINs([]);
      } else {
        setConfirmDeliveryDateModal(true);
        setLoader(false);
      }
    } else {
      notification.setNotification(
        <SLNotification key={generate()} type="error" title="We are currently experiencing issues, please try again" fullBleed={true} />
      );
      setIsCheckAtpDateDisabled(true);
      setIsButtonDisabled(true);
      setSelectedVINs([]);
      setLoader(false);
    }
  };
  /* End of VZC42148-82 & VZC42148-292 */

  const handleUploadCsv = (uploadCsvCheck) => {
    notification.setNotification(
      <SLNotification
        key={generate()}
        type="error"
        title={
          "Lines must be mapped to tech ref, contract item and order type in order to check ATP. Please check that all lines have been mapped before proceeding."
        }
        fullBleed={true}
      />
    );
    setIsCheckAtpDateDisabled(uploadCsvCheck);
  };

  return (
    <>
      <Loader active={isLoading} fullscreen />
      <div css={{ display: "flex", justifyContent: "flex-end" }}>
        <SearchInputWrapper>
          <SearchInput
            role="search"
            aria-label={globalSearchValue}
            value={globalSearchValue}
            type="text"
            onChange={onGlobalSearchChange}
            placeholder="Search"
          ></SearchInput>
          <img aria-label="search" role="img" src={searchIcon} height="20px" width="20px"></img>
        </SearchInputWrapper>
      </div>
      <Spacer bottom="25px"></Spacer>
      {deleteVINModal && (
        <DeleteVehicle
          deleteVINModal={deleteVINModal}
          setDeleteVINModal={setDeleteVINModal}
          selectedVINs={selectedVINs}
          vehicleIntakeList={vehicleIntakeList}
          setLoader={setLoader}
        />
      )}
      {isSourceCSP.isSourceCSP && (
        <>
          <GridComponent
            isSelectionEnabled={!limitWTCreation.limitWTCreation && !hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
            isExportEnabled={false}
            noDataMessage="No data available to display"
            ariaLabel="Vehicle Intake Table"
            rows={vehicleIntakeList}
            columns={VEHICLE_INTAKE_COL}
            hideGlobalFilter={true}
            onRowEditTemplate={!limitWTCreation.limitWTCreation && !hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY) && onRowEditTemplate}
            rowExpansionTemplate={rowExpansionTemplate}
            renderDataWithImage={renderImage}
            paginator={true}
            getSelectedItems={(row) => setSelectedVINRecord(row)}
            searchKeyboardValue={globalSearchValue}
            additionalGlobalSearchFields={additionalVehicleGlobalFilterFields}
            customFilterDropDownData={customVehicleFilterDropDownData}
          />
          <Spacer bottom="3rem" />
        </>
      )}
      {/* <UltraMediumText css={{ fontWeight: "bold", marginBottom: "0rem" }}>Hardware List</UltraMediumText> */}
      {isSourceCSP.isSourceCSP && (
        <Breadcrumbs surface="light">
          <BreadcrumbItem key={0} active={false}>
            <span
              css={{
                fontWeight: "400",
                color: "black",
              }}
              onClick={() => {
                handleChangePage({ index: 0 });
              }}
            >
              Vehicle Intake
            </span>
          </BreadcrumbItem>
          <BreadcrumbItem key={1} active={false}>
            <span
              css={{
                fontWeight: "700",
                color: "black",
              }}
            >
              Hardware List
            </span>
          </BreadcrumbItem>
        </Breadcrumbs>
      )}
      <GridComponent
        isSelectionEnabled={!limitWTCreation.limitWTCreation && !hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY)}
        isExportEnabled={false}
        noDataMessage="No data available to display"
        columns={HARDWARE_LIST_COL}
        ariaLabel="Hardeware List Table"
        onRowEditTemplate={!limitWTCreation.limitWTCreation && !hasPrivilege(Privileges.ENABLE_WORKTICKET_PORTAL_READONLY) && onHardwareRowEdit}
        rowExpansionTemplate={hardwareRowExpansionTemplate}
        getSelectedItems={(row) => setSelectedHardware(row)}
        rows={hardwareList}
        hideGlobalFilter={true}
        paginator={true}
        searchKeyboardValue={globalSearchValue}
        additionalGlobalSearchFields={additionalHardwareGlobalFilterFields}
        customFilterDropDownData={customHardwareFilterDropDownData}
        renderDataWithImage={renderImageHardware}
      />
      <Spacer top="1rem" />
      <FlexRow>
        <CustomSLButton
          aria-label="Check ATP Date"
          role="button"
          size="large"
          aria-disabled={isCheckAtpDateDisabled}
          tab-index="0"
          disabled={isCheckAtpDateDisabled}
          onClick={onClickCheckATPDate}
        >
          Check ATP Date
        </CustomSLButton>

        <CustomSLButton
          size="large"
          secondary
          aria-label="Delete"
          role="button"
          disabled={isButtonDisabled}
          tab-index="0"
          aria-disabled={isButtonDisabled}
          onClick={() => {
            setDeleteVINModal(true);
          }}
        >
          Delete
        </CustomSLButton>
      </FlexRow>
      {confirmDeliveryDateModal && <ConfirmDeliveryDateModal {...{ confirmDeliveryDateModal, setConfirmDeliveryDateModal, handleATPCheck }} />}
    </>
  );
}

export const VehicleListTableMetadata = {
  name: "Vehicle List",
  id: "vehicle_list_table",
  component: lazy(() => import("./VehicleListTable")),
  route: "/vehicle_list_table",
};
