import { COLUMN_WIDTHS } from "./ApplicationConstants";

export const VIEW_BATCH_TABLE = [
  {
    key: "ESN",
    value: "esn",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Activation Status",
    value: "status",
    isFilterEnabled: true,
    filterType: "Status",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Box Type",
    value: "boxType",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "MEID/ICCID",
    value: "iccid",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "GSM/MDN",
    value: "gsm",
    isFilterEnabled: true,
    filterType: "Date",
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Reveal ID",
    value: "createdBy",
    isFilterEnabled: true,
    filterType: "String",
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];

export const VIEW_EVENTS_UNITS_COL = [
  {
    key: "ESN",
    value: "esn",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isHyperLink: false,
    isSortable: true,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Status",
    value: "status",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Upload Date",
    value: "createdAt",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
    isDate: true,
  },
  {
    key: "Modify by",
    value: "modifiedBy",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
    isDate: true,
  },
  {
    key: "Message",
    value: "errorMessage",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },

  {
    key: "Uploaded By User",
    value: "createdBy",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];

export const UPLOAD_UNITS_COL = [
  {
    key: "Batch #",
    value: "batchNumber",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: true,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Brand",
    value: "brand",
    filterType: "Status",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
  {
    key: "Units",
    value: "count",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.small,
  },
  {
    key: "Filename",
    value: "batchFilename",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.large,
  },
  {
    key: "Upload Date",
    value: "createdAt",
    filterType: "Date",
    isFilterEnabled: true,
    isCustomFilter: true,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
    isDate: true,
  },
  {
    key: "Uploaded By User",
    value: "createdBy",
    filterType: "String",
    isFilterEnabled: true,
    isCustomFilter: false,
    isSortable: true,
    isHyperLink: false,
    width: COLUMN_WIDTHS.medium,
  },
];

export const UNITTYPE = {
  0: "Select Unit Type",
  1: "Cat M1",
  2: "CDMA",
  3: "GSM",
  4: "HSPA",
  5: "LTE",
  6: "LTE-VZ",
};

export const BOXTYPE = {
  0: "Select Box Type",
  1: "Calamp",
  2: "CalampAsset",
  3: "CatM1",
  4: "EAT",
  5: "EAT-Battery",
  6: "Enfora",
  7: "GenX",
  8: "Novatel",
  9: "NWF Calamp",
  10: "NWF 5500",
  11: "Pointer",
  12: "RaliacomVC",
  13: "Ruptela",
  14: "RuptelaTrackEasy",
  15: "SmartWitness",
  16: "Solo",
  17: "Unknown",
  18: "TeltonikaOrnicar",
  19: "Teltonika TrackEasy",
  20: "T2",
  21: "VDD",
  22: "Vcore",
  23: "Wifi Camera",
  24: "WNC",
  25: "Xirgo",
};

export const MODEL = {
  0: "Select Model",
  1: "Calamp 2820 3G",
  2: "Calamp 2830 Vzw",
  3: "Calamp 2830 3G",
  4: "Calamp 2630 3G Int",
  5: "Calamp 2630 3G Ext",
  6: "Calamp 2630 LTE Int",
  7: "Calamp 2630 LTE Ext",
  8: "Calamp 2620 EXT",
  9: "Calamp 2620 INT",
  10: "Calamp 2620 Verizon INT",
  11: "Calamp 2720 EXT",
  12: "Calamp 2720 INT",
  13: "Calamp 3030 EZ Vzw",
  14: "Calamp 3030 EZ 3G",
  15: "Calamp 4100",
  16: "Calamp TTU-720",
  17: "Cat-M1 LTE",
  18: "Driver Camera",
  19: "GNX3 - 3G INT",
  20: "GNX3 - LTE INT",
  21: "GNX3 - LTE EXT",
  22: "GNX3 - CDMA EXT",
  23: "GNX-5P 3G Ext",
  24: "GNX-5P 3G Int",
  25: "GNX-5P Verizon Ext",
  26: "GNX-5P Verizon Int",
  27: "MT-4100",
  28: "Multiple Stock Types",
  29: "Other",
  30: "Reactivated Unit",
  31: "Xirgo Vzw Int",
  32: "Xirgo 3G Int",
  33: "Xirgo LTE Int",
  34: "NWF 5500",
  35: "NWF Calamp",
  36: "LMU 4225 HSPA",
  37: "LMU 4225 CDMA VZ",
  38: "TTU 720 HSPA",
  39: "TTU 720 CDMA VZ",
  40: "LMU 3030 HSPA",
  41: "LMU 3030 CDMA",
  42: "LMU 4520 HSPA",
  43: "LMU 5000 HSPA",
  44: "LMU 4233 LTE VZ",
  45: "LMU 4233 LTE AT&T",
  46: "LMU 2630 HSPA",
  47: "LMU 2630 CDMA",
  48: "LMU 2630 LTE",
  49: "LMU 2630 VZW LTE",
  50: "TTU 2830 HSPA",
  51: "TTU 2830 CDMA",
  52: "TTU 2830 VZW LTE",
  53: "TTU 2830 LTE AT&T",
  54: "TTU 2820 HSPA",
  55: "TTU 2820 CDMA",
  56: "LMU 4230 HSPA",
  57: "LMU 4230 CDMA",
  58: "LMU 3640 VZW LTE",
  59: "LMU 3640 ATT LTE",
  60: "LTE Camera VZ",
  61: "LTE Camera Voda",
  62: "Novatel VZ",
  63: "Novatel ATT",
  64: "Novatel Telus",
  65: "Novatel ROW",
  66: "Point Asset Power",
  67: "Point Asset Battery",
  68: "Raliacom B2",
  69: "Verizon Equipment asset Tracking",
  70: "VDD",
  71: "Front DashCam WiFi",
  72: "Dual DashCam WiFi",
  73: "Verizon VT-410",
  74: "Xirgo 6360G",
  75: "Xirgo 6376A",
  76: "Xirgo 6372R",
  77: "Xirgo 6383R",
  78: "Xirgo 2060W",
  79: "Xirgo 2060G",
  80: "Xirgo EU",
  81: "Xirgo 2067 EU",
  82: "Xirgo XT6388 ICE",
  83: "KP2-VZ-Dual",
  84: "KP2-VZ-RFC",
  85: "KP2-VZ-DFC",
  86: "CP4-VZ",
  87: "CP4-VZG",
  88: "CP4-R-N",
  89: "CP4-R-P",
  90: "CP4-C-N",
  91: "CP4-C-P",
  92: "CP4-S-N",
  93: "CP4-S-P",
  94: "CP4-MON",
  95: "CP4-CH-SE",
};
