import { Col } from "@vds/grids";
import { SLNotification } from "../../components/core/Utility";
import React, { lazy, useContext, useEffect, useState } from "react";
import { generate } from "shortid";
import styled from "styled-components";
import { Header } from "../../components/core/Controls";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { ACTIVATE_BATCH_COLUMNS } from "../../constants/ActivateBatchConstants";
import { UploadContext } from "../../context/uploadContext";
import { batchHistory } from "../../services/Aggregator/batchHistory";
import { addPage } from "../../utility/navigation";
import { ActivateViewBatchMetadata } from "./ViewBatch";
import { Loader } from "@vds/loaders";

const LocationStyle = styled.div`
  margin-top: 10px;
  padding-left: 10px;
`;

export const StyledCol1 = styled(Col)`
  padding-bottom: 15px;
  padding-left: 0;
  padding-right: 0;
`;

export default function ActivateBatch() {
  const [batchHistoryArr, setBatchHistoryArr] = useState([]);
  const { batchNumber } = useContext(UploadContext);
  const { batchNumber: bnumber, setBatchNumber: setBNumber } = batchNumber;
  const [notification, setNotification] = useState();
  const [loading, setLoading] = useState(false);

  const getBatch = async () => {
    setLoading(true);
    try {
      return await batchHistory().then((response) => {
        setBatchHistoryArr(response?.rows ?? []);
        setLoading(false);
      });
    } catch (e) {
      notify("Unexpected error", "error");
      setLoading(false);
    }
  };
  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} />);
  };
  useEffect(() => {
    getBatch();
  }, []);

  const handleClickCallback = (data) => {
    setBNumber(data.batchNumber);
    addPage(ActivateViewBatchMetadata.id, { state: bnumber });
  };

  const formattedRows = (data) => {
    return [...(data || [])].map((d) => {
      d.uploadDate = new Date(d.uploadDate);
      return d;
    });
  };

  return (
    <>
      <Header>Upload Inactive Units</Header>
      <LocationStyle>
        {notification}
        <Loader active={loading} />
        <GridComponent
          isExportEnabled={true}
          isSelectionEnabled={false}
          rows={formattedRows(batchHistoryArr)}
          columns={ACTIVATE_BATCH_COLUMNS}
          title="Activate Batch - Upload History"
          hyperLinkClicked={handleClickCallback}
        />
      </LocationStyle>
    </>
  );
}

export const ActivateBatchMetadata = {
  name: "Activate Batch",
  id: "activatebatchpage",
  component: lazy(() => import("../ActivateBatch/index")),
  route: "/activatebatchpage",
};
