import { lazy, useState, useEffect, useContext } from "react";
import { VIEW_BATCH_TABLE } from "../../constants/UploadUnitsConstants";
import { Grid, Row } from "@vds/grids";
import { CompactCol, SmallText, SLGridLayout, SLRowLayout, SLNotification } from "../../components/core/Utility";
import styled from "@emotion/styled";
import { UploadContext } from "../../context/uploadContext";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { viewBatch } from "../../services/Aggregator/viewBatch";
import { modifyBatch } from "../../services/Aggregator/modifyBatch";
import { getProperty } from "../../utility/getProperty";
import { Header, SLButton } from "../../components/core/Controls";
import { Loader } from "@vds/loaders";

const UnitInfo = styled.div`
  background-color: var(--unit-info-box-bg-color);
  margin: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 20px;
  border-radius: 5px;
  box-shadow: 0 0px 1px rgba(0, 0, 0, 0.4);
  overflow: auto;
  padding: 10px;
`;

const LocationStyle = styled.div`
  margin-left: 20px;
  margin-right: 20px;
`;

const ContentWrapper = styled.div`
  padding: 10px 10px;
`;

const ViewBatches = () => {
  const { batchNumber } = useContext(UploadContext);
  const { batchNumber: batchNumberValue } = batchNumber;
  const [batchMetaData, setBatchMetaData] = useState("");
  const [batchNum, setBatchNum] = useState("");
  const [transformedVehicles, setTransformedVehicles] = useState([]);
  const [selectedEsnList, setSelectedEsnList] = useState([]);
  const [notificationStatus, setNotificationStatus] = useState("");
  const [notificationText, setNotificationText] = useState("");
  const [loading, setLoading] = useState(false);

  const viewBatchData = async () => {
    setLoading(true);
    await viewBatch({
      batchNumber: batchNumberValue,
    }).then((response) => {
      let err = getProperty(response, "error", "");
      if (err == "") {
        response.batch.uploadDate = new Date(response.batch.uploadDate).toLocaleString();
        setBatchMetaData(response.batch);
        setBatchNum(response.batch.batchNumber);
        setTransformedVehicles(response.vehicles);
      } else if (err.message.includes("404")) {
        setBatchMetaData("");
        setBatchNum(batchNumberValue);
        setTransformedVehicles([]);
        setNotificationStatus("error");
        setNotificationText("Batch is Empty!");
      }
      setLoading(false);
    });
  };

  const BATCH_DISPLAY_LEFT = {
    "Batch : ": batchNumberValue,
    "Brand : ": batchMetaData.brand,
    "Filename : ": batchMetaData.batchFilename,
    "Upload Date : ": batchMetaData.uploadDate,
    "Uploaded by User : ": batchMetaData.uploadByUser,
  };

  const BATCH_DISPLAY_RIGHT = {
    "Location : ": batchMetaData.location,
    "Box Type : ": batchMetaData.boxName,
    "Box Model : ": batchMetaData.boxModel,
    "Units Type : ": batchMetaData.unitType,
    "Units Count : ": batchMetaData.unitsCount,
  };

  useEffect(() => {
    viewBatchData();
  }, []);

  const getSelectedItemsCallback = (selectedItems) => {
    setSelectedEsnList(
      selectedItems.map((item) => {
        return item.esn;
      })
    );
  };

  const notificationHandler = () => {
    setNotificationStatus("");
    setNotificationText("");
  };

  const deleteEsnsFromBatch = () => {
    if (selectedEsnList.length == 0) {
      setNotificationStatus("error");
      setNotificationText("Please select an ESN to delete!");
    } else {
      const config = {
        request: {
          batchNumber: batchNum,
        },
        esnList: selectedEsnList,
      };
      modifyBatch(config).then((response) => {
        if (response === 200) {
          setNotificationStatus("success");
          setNotificationText("Esn's deleted Successfully!");
          viewBatchData();
          setSelectedEsnList([]);
        } else {
          setNotificationStatus("error");
          setNotificationText("Unable to delete ESN's.Please try again later!");
        }
      });
    }
  };

  return (
    <>
      <Header>View Batch - {batchNum}</Header>
      <Loader active={loading} />
      <ContentWrapper>
        {notificationStatus !== "" ? (
          <SLNotification
            type={notificationStatus}
            title={notificationText}
            fullBleed={true}
            inlineTreatment={true}
            onCloseButtonClick={() => notificationHandler()}
          />
        ) : (
          <></>
        )}
        <SLGridLayout>
          <SLRowLayout>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
              <UnitInfo>
                {Object.keys(BATCH_DISPLAY_LEFT).map((batch, index) => {
                  return (
                    <>
                      <strong key={index + "key1"}>
                        <SmallText css={{ display: "inline-block" }} bold>
                          {batch}{" "}
                        </SmallText>
                      </strong>
                      <SmallText css={{ display: "inline-block" }} key={index + "key2"}>
                        {BATCH_DISPLAY_LEFT[batch]}
                      </SmallText>
                      <br />
                    </>
                  );
                })}
              </UnitInfo>
            </CompactCol>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
              <UnitInfo>
                {Object.keys(BATCH_DISPLAY_RIGHT).map((batch, index) => {
                  return (
                    <>
                      <strong key={index + "key3"}>
                        <SmallText css={{ display: "inline-block" }} bold>
                          {batch}{" "}
                        </SmallText>
                      </strong>
                      <SmallText css={{ display: "inline-block" }} key={index + "key4"}>
                        {BATCH_DISPLAY_RIGHT[batch]}
                      </SmallText>
                      <br />
                    </>
                  );
                })}
              </UnitInfo>
            </CompactCol>
          </SLRowLayout>
        </SLGridLayout>
        <LocationStyle>
          <GridComponent
            isSelectionEnabled={true}
            isExportEnabled={true}
            noDataMessage="Add an ESN to display it in the grid"
            rows={transformedVehicles}
            columns={VIEW_BATCH_TABLE}
            getSelectedItems={getSelectedItemsCallback}
            title="Select one or more ESNs "
          />
        </LocationStyle>
        {transformedVehicles.length > 0 ? (
          <LocationStyle>
            <SLButton size="small" onClick={deleteEsnsFromBatch}>
              Delete
            </SLButton>
          </LocationStyle>
        ) : (
          <></>
        )}
      </ContentWrapper>
    </>
  );
};

export default ViewBatches;

export const ViewBatchMetadata = {
  name: "View Batch",
  id: "viewbatch",
  component: lazy(() => import("./ViewBatches")),
  route: "/viewbatch",
};
