import React, { lazy, useContext, useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { css } from "@emotion/react";
import { Loader } from "@vds/loaders";
import { Input, RadioButtonGroup, TextArea } from "@vds/inputs";
import { DropdownSelect } from "@vds/selects";
import { SLNotification } from "../../components/core/utility";
import { generate } from "shortid";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { Header, SLButton } from "../../components/core/Controls";
import {
  FlexRow,
  FlexColumn,
  MediumText,
  SmallText,
  UltraMediumText,
  CompactCol,
  SLGridLayout,
  SLRowLayout,
} from "../../components/core/Utility/index";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import Select from "react-select";
import { switchTab } from "../C4S Decommission - Replacement/utils";
import { Icon } from "@vds/icons";
import { getProcessingCodes } from "../../services/scheduler/getProcessingCodes";
import { getStatesList } from "../../services/C4S Decommission - Replacement/getStatesList";
import { addJob } from "../../services/scheduler/addJob";
import { sessionData, handleERPError, handleStartDate } from "../../utility/sessionData";
import { salesOrgBasedCurrency, salesOrgIdNameList } from "../../constants/PartialCompletionReasons";
import { addFeesJob } from "../../services/scheduler/addFeesJob";
import { max, groupBy, update, fill, isEqual } from "lodash";
import {
  ADDRESS_REGEX,
  CITY_REGEX,
  ZIP_REGEX,
  ZIP_CODE_LIMIT,
  VENDOR_ID,
  NAME_REGEX,
  EMAIL_REGEX_C360,
  ORIGINAL_PURCHASE_ORDER,
} from "../../constants/ValidationConstants";
import { Row, Grid } from "@vds/grids";
import { StyledCol } from "../C4S Decommission - Replacement/AddAVehicle";
import { getMaterialData } from "../../services/scheduler/getMaterialData";
import { getCountryList } from "../../services/scheduler/getCountryList";
import { validateAddress } from "../../services/scheduler/validateAddress";
import useOutsideClick from "../../customHook/use-outside-click";

export const RadioButtonGroupWrapper = styled.div`
  div[role="radiogroup"] {
    border: 0.0625rem solid #d8dada;
    // width: 70vmin;
    margin-bottom: 0.6rem;
    > div > label {
      > div:first-child > div {
        height: 1rem;
        width: 1rem;
        transform: translate(50%, 100%);
      }
      > div:last-child {
        font-size: 12px;
        font-family: Verizon-NHG-eTX;
        padding: 0.313rem 0.5rem;
        > span > span {
          font-size: 14px;
          font-family: Verizon-NHG-eTX;
        }
      }
    }
  }
`;

const CustomHistoricalLoader = css`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  margin-top: 0.5rem;
  div[aria-label="loader overlay"] {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  & div {
    padding-left: 0.5rem;
  }
`;
export const CustomDropdown = styled(DropdownSelect)`
  & div {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
    padding-bottom: ${(props) => (props.paddingBottom ? props.paddingBottom : "")};
    & label {
      & p {
        font-size: 12px;
        color: ${(props) => (props.disabled ? "#d8dada" : "")};
        & span {
          &::after {
            color: #747676;
          }
        }
      }
    }
    & span{
      & span{
        & div{
          & span{
            & svg{
              display:${(props) => (props.hideErrorImg ? "none !important" : "")};
            }
          }
        }
      }
    }
  }
  }
  & select {
    font-size: 12px;
    color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      // color: ${(props) => (props.disabled || props.disableValue ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
`;
export const CustomCalendar = styled(Input)`
  padding-bottom: 0.5rem;
  padding-right: 106px;
  height: auto;
  & label {
    & p {
      font-size: 12px;
      font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & span {
    & span {
      & span {
        width: ${(props) => (props.boxWidth ? props.boxWidth : "")};
      }
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;

export const TextAreaWrapper = styled.div`
  width: 100%;
  & label {
    & p {
      & span {
        display: none;
      }
    }
  }
  & span {
    & div {
      & div {
        & mark {
          background-color: transparent !important;
        }
      }
    }
  }
`;
export const CustomInput = styled(Input)`
  .ggJDVb {
    min-width: 0px !important;
  }
  .cWKEKC {
    min-width: 0px !important;
  }
  padding-bottom: 0.5rem;
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  height: auto;
  & label {
    & p {
      font-size: 12px;
      font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & span {
    & span {
      & span {
        width: ${(props) => (props.boxWidth ? props.boxWidth : "")};
      }
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;
export const CustomInputDynamicWidth = styled(Input)`
  padding-bottom: 0.5rem;
  height: auto;
  margin-right: ${(props) => (props.marginRight ? props.marginRight : "")};
  margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
  padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
  & label {
    & p {
      font-size: 12px;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & span {
    & span {
      & span {
        width: 100%;
      }
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: 100%;
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;
const CustomSLButton = styled(SLButton)`
  & span {
    padding: 0.68rem 2rem;
  }
`;
const CustomSelectCSS = css`
  width: 49%;
  font-size: 12px;
  input {
    width: 72.5% !important;
  }
  z-index: 999;
`;
export const CalendarWrapper = styled.div`
  > div > div:last-child {
    top: 3.5rem;
  }
  padding-right: "106px !important";
`;
const customStyle = {
  control: (base, state) => ({
    ...base,
    // fontSize: "12px",
    borderWidth: "0.0625rem",
    borderRadius: "0px",
    // width: "72.5% !important",
    borderStyle: "solid",
    borderColor: "rgb(216, 218, 218) rgb(216, 218, 218)",
    border: state.isFocused ? "1px solid gray" : "",
    // This line disable the blue border
    boxShadow: state.isFocused ? "1px solid gray" : "",
    "&:hover": {
      border: state.isFocused ? "1px solid gray" : "",
    },
    color: state.isDisabled && "rgb(216, 218, 218)",
    backgroundColor: state.isDisabled && "transparent",
    borderBottomColor: state.isDisabled ? "" : "rgb(0, 0, 0)",
  }),
  option: (styles, state) => ({
    ...styles,
    color: "#000",
    backgroundColor: state.label === state.selectProps.value?.label ? "#2196F3" : "transparent",
    ":hover": { ...styles[":hover"], backgroundColor: state.label !== state.selectProps.value?.label && "#a6d5fa" },
  }),
};
const Label = styled.label`
  font-family: "Verizon-NHG-eTX";
  font-size: 12px;
  margin-top: 10px;
  color: #000000;
`;

export const handleSalesOrg = (billingCountry, defaultCsp, source, defaultCase) => {
  let salesOrg = "",
    salesOrgName = "",
    salesOrgIdAndName = "",
    salesOrgCurrencyDetails,
    currency = "",
    currencySymbol = "",
    salesOrgAndNameList = [];
  if (source === "csp" && defaultCsp) {
    salesOrg = defaultCsp.Opp_Sales_Org;
    salesOrgName = defaultCsp.salesOrgName;
    salesOrgIdAndName = salesOrg + " " + salesOrgName;
    salesOrgCurrencyDetails = salesOrgBasedCurrency["SO_" + salesOrg];
    currency = salesOrgCurrencyDetails.currency;
    currencySymbol = salesOrgCurrencyDetails.currencySymbol;
  } else {
    salesOrgAndNameList = salesOrgIdNameList[billingCountry];
    salesOrg = defaultCase?.salesOrg;
    salesOrgName = defaultCase?.salesOrgName;
    salesOrgIdAndName = salesOrg + " " + salesOrgName;
    salesOrgCurrencyDetails = salesOrgBasedCurrency["SO_" + salesOrg];
    currency = salesOrgCurrencyDetails.currency;
    currencySymbol = salesOrgCurrencyDetails.currencySymbol;
  }
  return {
    salesOrg,
    salesOrgName,
    salesOrgIdAndName,
    currency,
    currencySymbol,
    options: salesOrgAndNameList,
  };
};

export default function CreateAdditionalFees({ jobData }) {
  const { accountId, source, accountDetails, cspDetails, caseDetails, countryListDetails, stateListDetails } = useContext(AccountDetailsContext);
  const defaultCsp = cspDetails?.cspDetails?.filter((cspDetail) => cspDetail?.DefaultCSP === "1")[0] ?? null;
  const defaultCase = caseDetails?.caseDetails?.filter((caseDetail) => caseDetail?.DefaultCase === "1")[0] ?? null;
  const crmReferenceId = source.source === "case" ? defaultCase?.CaseNumber ?? "" : defaultCsp?.CRM_Ref_ID ?? "";
  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter address 1", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
  });
  const [timezone, setTimezone] = useState("");
  const [zipCodeLimit, setZipCodeLimit] = useState("");
  const [zipRegex, setZipRegex] = useState("");
  const [latitude, setLatitude] = useState({
    value: "",
    error: false,
    errorText: "Enter latitude",
    success: false,
  });
  const [longitude, setLongitude] = useState({
    value: "",
    error: false,
    errorText: "Enter longitude",
    success: false,
  });
  const [verifyAddressIsDisable, setVerifyAddressIsDisable] = useState(true);
  const [suggestionsIsShown, setSuggestionsIsShown] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState({
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    longitude: "",
    latitude: "",
  });
  const [typedAddress, setTypedAddress] = useState("");
  const [showLatLong, setShowLatLong] = useState(false);
  const [feesDetails, setFeesDetails] = useState(null);
  const [processingCodes, setProcessingCodes] = useState([]);
  const [vendorId, setVendorId] = useState({
    value: null,
    error: false,
    errorText: "Enter Vendor Id",
    success: false,
  });
  const [originalPurchaseOrder, setOriginalPurchaseOrder] = useState({
    value: null,
    error: false,
    errorText: "Enter Original Purchase Order",
    success: false,
  });
  const [vendorNotes, setVendorNotes] = useState({
    value: null,
    error: false,
    errorText: "Enter Vendor Notes",
  });
  const [requestDate, setRequestDate] = useState({
    value: null,
    error: false,
    errorText: "",
    success: "",
  });
  const [scheduleDate, setScheduleDate] = useState({
    value: null,
    error: false,
    errorText: "",
    success: "",
  });
  const [contractAccountNumber, setContractAccountNumber] = useState({
    value: source.source === "csp" ? accountDetails?.contractAccount[0] ?? "" : "",
    options: source.source === "csp" ? accountDetails?.contractAccount ?? [] : [],
    error: source.source === "csp" ? !accountDetails?.contractAccount.length : false,
    errorText: source.source === "csp" ? "Unable to fetch contract accounts due to an unexpected error" : "Please enter contract account nummber",
    success: source.source === "csp" ? !!accountDetails.contractAccount.length : false,
  });
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isLoading, setLoader] = useState(false);
  const [notification, setNotification] = useState(null);
  const [descriptionList, setDescriptionList] = useState([]);
  const [selectedFeeOption, setSelectedFeeOption] = useState(null);
  const [addFeesMaterialsList, setAddFeesMaterialsList] = useState([]);
  const [name, setName] = useState({
    value: "",
    error: false,
    errorText: "Enter name",
    success: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    error: false,
    errorText: "Enter phone",
    success: false,
  });
  const [email, setEmail] = useState({
    value: "",
    error: false,
    errorText: "Enter email",
    success: false,
  });
  const countryZipLimitsList = {
    DEU: [5, 5],
    GBR: [4, 8],
    FRA: [5, 5],
    CAN: [6, 6],
    NLD: [4, 4],
    IRL: [7, 7],
    MEX: [5, 5],
    NZL: [4, 4],
    USA: [5, 5],
    AUS: [4, 4],
    AUT: [4, 4],
    BEL: [4, 4],
  };
  const [salesOrgAndCurrency, setSalesOrgAndCurrency] = useState({
    ...handleSalesOrg(accountDetails.billingCountry, defaultCsp, source.source, defaultCase),
    error: false,
    errorText: "Please select Sales Org",
    success: false,
  });
  const handleClickOutside = () => {
    setNotification(null);
  };

  const [isAddressvalidationLoader, setisAddressvalidationLoader] = useState(false);

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    let accountNotifiction =
      !accountDetails.customerName || !accountDetails.customerNumber || !accountDetails.vztSegment ? "account details and " : "";
    if (source.source === "case" && !caseDetails.caseDetails.length) {
      notify(`Unable to get ${accountNotifiction}case details due to an unexpected error`, "error", false);
    } else if (source.source !== "case" && !cspDetails.cspDetails.length) {
      notify(`Unable to get ${accountNotifiction}CSP details due to an unexpected error`, "error", false);
    }
    handleAddress();
  }, []);

  useEffect(() => {
    if (
      address.address1.success &&
      !address.address2.error &&
      address.city.success &&
      address.state.success &&
      address.country.success &&
      address.zip.success
    ) {
      if (!address.verified) {
        if (jobData?.id && address.addressByPass === "NeedToValidateOnLoad") {
          if (!jobData?.addressByPass) {
            handleAddressVerification(address);
          } else {
            setAddress((prevState) => ({ ...prevState, verified: true, addressByPass: jobData.addressByPass, locationFound: jobData.locationFound }));
          }
        } else {
          if (suggestionsIsShown) {
            !verifyAddressIsDisable && setVerifyAddressIsDisable(true);
            if (!suggestedAddress.address && suggestionsIsShown) {
              setSuggestionsIsShown(false);
              setAddress((prevState) => ({ ...prevState, verified: true }));
            }
          } else {
            verifyAddressIsDisable && setVerifyAddressIsDisable(false);
          }
        }
      }
    } else {
      if (address.addressByPass === "NeedToValidateOnLoad") {
        setAddress((prevState) => ({
          ...prevState,
          addressByPass: null,
        }));
      } else {
        if (!suggestedAddress.address && suggestionsIsShown) {
          setSuggestionsIsShown(false);
          setAddress((prevState) => ({ ...prevState, verified: false }));
        } else {
          !verifyAddressIsDisable && setVerifyAddressIsDisable(true);
        }
      }
    }
    if (
      salesOrgAndCurrency.salesOrg &&
      scheduleDate.value &&
      requestDate.value &&
      vendorId.value &&
      !vendorId.error &&
      originalPurchaseOrder.value &&
      !originalPurchaseOrder.error &&
      vendorNotes.value &&
      vendorNotes.value.length < 501 &&
      contractAccountNumber.value &&
      address.verified &&
      name.value &&
      email.value &&
      phone.value &&
      (feesDetails?.length ? feesDetails.every((fee) => fee.success) : false)
    ) {
      isSaveButtonDisabled && setIsSaveButtonDisabled(false);
    } else {
      !isSaveButtonDisabled && setIsSaveButtonDisabled(true);
    }
  }, [
    address.verified,
    address.addressByPass,
    address.address1.value,
    address.address2.value,
    address.city.value,
    address.state.value,
    address.zip.value,
    address.country.value,
    suggestedAddress.address,
    salesOrgAndCurrency.salesOrg,
    requestDate.value,
    scheduleDate.value,
    contractAccountNumber.value,
    vendorId.value,
    vendorId.error,
    originalPurchaseOrder.value,
    originalPurchaseOrder.error,
    vendorNotes.value,
    name.value,
    email.value,
    phone.value,
    feesDetails,
  ]);

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const handleFields = (field, value) => {
    switch (field) {
      case "Request Date": {
        let date = moment(value, "MMMM DD,YYYY").format("MM/DD/YYYY");
        setRequestDate((prevState) => ({ ...prevState, value: date, error: false, success: true }));
        break;
      }
      case "Schedule Date": {
        let date = moment(value, "MMMM DD,YYYY").format("MM/DD/YYYY");
        setScheduleDate((prevState) => ({ ...prevState, value: date, error: false, success: true }));
        break;
      }
      case "Vendor ID": {
        if (value && value.length) {
          if (VENDOR_ID.test(value)) {
            setVendorId({ value, error: false, errorText: "", success: true });
          } else {
            setVendorId({ value, error: true, errorText: "Enter a valid Vendor Id", success: false });
          }
        } else {
          setVendorId({ value: null, error: true, errorText: "Enter Vendor Id", success: false });
        }
        break;
      }
      case "Original Purchase Order": {
        if (value && value.length) {
          if (ORIGINAL_PURCHASE_ORDER.test(value)) {
            setOriginalPurchaseOrder({ value, error: false, errorText: "", success: true });
          } else {
            setOriginalPurchaseOrder({ value, error: true, errorText: "Enter a valid Original Purchase Order", success: false });
          }
        } else {
          setOriginalPurchaseOrder({ value: "", error: true, errorText: "Enter Original Purchase Order", success: false });
        }
        break;
      }
      case "Vendor Notes": {
        value && value.length
          ? setVendorNotes({ value, error: false, errorText: "Enter Vendor Notes" })
          : setVendorNotes({ value: "", error: true, errorText: "Enter Vendor Notes" });
        break;
      }
      case "Sales Org": {
        let salesOrgDetails = value.split(" "),
          salesOrg = salesOrgDetails[0],
          salesOrgName = salesOrgDetails.slice(1).join(" "),
          salesOrgIdAndName = salesOrg + " " + salesOrgName,
          salesOrgCurrencyDetails = salesOrgBasedCurrency["SO_" + salesOrg],
          currency = salesOrgCurrencyDetails.currency,
          currencySymbol = salesOrgCurrencyDetails.currencySymbol;
        setSalesOrgAndCurrency((prevState) => ({
          ...prevState,
          salesOrg,
          salesOrgName,
          salesOrgIdAndName,
          currency,
          currencySymbol,
          error: !salesOrg,
          success: !!salesOrg,
        }));
        materialDataRequest(salesOrg, true);
        break;
      }
      case "Contract Account Number": {
        value && value.length
          ? setContractAccountNumber({ value, error: false, errorText: "Please Enter Contract Account Number", success: true })
          : setContractAccountNumber({ value: "", error: true, errorText: "Please Enter Contract Account Number", success: false });
        break;
      }
      case "Name": {
        if (value && value.length) {
          if (NAME_REGEX.test(value)) {
            setName({ value, error: false, errorText: "Enter name", success: true });
          } else {
            setName({ value, error: true, errorText: "Enter a valid name", success: false });
          }
        } else {
          setName({ value: "", error: true, errorText: "Enter name", success: false });
        }
        break;
      }
      case "Phone": {
        let phoneRegex = /^([^a-zA-Z])+$/;
        if (value && value.length) {
          if (phoneRegex.test(value)) {
            setPhone({ value, error: false, errorText: "Enter phone number", success: true });
          }
          // else {
          //   setPhone({ value, error: true, errorText: "Enter a valid phone number", success: false });
          // }
        } else {
          setPhone({ value: "", error: true, errorText: "Enter phone number", success: false });
        }
        break;
      }
      case "Email": {
        if (value && value.length) {
          if (EMAIL_REGEX_C360.test(value)) {
            setEmail({ value, error: false, errorText: "Enter email", success: true });
          } else {
            setEmail({ value, error: true, errorText: "Enter a valid email", success: false });
          }
        } else {
          setEmail({ value: "", error: true, errorText: "Enter email", success: false });
        }
        break;
      }
    }
  };

  const getCountries = async () => {
    updateNewrelicStats();
    let countryOptions = [],
      allCountryStateOptions = null;
    let countryListResponse = countryListDetails.countryListDetails ? countryListDetails.countryListDetails : await getCountryList();
    if (countryListResponse.status === 200 && countryListResponse?.data?.data?.length) {
      countryOptions = countryListResponse.data.data;
      countryListDetails.setCountryListDetails(countryListResponse);
      ({ allCountryStateOptions } = await getStateList());
    } else {
      notify("Unable to fetch country list due to an unexpected error", "error");
    }
    setAddress((prevState) => ({
      ...prevState,
      country: {
        ...prevState.country,
        options: countryOptions,
        error: !countryOptions.length,
        errorText: countryOptions.length ? prevState.country.errorText : "Unable to fetch country list due to an unexpected error",
        success: false,
      },
      state: {
        ...prevState.state,
        options: [],
        allStateOptions: allCountryStateOptions,
        error: !allCountryStateOptions,
        errorText: allCountryStateOptions ? prevState.state.errorText : "Unable to fetch state list due to an unexpected error",
        success: false,
      },
    }));
    return { countryOptions, allCountryStateOptions };
  };

  const getStateList = async () => {
    updateNewrelicStats();
    let allCountryStateOptions = null;
    let stateListResponse = stateListDetails.stateListDetails ? stateListDetails.stateListDetails : await getStatesList();
    if (stateListResponse.status === 200 && stateListResponse?.data?.data?.length) {
      stateListDetails.setStateListDetails(stateListResponse);
      allCountryStateOptions = stateListResponse.data.data.map((state) => ({
        ...state,
        stateName: state.erpStateCode + " - " + state.stateCode,
      }));
      allCountryStateOptions = groupBy(allCountryStateOptions, "countryCode");
    } else {
      notify("Unable to fetch state list due to an unexpected error", "error");
    }
    return { allCountryStateOptions };
  };

  const handleAddress = async () => {
    setLoader(true);
    let { countryOptions, allCountryStateOptions } = await getCountries();
    salesOrgAndCurrency.salesOrg && (await materialDataRequest());
    setLoader(false);
    if (jobData && Object.keys(jobData).length) {
      handleAddressFields("Address 1", jobData.addressOne?.trim());
      handleAddressFields("Address 2", jobData.addressTwo?.trim());
      handleAddressFields("City", jobData.city?.trim());
      handleAddressFields(
        "Country",
        jobData.country?.trim(),
        jobData.stateCode?.trim(),
        jobData.zipCode?.trim(),
        countryOptions,
        allCountryStateOptions
      );
      handleFields("Latitude", jobData?.latitude ?? "");
      handleFields("Longitude", jobData?.longitude ?? "");
      setTimezone(jobData.timezone ?? "");
      setAddress((prevState) => ({
        ...prevState,
        addressByPass: "NeedToValidateOnLoad",
      }));
    }
  };

  const handleAddressFields = (
    field,
    value,
    customValue1 = false,
    customValue2 = false,
    countryOptions = false,
    allCountryStateOptions = false,
    trim = false
  ) => {
    if (!trim) {
      if (address.verified) {
        setAddress((prevState) => ({ ...prevState, verified: false }));
        latitude.value &&
          setLatitude({
            value: "",
            error: false,
            errorText: "Enter latitude",
            success: false,
          });
        longitude.value &&
          setLongitude({
            value: "",
            error: false,
            errorText: "Enter longitude",
            success: false,
          });
        showLatLong && setShowLatLong(false);
      }
    }
    switch (field) {
      case "Address 1": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: false, errorText: "Enter address 1", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: true, errorText: "Enter a valid address 1", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address1: { value: "", error: true, errorText: "Enter address 1", success: false } }));
        }
        break;
      }
      case "Address 2": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: false, errorText: "Enter address 2", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: true, errorText: "Enter a valid address 2", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address2: { value: "", error: false, errorText: "Enter address 2", success: false } }));
        }
        break;
      }
      case "City": {
        value && value.length && value.toLowerCase() !== "null"
          ? ADDRESS_REGEX.test(value)
            ? setAddress((prevState) => ({ ...prevState, city: { ...prevState.city, value, error: false, errorText: "Enter city", success: true } }))
            : setAddress((prevState) => ({
                ...prevState,
                city: { ...prevState.city, value, error: true, errorText: "Enter valid city", success: false },
              }))
          : setAddress((prevState) => ({
              ...prevState,
              city: { ...prevState.city, value: "", error: true, errorText: "Enter city", success: false },
            }));
        break;
      }
      case "State": {
        value = customValue1.find((state) => value === state.erpStateCode || value.toLowerCase() === state.stateCode.toLowerCase()) ?? null;
        if (value) {
          setAddress((prevState) => ({
            ...prevState,
            state: { ...prevState.state, value, error: false, errorText: "Please select state", success: true },
          }));
        } else {
          setAddress((prevState) => ({
            ...prevState,
            state: { ...prevState.state, value: null, error: true, errorText: "Please select state", success: false },
          }));
        }
        break;
      }
      case "Zip": {
        value = value.replace(/[^A-Z0-9 ]/g, "");
        if (value && value.length && value.toLowerCase() !== "null") {
          if (customValue1.test(value)) {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: false, errorText: "Enter zip code", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: true, errorText: "Enter a valid zip code", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, zip: { value: "", error: true, errorText: "Enter zip code", success: false } }));
        }
        break;
      }
      case "Country": {
        countryOptions = countryOptions || address.country.options;
        let stateOptions = allCountryStateOptions || address.state.allStateOptions;
        value =
          countryOptions.find(
            (country) => country.code.toLowerCase() === value.toLowerCase() || country.description.toLowerCase() === value.toLowerCase()
          ) ?? null;
        let { zipCodeRegex } = handleZipCodeBasedOnCountry(value?.code ?? null);
        if (value) {
          stateOptions = stateOptions ? stateOptions[value.code] : [];
          setAddress((prevState) => ({
            ...prevState,
            country: { ...prevState.country, value, error: false, errorText: "Please select country", success: true },
            state: {
              ...prevState.state,
              value: null,
              options: stateOptions,
              error: !stateOptions.length || prevState.state.value || prevState.state.error ? true : false,
              success: false,
            },
          }));
          stateOptions.length && customValue1 && handleAddressFields("State", customValue1, stateOptions);
        } else {
          setAddress((prevState) => ({
            ...prevState,
            country: {
              ...prevState.country,
              value: null,
              error: true,
              errorText: prevState.country.error ? prevState.country.errorText : "Please select country",
              success: false,
            },
            state: {
              ...prevState.state,
              value: null,
              options: [],
              error: prevState.state.value || prevState.state.error ? true : false,
              success: false,
            },
          }));
        }
        customValue2
          ? handleAddressFields("Zip", customValue2, zipCodeRegex)
          : address.zip.value && handleAddressFields("Zip", address.zip.value, zipCodeRegex);
        break;
      }
      case "Latitude": {
        if (value && value.length) {
          if (isFinite(value) && Math.abs(value) <= 90) {
            setLatitude((prevState) => ({ ...prevState, value, error: false, errorText: "Enter latitude", success: true }));
          } else {
            setLatitude((prevState) => ({ ...prevState, value, error: true, errorText: "Enter a valid latitude", success: false }));
          }
        } else {
          setLatitude((prevState) => ({ ...prevState, value: "", error: false, errorText: "Enter latitude", success: false }));
        }
        break;
      }
      case "Longitude": {
        if (value && value.length) {
          if (isFinite(value) && Math.abs(value) <= 180) {
            setLongitude((prevState) => ({ ...prevState, value, error: false, errorText: "Enter longitude", success: true }));
          } else {
            setLongitude((prevState) => ({ ...prevState, value, error: true, errorText: "Enter a valid longitude", success: false }));
          }
        } else {
          setLongitude((prevState) => ({ ...prevState, value: "", error: false, errorText: "Enter longitude", success: false }));
        }
        break;
      }
    }
  };

  const handleZipCodeBasedOnCountry = (country) => {
    let zipCodeRegex = ZIP_REGEX,
      zipCodeLimit = ZIP_CODE_LIMIT;
    if (country) {
      let limits = countryZipLimitsList[country];
      (zipCodeRegex = new RegExp(`^\\d{${limits[0]},${limits[1]}}$`)), (zipCodeLimit = limits[1]);
      if (["GBR", "CAN", "IRL", "NLD"].includes(country)) {
        if (country === "IRL") {
          zipCodeRegex = new RegExp("^[A-Z0-9]{3} [A-Z0-9]{4}$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "CAN") {
          zipCodeRegex = new RegExp("^[A-Z]\\d[A-Z] \\d[A-Z]\\d$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "GBR") {
          zipCodeRegex = new RegExp(`^[a-zA-Z0-9 ]{${limits[0]},${limits[1]}}$`);
          zipCodeLimit = limits[1];
        }
        if (country === "NLD") {
          zipCodeRegex = new RegExp("^[0-9]{4} [A-Z]{2}$");
          zipCodeLimit = 7;
        }
      } else {
        zipCodeLimit = limits[1];
      }
    }
    setZipRegex(zipCodeRegex);
    setZipCodeLimit(zipCodeLimit);
    return { zipCodeRegex };
  };

  const handleAddressVerification = async (address) => {
    updateNewrelicStats();
    setisAddressvalidationLoader(true);
    !verifyAddressIsDisable && setVerifyAddressIsDisable(true);
    showLatLong && setShowLatLong(false);
    let data = {
      inputAddress: {
        addressLine1: address.address1.value,
        addressLine2: address.address2.value ? address.address2.value : "",
        city: address.city.value,
        stateProvince: address.state.value.erpStateCode,
        postalCode: address.zip.value,
        country: address.country.value.code,
      },
    };
    let response = await validateAddress(data);
    if (response.status === 200 && response?.data?.message.toLowerCase() === "success") {
      let responsedata = response.data.data,
        addressByPass = responsedata.addressByPass,
        locationFound = responsedata.locationFound,
        stdAddress = responsedata?.standardizedInfo?.standardizedAddress,
        userAddress = responsedata.inputAddress,
        standardAddress = {
          address1: stdAddress?.addressLine1?.toLowerCase(),
          address2: stdAddress?.subLocation?.toLowerCase() ?? "",
          city: stdAddress?.city?.toLowerCase(),
          state: stdAddress?.stateProvince?.toLowerCase(),
          zip: stdAddress?.postalCodeBase?.toLowerCase(),
          country: stdAddress?.iso3CountryCode?.toLowerCase(),
        },
        userTypedAddress = {
          address1: userAddress.addressLine1?.toLowerCase(),
          address2: userAddress.addressLine2?.toLowerCase() ?? "",
          city: userAddress.city.toLowerCase(),
          state: userAddress.stateProvince.toLowerCase(),
          zip: userAddress.postalCode.toLowerCase(),
          country: userAddress.country.toLowerCase(),
        },
        isExactMatch = isEqual(standardAddress, userTypedAddress),
        latitude = stdAddress?.latLong?.latitude ?? "",
        longitude = stdAddress?.latLong?.longitude ?? "";
      setTimezone(responsedata?.location?.timezone ?? "");
      if (addressByPass === "YES") {
        setAddress((prevState) => ({ ...prevState, verified: true }));
        if (locationFound) {
          if (isExactMatch) {
            handleFields("Latitude", latitude);
            handleFields("Longitude", longitude);
          } else {
            address.country.value.code === "MEX" && setShowLatLong(true);
            address.country.value.code === "USA" &&
              notify(
                `Please note that the entered address is not verified and couldn't find
          a close match. However, you may continue utilizing the entered address`,
                "warning"
              );
          }
        } else {
          address.country.value.code === "MEX" && setShowLatLong(true);
          notify(
            `Please note that the entered address is not verified and couldn't find
            a close match. However, you may continue utilizing the entered address`,
            "warning"
          );
        }
      }
      // else {
      //   if (locationFound) {
      //     if (isExactMatch) {
      //       setAddress((prevState) => ({ ...prevState, verified: true }));
      //       handleFields("Latitude", latitude);
      //       handleFields("Longitude", longitude);
      //     } else {
      //       setSuggestionsIsShown(true);
      //       setSuggestedAddress({
      //         address: `${stdAddress.addressLine1}, ${stdAddress.subLocation ? stdAddress.subLocation + ", " : ""}${stdAddress.city}, ${
      //           stdAddress.stateProvince
      //         }, ${stdAddress.postalCodeBase}, ${stdAddress.iso3CountryCode}`,
      //         address1: stdAddress.addressLine1,
      //         address2: stdAddress.subLocation,
      //         city: stdAddress.city,
      //         state: stdAddress.stateProvince,
      //         zip: stdAddress.postalCodeBase,
      //         country: stdAddress.iso3CountryCode,
      //         longitude,
      //         latitude,
      //       });
      //       setTypedAddress(
      //         `${userAddress.addressLine1}, ${userAddress.addressLine2 ? userAddress.addressLine2 + ", " : ""}${userAddress.city}, ${
      //           userAddress.stateProvince
      //         }, ${userAddress.postalCode}, ${userAddress.country}`
      //       );
      //     }
      //   } else {
      //     notify("We couldn't verify the entered address and couldn't find a close match", "error");
      //   }
      // }
      setAddress((prevState) => ({ ...prevState, addressByPass, locationFound }));
    } else {
      setTimezone("");
      notify("Address validation failed due to an unexpected error", "error");
    }
    setisAddressvalidationLoader(false);
  };

  const handleAddressSelection = (verified = false) => {
    if (verified) {
      handleAddressFields("Address 1", suggestedAddress.address1);
      handleAddressFields("Address 2", suggestedAddress.address2);
      handleAddressFields("City", suggestedAddress.city);
      handleAddressFields("Country", suggestedAddress.country, suggestedAddress.state, suggestedAddress.zip);
      handleFields("Latitude", suggestedAddress.latitude);
      handleFields("Longitude", suggestedAddress.longitude);
    }
    setSuggestedAddress((prevState) => ({
      ...prevState,
      address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      longitude: "",
      latitude: "",
    }));
    setTypedAddress("");
  };

  const materialDataRequest = async (salesOrgId = salesOrgAndCurrency.salesOrg, loader = false) => {
    updateNewrelicStats();
    loader && setLoader(true);
    let data = {
      salesOrgId,
      flag: "FLAG_VENDOR_FEE",
    };
    let response = await getMaterialData(data);
    if (response && response?.data && response?.data?.statusCode === "200" && response?.data?.data) {
      let materialInfo = response?.data?.data;
      setAddFeesMaterialsList(materialInfo);
      materialInfo = materialInfo?.map((item) => ({
        label: `${item.materialName}-${item.materialId}`,
        materialId: item.materialId,
        materialName: item.materialName,
        baseMeasurementUnit: item.baseMeasurementUnit,
      }));
      setDescriptionList(materialInfo);
      !processingCodes.length && (await getProcessingCodesData());
    } else {
      descriptionList.length && setDescriptionList([]);
      notify("Unable to fetch materials due to an unexpected error", "error");
    }
    loader && setLoader(false);
  };

  const getProcessingCodesData = async () => {
    updateNewrelicStats();
    let processCodesResponse = await getProcessingCodes();
    if (processCodesResponse?.status === 200 && processCodesResponse?.data?.data?.length)
      if (processCodesResponse.data?.data?.length) {
        setProcessingCodes(processCodesResponse?.data?.data);
      } else {
        notify("Unable to fetch Processing Codes due to an unexpected error", "error");
      }
  };

  const addMoreFees = (option) => {
    let tempFees = feesDetails ? [...feesDetails] : [];
    tempFees.push({
      serviceName: { value: option.materialName, error: !option.materialName, errorText: "Select Service Name", success: !!option.materialName },
      mmId: { value: option.materialId, error: !option.materialId, errorText: "Enter MMID", success: !!option.materialId },
      quantity: { value: 0, error: false, errorText: "Enter Quantity", success: false },
      processingCode: {
        value: "",
        error: !processingCodes.length,
        errorText: "Unable to fetch processing code due to an unexpected error",
        success: !!processingCodes.length,
      },
      unitOfMeasure: {
        value: option.baseMeasurementUnit,
        error: !option.baseMeasurementUnit,
        errorText: "Enter Unit Of Measure",
        success: !!option.baseMeasurementUnit,
      },
      charge: {
        value: "",
        formattedValue: "",
        error: false,
        errorText: "Enter Charge",
        success: false,
        showFormattedInput: false,
        focused: false,
      },
      materialLineId: "",
      techRefId: "",
      manualAdjustments: "",
      rhiInstalledQuantity: "",
      customerCharge: "",
      success: false,
      uniqueKey: generate(),
    });
    setFeesDetails(tempFees);
  };

  const deleteFeesHandler = (feeToRemoveIndex) => {
    let tempArray = [...feesDetails];
    tempArray = tempArray.filter((fee, index) => index !== feeToRemoveIndex);
    setFeesDetails(tempArray);
  };

  const chargeFocusHandler = (fees, index, value) => {
    let updatedFees = update(fees, "charge.focused", () => value);
    handleChargeInputsToggle(updatedFees, index, !value);
  };

  const handleChargeInputsToggle = (fees, index, value) => {
    let tempFees = [...feesDetails],
      updatedFees = update(fees, "charge.showFormattedInput", () => value);
    tempFees = [...fill(tempFees, updatedFees, index, index + 1)];
    setFeesDetails(tempFees);
  };

  const handleFeesFields = (field, value, fees, index) => {
    switch (field) {
      case "quantity": {
        let updatedFees = { ...fees },
          errorText = "Please enter quantity";
        if (!(value && value > 0 && value < 10000)) {
          value = 0;
          errorText = "Please enter a valid quantity";
        }
        updatedFees = update(fees, field, () => ({ ...fees["quantity"], value, error: !value, errorText, success: !!value }));
        feesDataCompletionChecker(updatedFees, index);
        break;
      }
      case "processingCode": {
        let updatedFees = { ...fees };
        updatedFees = update(fees, "processingCode", () => ({ ...fees["processingCode"], value }));
        feesDataCompletionChecker(updatedFees, index);
        break;
      }
      case "charge": {
        let updatedFees = { ...fees },
          errorText = "Please enter charge",
          formattedValue = fees.formattedValue;
        let chargeRegex = /^([0-9.])+$/;
        if (value && value.length && chargeRegex.test(value)) {
          formattedValue =
            salesOrgAndCurrency.currency && salesOrgAndCurrency.currencySymbol
              ? salesOrgAndCurrency.currencySymbol + value + " (" + salesOrgAndCurrency.currency + ")"
              : value;
        } else {
          value = "";
          formattedValue = "";
          errorText = "Please enter a valid charge";
        }
        updatedFees = update(fees, "charge", () => ({
          ...fees["charge"],
          value,
          formattedValue,
          error: !value,
          errorText,
          success: !!value,
          focused: true,
        }));
        feesDataCompletionChecker(updatedFees, index);
        break;
      }
    }
  };

  const feesDataCompletionChecker = (fees, index) => {
    let updatedFees = { ...fees },
      tempFees = [...feesDetails];
    if (
      fees.serviceName.value &&
      fees.mmId.value &&
      fees.quantity.value &&
      fees.processingCode.value &&
      (fees.processingCode.value === "ZBTP - Customer Fee" ? fees.charge.value : true) &&
      fees.unitOfMeasure.value
    ) {
      updatedFees = !fees.success ? update(fees, "success", () => true) : updatedFees;
    } else {
      updatedFees = fees.success ? update(fees, "success", () => false) : updatedFees;
    }
    tempFees = [...fill(tempFees, updatedFees, index, index + 1)];
    setFeesDetails(tempFees);
  };

  const handleSave = () => {
    let cspAccDetails = source.source !== "case" ? defaultCsp : null;
    let caseAccDetails = source.source === "case" ? defaultCase : null;
    let data = {
      status: "OPEN",
      startDate: requestDate.value,
      appointmentLength: "",
      estAppointmentLength: "",
      contactName: name.value,
      phone: phone.value,
      email: email.value,
      accountId: accountId.accountId,
      address1: address.address1.value,
      address2: address.address2.value ?? null,
      city: address.city.value,
      stateCode: address.state.value.erpStateCode,
      country: address.country.value.code,
      zipCode: address.zip.value,
      timezone: timezone,
      addressByPass: address.addressByPass,
      locationFound: address.locationFound,
      division: accountDetails.division ?? "",
      distributionChannel: accountDetails.distributionChannel ?? "",
      customerName: accountDetails.customerName,
      customerNumber: accountDetails.customerNumber,
      vztSegment: accountDetails.vztSegment,
      modifiedAt: new Date(),
      modifiedBy: accountDetails.sfdcUsername ?? null,
      createdAt: new Date(),
      createdBy: accountDetails.sfdcUsername,
      isDeleted: false,
      erpLastModifiedAt: new Date(),
      extendedJobId: null,
      latitude: latitude.value,
      longitude: longitude.value,
      scheduleType: ["united states", "usa"].includes(address.country.value.code.toLowerCase()) ? "AUTO" : "MANUAL",
      scheduleStartTime: "",
      scheduleEndDate: "",
      scheduleEndTime: "",
      scheduledDate: scheduleDate?.value,
      erpBusinessPartnerId: accountDetails.erpBpNumber,
      providerOrder: source.source === "csp" ? accountDetails.providerOrder : "",
      erpTicketType: source.source === "csp" ? "ZINS" : "ZSRQ",
      assignToName: JSON.parse(sessionStorage.getItem("Name")),
      erpProductRecipientPartyId: accountDetails.erpBpNumber,
      salesOrg: salesOrgAndCurrency.salesOrg,
      resellerBusinessPartnerNumber: accountDetails.resellerBusinessPartnerNumber,
      brand: "",
      originalPurchaseOrder: originalPurchaseOrder.value,
      vendorNotes: vendorNotes.value,
      jobDetails: [
        {
          cspSfdcId: cspAccDetails?.CSPID ?? "",
          primaryDevice: null,
          addOns: "updated",
          vehicleCount: "",
          jobType: source.source === "case" ? "" : "INSTALL",
          opportunityId: null,
          cspOwner: cspAccDetails?.CSPOwner ?? "",
          cspName: cspAccDetails?.CSPName ?? "",
          caseSfdcId: caseAccDetails?.CaseSFDCID ?? "",
          caseOwner: caseAccDetails?.CaseOwner ?? "",
          caseNumber: caseAccDetails?.CaseNumber ?? "",
          modifiedAt: new Date(),
          modifiedBy: accountDetails.sfdcUsername ?? "",
          createdAt: new Date(),
          createdBy: accountDetails.sfdcUsername ?? "",
          isDeleted: false,
          workTickets: [
            {
              workTicket: "",
            },
          ],
        },
      ],
      contractAccountId: contractAccountNumber.value,
      crmReferenceId: crmReferenceId ?? "",
      jobDurationDetails: [],
      erpVendorId: vendorId?.value,
      source: "Add_Fees",
    };
    let missingFieldsArray = [];
    for (const key of Object.keys(data)) {
      if (key === "crmReferenceId" || key === "distributionChannel" || key === "division") {
        if (data[key] === "" || data[key] === undefined || data[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to create job due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    }
    handleJobCreation(data);
  };

  const handleJobCreation = async (data) => {
    updateNewrelicStats();
    setLoader(true);
    let response = await addJob(data);
    if (response.status === 200 && response?.data?.data?.id) {
      // notify("Job created successfully", "success");
      // sessionData(response.data.data.id);
      // setTimeout(() => {
      //   setNotification(null);
      // }, 5000);
      setLoader(false);
      postTicketClosure(response?.data?.data);
    } else {
      notify(response.status === 200 && response.data?.message ? response.data?.message : "Job creation failed due to an unexpected error", "error");
      setLoader(false);
    }
  };
  let emptyMaterialLineId = [],
    emptyMaterialId = [],
    emptyItemDescription = [],
    emptyQuantity = [],
    emptyProcessingCode = [],
    emptyLineItemUniqueId = [],
    emptyUnitMeasure = [];

  const postTicketClosure = async (jobDetails) => {
    updateNewrelicStats();
    let name, firstName, lastName;
    name = jobDetails?.contactName?.split(" ");
    firstName = name ? (name.length > 1 ? name.slice(0, name.length - 1).join(" ") : name[0]) : "";
    lastName = name?.length > 1 ? name[name.length - 1] : "";
    let feesData = [],
      materialLineId = 0;
    feesDetails.map((newData) => {
      if (newData.materialId !== "") {
        materialLineId = max(feesDetails.map((fee) => (fee.materialLineId ? Number(fee.materialLineId) : materialLineId))) + 10;
        let wtMaterialId = addFeesMaterialsList?.filter((job) => job.materialId === newData.mmId.value)[0]?.wtMaterialId || "";
        let materialUniqueId = materialLineId?.toString().padStart(6, "0");
        let tempObj = {
          id: null,
          customerPONumber: source.source === "csp" ? defaultCsp.Provider_Order : null,
          materialName: newData.serviceName.value,
          materialId: newData.mmId.value,
          processingCode: newData.processingCode.value.split(" ")[0],
          quantity: newData.quantity.value,
          customerCharge: newData.charge.value,
          lineItemUniqueId: jobDetails?.id + "_" + materialUniqueId,
          materialLineId: materialUniqueId,
          unitOfMeasure: newData?.unitOfMeasure?.value,
          techRefId: newData.techRefId,
          manualAdjustments: newData.manualAdjustments,
          rhiInstalledQuantity: newData.rhiInstalledQuantity,
          wtMaterialId: wtMaterialId || "",
        };
        feesData.push(tempObj);
      }
    });
    let payload = {
      serviceJobId: jobDetails?.serviceJobId ?? "",
      documentType: source.source === "csp" ? "ZINS" : "ZSRQ",
      salesOrg: salesOrgAndCurrency.salesOrg,
      currencyCode: accountDetails?.currencyCode ?? "",
      distributionChannel: accountDetails.distributionChannel,
      division: accountDetails.distributionChannel,
      crmReferenceId: crmReferenceId,
      contractAccount: contractAccountNumber.value,
      erpVendorId: vendorId.value,
      // assignedTo: accountDetails.employeeId ?? "",
      subStatus: "",
      oldDeliveryNo: "",
      soldToParty: accountDetails.erpBpNumber ?? "",
      shipToParty: accountDetails.erpBpNumber ?? "",
      modifiedBy: accountDetails?.employeeFullName,
      modifiedByEmail: accountDetails?.employeeEmail,
      modifiedByEid: accountDetails?.employeeId,
      assignedTo: source.source === "csp" ? defaultCsp?.CSPOwner : defaultCase?.CaseOwner,
      assignedToEmail: source.source === "csp" ? defaultCsp?.CSPOwnerEmail : defaultCase?.CaseOwnerEmail,
      assignedToEid: source.source === "csp" ? defaultCsp?.CSPOwnerEId : defaultCase?.CaseOwnerEId,
      vendorMessage: vendorNotes.value,
      serviceAddress: {
        lastName,
        firstName,
        address1: jobDetails?.address1 ?? "",
        address2: jobDetails?.address2 ?? "",
        city: jobDetails?.city ?? "",
        state: jobDetails?.stateCode ?? "",
        postalCode: jobDetails?.zipCode ?? "",
        country: jobDetails?.country ?? "",
      },
      partners: [
        {
          party: accountDetails.resellerBusinessPartnerNumber || accountDetails.erpBpNumber,
          partRoleCode: "AG",
        },
        {
          party: accountDetails.erpBpNumber ?? "",
          partRoleCode: "WE",
        },
        {
          party: accountDetails.resellerBusinessPartnerNumber || accountDetails.erpBpNumber,
          partRoleCode: "Z6",
          accountName: accountDetails.customerName ?? "",
          contactName: jobDetails?.contactName ?? "",
          contactPhone: jobDetails?.phone ?? "",
          contactEmail: jobDetails?.email ?? "",
          street: jobDetails?.address1 ?? "",
          city: jobDetails?.city ?? "",
          state: jobDetails?.stateCode ?? "",
          zip: jobDetails?.zipCode ?? "",
          country: jobDetails?.country ?? "",
          telephone: "",
        },
      ],
      itemDetails: feesData.map((job) => {
        return {
          id: job.jobDurationDetailId ? job.jobDurationDetailId : job.id,
          materialLineId: job.materialLineId,
          materialId: job.materialId,
          itemDescription: job.materialName,
          quantity: job.quantity,
          unitMeasure: job.unitOfMeasure,
          techRefId: job.techRefId,
          processingCode: job.processingCode,
          lineItemUniqueId: job.lineItemUniqueId,
          customerPONumber: source.source === "csp" ? defaultCsp?.Provider_Order : null, // FROM CSP ONLY
          customerPONumberKut: source.source === "case" ? defaultCsp?.Provider_Order : null, // FROM CASE ONLY
          baseItemId: "",
          esnToBeReturned: "",
          installedQuantity: job.quantity,
          manualAdjustments: job.manualAdjustments,
          rhiInstalledQuantity: job.rhiInstalledQuantity,
          customerCharge: job.customerCharge,
          wtMaterialId: job.wtMaterialId || "",
        };
      }),
    };
    let errorMsg = [];
    let missingFieldsArray = [];
    for (const key of Object.keys(payload)) {
      if (
        key === "serviceJobId" ||
        key === "salesOrg" ||
        key === "crmReferenceId" ||
        key === "currencyCode" ||
        key === "distributionChannel" ||
        key === "division" ||
        key === "erpVendorId" ||
        key === "contractAccount" ||
        key === "assignedTo"
      ) {
        if (payload[key] === "" || payload[key] === undefined || payload[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    for (const key of Object.keys(payload.serviceAddress)) {
      if (key === "address1" || key === "city" || key === "state" || key === "postalCode" || key === "country") {
        if (payload.serviceAddress[key] === "" || payload.serviceAddress[key] === undefined || payload.serviceAddress[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to add fees due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    } else if (accountDetails.resellerBusinessPartnerNumber === null && accountDetails.erpBpNumber === null) {
      return setTimeout(() => {
        notify(
          "Unable to add fees due to missing information. Kindly contact IT team for support. Missing information: Reseller Business Partner Number).",
          "error",
          false
        );
      }, 10);
    } else {
      payload?.itemDetails?.filter((data) => {
        for (const key of Object.keys(data)) {
          if (
            key === "materialLineId" ||
            key === "materialId" ||
            key === "itemDescription" ||
            key === "unitMeasure" ||
            key === "quantity" ||
            key === "processingCode" ||
            key === "lineItemUniqueId"
          ) {
            if (data[key] === "" || data[key] === undefined || data[key] === null) {
              captureMaterialId(key, data.materialId);
            }
          }
        }
      });
      if (emptyMaterialId.length > 0) errorMsg.push(` ${"Material Id"} is missing for ${emptyMaterialId.toString()}`);
      if (emptyMaterialLineId.length > 0) errorMsg.push(` ${"Material Line Id"} is missing for ${emptyMaterialLineId.toString()}`);
      if (emptyItemDescription.length > 0) errorMsg.push(` ${"Item Description"} is missing for ${emptyItemDescription.toString()}`);
      if (emptyQuantity.length > 0) errorMsg.push(` ${"Quantity"} is missing for ${emptyQuantity.toString()}`);
      if (emptyProcessingCode.length > 0) errorMsg.push(` ${"Processing Code"} is missing for ${emptyProcessingCode.toString()}`);
      if (emptyLineItemUniqueId.length > 0) errorMsg.push(` ${"LineItem Unique Id"} is missing for ${emptyLineItemUniqueId.toString()}`);
      if (emptyUnitMeasure.length > 0) errorMsg.push(` ${"Unit of Measure"} is missing for ${emptyUnitMeasure.toString()}`);

      if (errorMsg.length > 0) {
        return setTimeout(() => {
          notify(
            <>
              {"Unable to add fees due to below missing information. Kindly contact IT team for support. Missing Information: "}
              {errorMsg.map((msg) => (
                <div key={generate()}>{msg}</div>
              ))}
            </>,
            "error",
            false
          );
        }, 10);
      }
    }
    setLoader(true);
    let response = await addFeesJob(payload, jobDetails?.id);
    if (response && response?.data?.statusCode === "200" && response?.data?.message?.toLowerCase() === "success") {
      let message = response?.data?.data?.message;
      notify(message, "success");
      setLoader(false);
      switchTab(2, { data: { source: "Add_Fees" } });
    } else if (response && response?.data?.statusCode === "404") {
      let message = response?.data?.message;
      response?.data?.data ? handleERPError(response?.data, notify) : notify(message, "error");
      setLoader(false);
    } else {
      notify("We are currently experiencing issues, please try again", "error");
      setLoader(false);
    }
  };

  const navigate = () => {
    switchTab(2, { data: { source: "Add_Fees" } });
  };

  const captureMaterialId = (materialName, materialId) => {
    switch (materialName) {
      case "materialLineId": {
        emptyMaterialLineId.push(materialId);
        break;
      }
      case "materialId": {
        emptyMaterialId.push(materialId);
        break;
      }
      case "itemDescription": {
        emptyItemDescription.push(materialId);
        break;
      }
      case "quantity": {
        emptyQuantity.push(materialId);
        break;
      }
      case "processingCode": {
        emptyProcessingCode.push(materialId);
        break;
      }
      case "lineItemUniqueId": {
        emptyLineItemUniqueId.push(materialId);
        break;
      }
      case "unitMeasure": {
        emptyUnitMeasure.push(materialId);
        break;
      }
    }
  };

  return (
    <>
      <Loader active={isLoading} fullscreen />
      <Header role="main" tabIndex={0} ariaLabel="Add Fees">
        Add Fees
      </Header>
      {notification}
      <FlexRow tabIndex={0} css={{ marginTop: "1.5rem" }}>
        <SmallText bold>{"Manually add fees to the tickets that had been closed or to tickets that can not be found."}</SmallText>
      </FlexRow>
      <FlexRow css={{ marginTop: "1.5rem" }}>
        <MediumText tabIndex={0} bold>
          Ticket Information
        </MediumText>
      </FlexRow>
      <SLGridLayout>
        <SLRowLayout css={{ marginTop: "0.5rem", paddingLeft: "0.6rem !important" }}>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            <Label> CRM Reference ID </Label>
            <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
              {crmReferenceId || "--"}
            </UltraMediumText>
          </CompactCol>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            {!salesOrgAndCurrency?.options?.length ? (
              <>
                <Label> Sales Org </Label>
                <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
                  {salesOrgAndCurrency.salesOrg && salesOrgAndCurrency.salesOrgName
                    ? `${salesOrgAndCurrency.salesOrg} (${salesOrgAndCurrency.salesOrgName})`
                    : "--"}
                </UltraMediumText>
              </>
            ) : (
              <CustomDropdown
                width="85%"
                paddingRight="10px"
                type="text"
                hideErrorImg={salesOrgAndCurrency.error}
                label="Sales Org"
                errorText={salesOrgAndCurrency.errorText}
                error={salesOrgAndCurrency.error}
                value={salesOrgAndCurrency.salesOrgIdAndName || "Please select"}
                onChange={(e) => handleFields("Sales Org", e.target.value)}
              >
                <>
                  <option disabled>Please select</option>
                  {salesOrgAndCurrency.options.map((salesOrg, index) => (
                    <option key={index}>{salesOrg}</option>
                  ))}
                </>
              </CustomDropdown>
            )}
          </CompactCol>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            <Label> Distribution Channel </Label>
            <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
              {accountDetails?.distributionChannel || "--"}
            </UltraMediumText>
          </CompactCol>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 2 }}>
            <Label> Division </Label>
            <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
              {accountDetails?.division || "--"}
            </UltraMediumText>
          </CompactCol>
        </SLRowLayout>
        <SLRowLayout css={{ marginTop: "1rem", paddingLeft: "0.6rem !important" }}>
          <CompactCol colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            <CustomInput
              width="95%"
              type="calendar"
              label="Schedule Date"
              aria-label="Schedule Date"
              error={scheduleDate.error}
              errorText={scheduleDate.errorText}
              minDate={new Date(1998, 1, 1)}
              value={scheduleDate.value ? moment(scheduleDate.value, "MM/DD/YYYY").format("MMM D, yyyy") : ""}
              // selectedDate={scheduleDate.value ? new Date(scheduleDate.value) : new Date(moment().add(1, "days"))}
              onChange={(event) => handleFields("Schedule Date", event)}
            />
          </CompactCol>
          <CompactCol colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            <CustomInput
              width="95%"
              type="calendar"
              label="Request Date"
              aria-label="Request Date"
              error={requestDate?.error}
              errorText={requestDate?.errorText}
              value={requestDate?.value ? moment(requestDate?.value, "MM/DD/YYYY").format("MMM D, yyyy") : ""}
              // selectedDate={requestDate.value ? new Date(requestDate?.value) : new Date(moment().add(1, "days"))}
              onChange={(event) => handleFields("Request Date", event)}
            />
          </CompactCol>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            <Label> Party (Account ID) </Label>
            <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
              {accountDetails?.erpBpNumber || "--"}
            </UltraMediumText>
          </CompactCol>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 2 }}>
            <Label> Ship To Party </Label>
            <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
              {accountDetails?.resellerBusinessPartnerNumber || "--"}
            </UltraMediumText>
          </CompactCol>
        </SLRowLayout>
        <SLRowLayout css={{ marginTop: "1rem", paddingLeft: "0.6rem !important" }}>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            {source.source === "csp" ? (
              <>
                {contractAccountNumber.options.length <= 1 ? (
                  <>
                    <Label> Contract Account Number </Label>
                    <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
                      {contractAccountNumber.value || "--"}
                    </UltraMediumText>
                  </>
                ) : (
                  <CustomDropdown
                    width="85%"
                    paddingRight="10px"
                    type="text"
                    hideErrorImg={contractAccountNumber.error}
                    label="Contract Account Number"
                    errorText={contractAccountNumber.errorText}
                    error={contractAccountNumber.error}
                    value={contractAccountNumber.value || "Please select"}
                    onChange={(e) => setContractAccountNumber((prevState) => ({ ...prevState, value: e.target.value }))}
                  >
                    <>
                      <option disabled>Please select</option>
                      {contractAccountNumber.options.map((record, index) => (
                        <option key={index}>{record}</option>
                      ))}
                    </>
                  </CustomDropdown>
                )}
              </>
            ) : (
              <>
                <CustomInput
                  width="93.5%"
                  type="text"
                  label="Contract Account Number"
                  aria-label="Contract Account Number"
                  error={contractAccountNumber.error}
                  errorText={contractAccountNumber.errorText}
                  success={contractAccountNumber.success}
                  value={contractAccountNumber.value}
                  onChange={(event) => handleFields("Contract Account Number", event.target.value)}
                />
              </>
            )}
          </CompactCol>
          <CompactCol colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            <CustomInput
              width="93.5%"
              type="text"
              label="Vendor ID"
              aria-label="Vendor ID"
              error={vendorId.error}
              errorText={vendorId.errorText}
              success={vendorId.success}
              value={vendorId.value}
              onChange={(event) => handleFields("Vendor ID", event.target.value)}
            />
          </CompactCol>
          <CompactCol colSizes={{ mobile: 6, tablet: 12, desktop: 3 }}>
            <CustomInput
              width="93.5%"
              type="text"
              maxLength={40}
              label="Original Purchase Order"
              aria-label="Original Purchase Order"
              error={originalPurchaseOrder.error}
              errorText={originalPurchaseOrder.errorText}
              success={originalPurchaseOrder.success}
              value={originalPurchaseOrder.value}
              onChange={(event) => handleFields("Original Purchase Order", event.target.value?.trim())}
            />
          </CompactCol>
          <CompactCol tabIndex={0} colSizes={{ mobile: 6, tablet: 12, desktop: 2 }}>
            <Label> ERP Primary Contact </Label>
            <UltraMediumText fontSize="12px" fontWeight="normal" marginTop="0.75rem">
              {accountDetails?.erpPrimaryContact || "--"}
            </UltraMediumText>
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
      <FlexRow tabIndex={0} css={{ marginTop: "1rem" }}>
        <SmallText bold>{"Ship To Party Address"}</SmallText>
      </FlexRow>
      <SLGridLayout>
        <SLRowLayout css={{ marginTop: "0.5rem", paddingLeft: "0.6rem !important" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomInput
              type="text"
              label="Address 1"
              aria-label="Address 1"
              error={address.address1.error}
              errorText={address.address1.errorText}
              success={address.address1.success}
              value={address.address1.value}
              onChange={(event) => handleAddressFields("Address 1", event.target.value)}
              onBlur={(e) => handleAddressFields("Address 1", e.target.value?.trim(), false, false, false, false, true)}
            />
          </CompactCol>
        </SLRowLayout>
        <SLRowLayout css={{ paddingLeft: "0.6rem !important" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomInput
              type="text"
              label="Address 2 (Optional)"
              aria-label="Address 2 (Optional)"
              error={address.address2.error}
              errorText={address.address2.errorText}
              success={address.address2.success}
              value={address.address2.value}
              onChange={(event) => handleAddressFields("Address 2", event.target.value)}
              onBlur={(e) => handleAddressFields("Address 2", e.target.value?.trim(), false, false, false, false, true)}
            />
          </CompactCol>
        </SLRowLayout>
        <SLRowLayout css={{ paddingLeft: "0.6rem !important" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }} css={StyledCol}>
            <SLGridLayout>
              <SLRowLayout css={{ paddingLeft: "0.6rem !important" }}>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomInput
                    type="text"
                    label="City"
                    aria-label="City"
                    paddingRight="5px"
                    error={address.city.error}
                    errorText={address.city.errorText}
                    success={address.city.success}
                    value={address.city.value}
                    onChange={(event) => handleAddressFields("City", event.target.value)}
                    onBlur={(e) => handleAddressFields("City", e.target.value?.trim(), false, false, false, false, true)}
                  />
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomDropdown
                    // width="99%"
                    type="text"
                    label="Country"
                    aria-label="Select Country"
                    paddingRight="5px"
                    error={address.country.error}
                    errorText={address.country.errorText}
                    success={address.country.success}
                    value={address.country.value?.code || "Please Select"}
                    hideErrorImg={address.country.error}
                    onChange={(e) => handleAddressFields("Country", e.target.value, false, false)}
                  >
                    <>
                      <option disabled>Please Select</option>
                      {address.country.options.map((country) => (
                        <option key={country.code}>{country.code}</option>
                      ))}
                    </>
                  </CustomDropdown>
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomDropdown
                    type="text"
                    label="State"
                    aria-label="Select State"
                    paddingRight="5px"
                    disabled={!address.country.value}
                    hideErrorImg={address.state.error}
                    error={address.state.error}
                    errorText={address.state.errorText}
                    value={address.state.value?.stateName || "Please Select"}
                    onChange={(event) => handleAddressFields("State", event.target.value.split(" - ")[0], address.state.options)}
                  >
                    <>
                      <option disabled style={{ backgroundColor: "lightgrey" }}>
                        Please Select
                      </option>
                      {address.state.options.map((stateCode) => (
                        <option key={stateCode.stateCode + stateCode.countryCode}>{stateCode.stateName}</option>
                      ))}
                    </>
                  </CustomDropdown>
                </CompactCol>
                <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                  <CustomInput
                    type="text"
                    label="Zip/Postal Code"
                    aria-label="Zip"
                    error={address.zip.error}
                    errorText={address.zip.errorText}
                    success={address.zip.success}
                    value={address.zip.value}
                    onChange={(event) => handleAddressFields("Zip", event.target.value, zipRegex)}
                    disabled={!address.country.value}
                    maxLength={zipCodeLimit}
                    onBlur={(e) => handleAddressFields("Zip", e.target.value?.trim(), zipRegex, false, false, false, true)}
                  />
                </CompactCol>
              </SLRowLayout>
            </SLGridLayout>
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
      {showLatLong && (
        <SLGridLayout>
          <SLRowLayout css={{ paddingLeft: "0.6rem !important" }}>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }} css={StyledCol}>
              <SLGridLayout>
                <SLRowLayout css={{ paddingLeft: "0.6rem !important" }}>
                  <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                    <CustomInput
                      width="99%"
                      type="text"
                      label="Latitude (Optional)"
                      aria-label="Latitude (Optional)"
                      paddingRight="5px"
                      disabled={!address.verified}
                      error={latitude.error}
                      errorText={latitude.errorText}
                      success={latitude.success}
                      value={latitude.value}
                      onChange={(event) => handleAddressFields("Latitude", event.target.value)}
                    />
                  </CompactCol>
                  <CompactCol colSizes={{ mobile: 4, tablet: 12, desktop: 3 }}>
                    <CustomInput
                      width="99%"
                      marginBottom="0.6rem"
                      paddingRight="5px"
                      type="text"
                      label="Longitude (Optional)"
                      aria-label="Longitude (Optional)"
                      error={longitude.error}
                      errorText={longitude.errorText}
                      success={longitude.success}
                      value={longitude.value}
                      disabled={!address.verified}
                      onChange={(e) => handleAddressFields("Longitude", e.target.value)}
                    />
                  </CompactCol>
                </SLRowLayout>
              </SLGridLayout>
            </CompactCol>
          </SLRowLayout>
        </SLGridLayout>
      )}
      {!address.verified && !suggestionsIsShown && (
        <FlexRow>
          <CustomSLButton
            aria-label="Verify address"
            role="button"
            disabled={verifyAddressIsDisable}
            aria-disabled={verifyAddressIsDisable}
            onClick={() => handleAddressVerification(address)}
          >
            Verify address
          </CustomSLButton>
          {isAddressvalidationLoader && (
            <FlexColumn css={CustomHistoricalLoader}>
              <Loader active fullscreen={false} />
              <SmallText bold css={{ paddingLeft: "2rem" }}>
                Verifying address...
              </SmallText>
            </FlexColumn>
          )}
        </FlexRow>
      )}
      {suggestionsIsShown && (
        <SLGridLayout>
          <SLRowLayout css={{ paddingLeft: "0.6rem !important" }}>
            <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }} css={StyledCol}>
              <UltraMediumText tabIndex={0} eDSFont fontSize="18px" marginBottom="0" marginTop="1rem">
                Choose an option
              </UltraMediumText>
              <SmallText tabIndex={0}>We couldn&apos;t verify the entered address. We found a close match</SmallText>
              <RadioButtonGroupWrapper>
                <RadioButtonGroup
                  onChange={() => handleAddressSelection(true)}
                  error={false}
                  data={[
                    {
                      name: "group",
                      label: (
                        <>
                          <span>Use suggested address</span>
                          <mark css={{ fontSize: "10px", backgroundColor: "#d8dada", padding: "0rem 0.313rem", marginLeft: "0.313rem" }}>
                            Verified
                          </mark>
                        </>
                      ),
                      children: suggestedAddress.address,
                      value: "radioOne",
                      ariaLabel: "Use suggested address",
                    },
                  ]}
                />
                <RadioButtonGroup
                  onChange={() => handleAddressSelection()}
                  error={false}
                  data={[
                    {
                      name: "group",
                      label: (
                        <>
                          <span>Use address as typed</span>
                        </>
                      ),
                      children: typedAddress,
                      value: "radioTwo",
                      ariaLabel: "Use address as typed",
                    },
                  ]}
                />
              </RadioButtonGroupWrapper>
            </CompactCol>
          </SLRowLayout>
        </SLGridLayout>
      )}
      <FlexRow tabIndex={0} css={{ marginTop: "1rem" }}>
        <SmallText bold>Contact Details</SmallText>
      </FlexRow>
      <SLGridLayout>
        <SLRowLayout css={{ marginTop: "0.5rem", paddingLeft: "0.6rem !important" }}>
          <CompactCol colSizes={{ mobile: 2, tablet: 6, desktop: 6 }}>
            <CustomInput
              width="85%"
              type="text"
              label="Contact Name"
              aria-label="Contact Name"
              error={name.error}
              errorText={name.errorText}
              success={name.success}
              value={name.value}
              maxLength={50}
              onChange={(event) => handleFields("Name", event.target.value)}
            />
            <CustomInput
              width="85%"
              type="text"
              label="Phone"
              aria-label="Phone"
              error={phone.error}
              errorText={phone.errorText}
              success={phone.success}
              value={phone.value}
              onChange={(event) => handleFields("Phone", event.target.value)}
            />
            <CustomInput
              width="85%"
              type="text"
              label="Email"
              aria-label="Email"
              error={email.error}
              errorText={email.errorText}
              success={email.success}
              value={email.value}
              onChange={(event) => handleFields("Email", event.target.value)}
            />
          </CompactCol>
        </SLRowLayout>
      </SLGridLayout>
      <FlexRow tabIndex={0} css={{ marginTop: "2rem" }}>
        <MediumText bold>Fees</MediumText>
      </FlexRow>
      <FlexRow css={{ marginTop: "0.5rem" }}>
        <Select
          options={descriptionList}
          onChange={addMoreFees}
          isSearchable={true}
          isDisabled={!descriptionList.length}
          css={CustomSelectCSS}
          styles={customStyle}
          value={selectedFeeOption}
        />
      </FlexRow>
      <div
        css={{ height: feesDetails?.length > 4 && "35vh", overflow: "auto", marginTop: "1rem", display: "grid", gridTemplateColumns: "max-content" }}
      >
        {feesDetails &&
          feesDetails?.length > 0 &&
          feesDetails?.map((fees, index) => {
            return (
              <FlexRow key={fees.uniqueKey} style={{ justifyContent: "space-between", marginTop: "0.5rem" }}>
                <CustomInputDynamicWidth
                  width="30%"
                  type="text"
                  label="Service Name"
                  aria-label="Service Name"
                  key={"serviceName" + index}
                  disabled
                  value={fees.serviceName.value}
                  error={fees.serviceName.error}
                  errorText={fees.serviceName.errorText}
                  success={fees.serviceName.success}
                />
                <CustomInputDynamicWidth
                  width="15%"
                  type="number"
                  label="MMID"
                  aria-label="MMID"
                  name="mmid"
                  disabled
                  key={"mmid" + index}
                  value={fees.mmId.value}
                  error={fees.mmId.error}
                  errorText={fees.mmId.errorText}
                  success={fees.mmId.success}
                />
                <CustomInputDynamicWidth
                  width="10%"
                  type="number"
                  label="Quantity"
                  aria-label="Quantity"
                  key={"quantity" + index}
                  value={fees.quantity.value}
                  error={fees.quantity.error}
                  errorText={fees.quantity.errorText}
                  success={fees.quantity.success}
                  onChange={(e) => handleFeesFields("quantity", e.target.value, fees, index)}
                />
                <CustomDropdown
                  type="text"
                  width="22%"
                  label="Processing Code"
                  aria-label="Processing Code"
                  key={"processingCode" + index}
                  value={fees.processingCode.value || "Please select"}
                  error={fees.processingCode.error}
                  errorText={fees.processingCode.errorText}
                  success={fees.processingCode.success}
                  onChange={(e) => handleFeesFields("processingCode", e.target.value, fees, index)}
                >
                  <>
                    <option disabled>Please select</option>
                    {processingCodes.map((type) => (
                      <option key={type.code}>
                        {type.code} - {type.description}
                      </option>
                    ))}
                  </>
                </CustomDropdown>
                {fees?.processingCode?.value === "ZBTP - Customer Fee" ? (
                  <CustomInputDynamicWidth
                    width="15%"
                    type="text"
                    className="ChargeOne"
                    label="Charge"
                    aria-label="Charge"
                    key={"charge" + index}
                    error={fees.charge.error}
                    errorText={fees.charge.errorText}
                    success={fees.charge.success}
                    value={fees.charge.showFormattedInput ? fees.charge.formattedValue : fees.charge.value}
                    onMouseOver={() => fees.charge.showFormattedInput && handleChargeInputsToggle(fees, index, false)}
                    onFocus={() => fees.charge.showFormattedInput && chargeFocusHandler(fees, index, true)}
                    onMouseOut={() => !fees.charge.showFormattedInput && !fees.charge.focused && handleChargeInputsToggle(fees, index, true)}
                    onBlur={() => !fees.charge.showFormattedInput && chargeFocusHandler(fees, index, false)}
                    onChange={(e) => handleFeesFields("charge", e.target.value, fees, index)}
                  />
                ) : (
                  <CustomInputDynamicWidth
                    width="15%"
                    type="text"
                    label={"Unit of Measure"}
                    aria-label={"Unit of Measure"}
                    key={"unitOfMeasure" + index}
                    value={fees?.unitOfMeasure?.value}
                    error={fees?.unitOfMeasure?.error}
                    errorText={fees?.unitOfMeasure?.errorText}
                    success={fees?.unitOfMeasure?.success}
                    disabled
                  />
                )}
                <FlexColumn
                  tabIndex={0}
                  onClick={() => deleteFeesHandler(index)}
                  css={{ justifyContent: "space-evenly", marginTop: "0.75rem", width: "5%" }}
                >
                  <Icon className="trash-icon" name="trash" size="medium" color="#000000" style={{ cursor: "pointer", outline: "none" }} />
                </FlexColumn>
              </FlexRow>
            );
          })}
      </div>
      <FlexRow style={{ marginTop: "1rem" }}>
        <TextAreaWrapper>
          <TextArea
            label="Vendor Notes"
            width="49%"
            required={false}
            maxLength={500}
            error={vendorNotes.error}
            errorText={vendorNotes.errorText}
            value={vendorNotes.value}
            onChange={(event) => handleFields("Vendor Notes", event.target.value)}
            placeholder="Type here"
            errorEvent="blurAndChange"
            css={{ fontSize: "12px", fontFamily: "Verizon-NHG-eTX", letterSpacing: "0" }}
          ></TextArea>
        </TextAreaWrapper>
      </FlexRow>
      <FlexRow style={{ marginTop: "1rem" }}>
        <CustomSLButton
          css={{ padding: "0 10px 0 10px" }}
          primary
          aria-label="Save"
          role="button"
          disabled={isSaveButtonDisabled}
          aria-disabled={isSaveButtonDisabled}
          onClick={handleSave}
        >
          Save
        </CustomSLButton>
        <CustomSLButton aria-label="Cancel" role="button" secondary onClick={navigate}>
          Cancel
        </CustomSLButton>
      </FlexRow>
    </>
  );
}

export const CreateAdditionalFeesMetadata = {
  name: "Create Add Fees",
  id: "create_add_fees",
  component: lazy(() => import("./CreateAdditionalFees")),
  route: "/create_add_fees",
};
