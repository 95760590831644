import styled from "@emotion/styled";
import { Button } from "@vds/buttons";
import { Col, Grid, Row } from "@vds/grids";
import { Body, Title } from "@vds/typography";
import { Line } from "@vds/lines";
import { Notification } from "@vds/notifications";

export const SLNotification = styled(Notification)`
  div > div {
    max-width: 100%;
  }
`;

export const Header = (props) => {
  return (
    <>
      <XLargeText css={{ padding: "10px 0px" }} bold {...props} />
      <Line type="light"></Line>
    </>
  );
};

export const SLButton = styled(Button)({
  margin: "10px 20px 10px 0px",
});

const Flex = styled.div((props) => {
  const style = {
    display: "flex",
    ...props,
  };
  delete style.children;
  return style;
});

export const FlexRow = (props) => <Flex flexDirection="row" flexWrap="wrap" {...props} />;

export const FlexColumn = (props) => <Flex flexDirection="column" flexWrap="wrap" {...props} />;

const SpacerHelper = styled.div((props) => {
  let style = {};
  if (props.top) {
    style.paddingTop = props.top;
  }
  if (props.bottom) {
    style.paddingBottom = props.bottom;
  }
  if (props.left) {
    style.paddingLeft = props.left;
  }
  if (props.right) {
    style.paddingRight = props.right;
  }
  if (props.width) {
    style.width = props.width;
  }
  if (props.height) {
    style.height = props.height;
  }
  return style;
});

export const Spacer = (props) => <SpacerHelper {...props} />;

export const CompactCol = (props) => <Col {...props} style={{ padding: 0 }}></Col>;

export const CompactColNew = (props) => <Col {...props} style={{ padding: "0 20px 0 0 " }}></Col>;

export const SLGridLayout = (props) => <Grid {...props} style={{ maxWidth: "100%" }}></Grid>;

export const SLRowLayout = (props) => <Row {...props} style={{ maxWidth: "100%", paddingLeft: "10px" }}></Row>;

const MarginWrapper = styled.div({
  margin: "0.2rem 0px",
});

export const SmallText = (props) => (
  <MarginWrapper {...props}>
    <Body size="small" {...props}></Body>
  </MarginWrapper>
);

export const MediumText = (props) => (
  <MarginWrapper {...props}>
    <Body size="large" {...props}></Body>
  </MarginWrapper>
);

export const XLargeText = (props) => (
  <MarginWrapper {...props}>
    <Title primitive="p" size="large" {...props}></Title>
  </MarginWrapper>
);

export const LargeText = (props) => (
  <MarginWrapper {...props}>
    <Title primitive="p" size="small" {...props}></Title>
  </MarginWrapper>
);
export const UltraMediumText = styled.p`
  font-family: ${(props) => (props.eDSFont ? "Verizon-NHG-eDS" : "Verizon-NHG-eTX")};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "0px")};
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "bold")};
  line-height: ${(props) => props.lineHeight && props.lineHeight};
`;
export const StyledGridWrapper = styled.div`
  width: 100%;
  > div > div {
    width: 100%;
    max-width: 100%;
    margin: 0;
    height: auto;
    background: ${({ theme }) => theme.background};
    > div > div {
      max-width: 100%;
      width: 100%;
      background: ${({ theme }) => theme.background};
    }
  }
`;
export const CustomSLButton = styled(SLButton)`
  & span {
    padding: 0.68rem 2rem;
    @media screen and (min-width: 700px) and (max-width: 720px) {
      padding: 0.68rem 0.8rem;
    }
    @media screen and (min-width: 720px) and (max-width: 750px) {
      padding: 0.68rem 0.895rem;
    }
    @media screen and (min-width: 751px) and (max-width: 850px) {
      padding: 0.68rem 1.2rem;
    }
  }
`;
