import React from "react";
import { css } from "@emotion/react";
import { TextLink } from "@vds/buttons";
import { SmallText } from "../../components/core/Utility/index";
import { SLNotification } from "../../components/core/utility";

const CustomisedNotification = css`
  margin-top: 1rem;
  width: 97%;
  & span:last-child {
    max-width: fit-content;
  }
`;

function JobCalculatorNotification({ scheduleType, totalJobLength }) {
  const learnMoreKBArticle = "https://vzt-kb.vtitel.com/Reveal/Reveal_Support/Reveal_Scheduling/Scheduling_and_Shipping_Scenarios";
  const ScheduleKBArticle = "https://vzt-kb.vtitel.com/Systems_and_Tools/Connect_360/Connect_360_Processes/Using_the_Scheduling_Portal";

  return (
    <>
      {scheduleType === "AUTO" && totalJobLength > 10 ? (
        <SLNotification
          type="warning"
          title={
            <span>
              Your job is currently over the 1 day threshold (10 hours). If you proceed with this length, you will need to manually schedule the
              appointment. If you want to leverage the automated Scheduling Portal, please split the request into multiple days by reducing the job
              length to ≤ 10 hours.
              <br />
              KB{" "}
              <TextLink
                href={ScheduleKBArticle}
                size="small"
                type="inline"
                tabIndex="0"
                role="link"
                ariaLabel="kbArticleLink"
                target="_blank"
                rel="noopener noreferrer"
              >
                Using the Scheduling Portal: Expand a Job
              </TextLink>
              .
            </span>
          }
          hideCloseButton={true}
          css={CustomisedNotification}
        />
      ) : (
        <SmallText style={{ margin: "1rem 1rem 0 0" }}>
          Minimum job duration is 1 hour.{" "}
          <TextLink aria-label={learnMoreKBArticle} href={learnMoreKBArticle} tabIndex={0} role="link" target="_blank">
            Learn more
          </TextLink>
        </SmallText>
      )}
    </>
  );
}

export default JobCalculatorNotification;
