import React, { useContext, useRef, useEffect } from "react";
import { SLButton } from "../../../components/core/Controls";
import { FlexColumn, FlexRow, SmallText, Spacer } from "../../../components/core/Utility/index";
import { SLNotification } from "../../../components/core/utility";
import { CustomisedNotification, StyledModal } from "./../RescheduleModal";
import { generate } from "shortid";
import { AccountDetailsContext } from "../../../context/accountDetailsContext";
import "./../Stepper.css";
import { salesOrgBasedCurrency } from "../../../constants/PartialCompletionReasons";
import { TextLink } from "@vds/buttons";
const ConfirmationComp = ({ formStep, previousStep, closePopup, cancelScheduledJob, source, completeJobs, Addfees, jobDetails }) => {
  const { cspDetails, totalCustomerFees, customerFees, installPartnerFees, accountDetails, source: newSource } = useContext(AccountDetailsContext);
  const onClickSubmit = () => {
    if (source === "Complete") completeJobs();
    else if (source === "Cancel") cancelScheduledJob();
    else if (source === "AddFees") Addfees();
  };

  const kbNoAdditionalServices = "https://vzt-kb.vtitel.com/Customer_Onboarding/Reveal_Scheduling/Using_the_Scheduling_Portal";

  //for accessibility
  const ConfirmationTitleRef = useRef();
  useEffect(() => {
    ConfirmationTitleRef.current.focus();
  }, []);

  return (
    <>
      <Spacer top="4vw" />
      <FlexRow style={{ justifyContent: "space-between", padding: "5px" }}>
        <div ref={ConfirmationTitleRef} tabIndex={0} style={{ fontSize: "2rem", fontWeight: 700, color: "#000" }}>
          Confirmation
        </div>
      </FlexRow>
      <Spacer top="0.5em" />
      {/* {(source === "Complete" || source === "Cancel") && systemUserNotification} */}
      <Spacer top="0.5em" />
      {source === "AddFees" && installPartnerFees.installPartnerFees?.length === 0 && customerFees?.customerFees?.length === 0 && (
        <>
          <SLNotification
            type={"error"}
            title={
              <>
                <span>No new fee added</span>
              </>
            }
            key={generate()}
            hideCloseButton={true}
            css={CustomisedNotification}
          />
          <Spacer top="0.5rem" />
        </>
      )}

      {(accountDetails.vztSegment.toLocaleLowerCase() === "government" || accountDetails.vztSegment.toLocaleLowerCase() === "public sector") && (
        <>
          <SLNotification
            type={"info"}
            title={
              <>
                <span>This is a govenment account. Please validate charges with the contract before submitting. </span>
              </>
            }
            key={generate()}
            hideCloseButton={true}
            css={CustomisedNotification}
          />
          <Spacer top="2rem" />
        </>
      )}

      <hr></hr>
      <FlexRow style={{ justifyContent: "space-between", padding: "5px" }}>
        <span tabIndex={0} style={{ fontSize: "1.3rem", fontWeight: 700, color: "#000" }}>
          Total Customer Charge
        </span>
        {newSource.source === "csp" ? (
          <span tabIndex={0} style={{ fontSize: "1.5rem", fontWeight: 700, color: "#000" }}>
            {salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currencySymbol}
            {totalCustomerFees.totalCustomerFees} ({salesOrgBasedCurrency["SO_" + cspDetails?.cspDetails?.[0]?.Opp_Sales_Org]?.currency})
          </span>
        ) : (
          <span tabIndex={0} style={{ fontSize: "1.5rem", fontWeight: 700, color: "#000" }}>
            {salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currencySymbol}
            {totalCustomerFees.totalCustomerFees} ({salesOrgBasedCurrency["SO_" + jobDetails?.salesOrg]?.currency})
          </span>
        )}
      </FlexRow>
      <FlexRow style={{ justifyContent: "flex-start", paddingTop: "1rem" }}>
        {formStep > 0 && (
          <SLButton aria-label="Previous" role="button" size="large" style={{ width: "10rem" }} onClick={previousStep}>
            Previous
          </SLButton>
        )}
        <SLButton
          aria-label="Submit"
          role="button"
          size="large"
          disabled={source === "AddFees" && installPartnerFees.installPartnerFees?.length === 0 && customerFees?.customerFees?.length === 0}
          style={{ width: "10rem" }}
          onClick={() => onClickSubmit()}
        >
          Submit
        </SLButton>
        <SLButton aria-label="Cancel" role="button" size="large" secondary style={{ width: "10rem" }} onClick={closePopup}>
          Cancel
        </SLButton>
      </FlexRow>
    </>
  );
};
export default ConfirmationComp;
