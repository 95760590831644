import { getVTUDetailsByEsnForCamera } from "../../../services/Camera";
import { ESN_REGEX } from "../../../constants/ValidationConstants";
import { Body } from "@vds/typography/dist/cjs/components/Body";
import { Spacer, SmallText, SLGridLayout, SLRowLayout } from "../../../components/core/Utility/index";
import { Grid, Row, Col } from "@vds/grids";
import styled from "@emotion/styled";
import { SLInput } from "../../../components/core/Controls";
import { Loader } from "@vds/loaders";
import { Button } from "@vds/buttons";
import { AssignCameraContext } from "../../../context/cameraContext";
import { Line } from "@vds/lines";

export const DetailsPanel = ({ vtuDetails, controlUnitData }) => {
  return (
    <>
      <Spacer height={"34px"} />
      {vtuDetails && Object.keys(vtuDetails).length > 0 && (
        <>
          <SLGridLayout
            colSizes={{
              mobile: 12,
              tablet: 4,
              desktop: 2,
            }}
          >
            <SLRowLayout>
              <Spacer bottom="5px" left="20px">
                <Body size="large" color="#000000" bold={true}>
                  VTU ESN Details:
                </Body>
              </Spacer>
            </SLRowLayout>
            <SLRowLayout css={{ marginTop: "1.00rem" }}>
              <Col>
                <SmallText>ESN: </SmallText>
                <SmallText bold>{vtuDetails?.esn}</SmallText>
              </Col>
              <Col>
                <SmallText>Sales Order Number:</SmallText>
                <SmallText bold>{vtuDetails?.salesOrderNumber ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Account:</SmallText>
                <SmallText bold>{vtuDetails?.accountUid ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Account Name: </SmallText>
                <SmallText bold>{vtuDetails?.accountName ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Work Order: </SmallText>
                <SmallText bold>{vtuDetails?.workOrderNumber ?? "--"}</SmallText>
              </Col>
            </SLRowLayout>
            <SLRowLayout css={{ marginTop: "1.25rem" }}>
              <Col>
                <SmallText>Vehicle number: </SmallText>
                <SmallText bold>{vtuDetails?.vehicleSerialNumber ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Vehicle Name: </SmallText>
                <SmallText bold>{vtuDetails?.vehicleLabel ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Make: </SmallText>
                <SmallText bold> {vtuDetails?.vehicleMake ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Model: </SmallText>
                <SmallText bold>{vtuDetails?.vehicleModel ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Year: </SmallText>
                <SmallText bold>{vtuDetails?.vehicleYear ?? "--"}</SmallText>
              </Col>
            </SLRowLayout>
          </SLGridLayout>
        </>
      )}

      <Spacer height={"34px"} />
      {controlUnitData && Object.keys(controlUnitData).length > 0 && (
        <>
          <SLGridLayout
            colSizes={{
              mobile: 12,
              tablet: 4,
              desktop: 2,
            }}
          >
            <SLRowLayout>
              <Spacer bottom="5px" left="20px">
                <Body size="large" color="#000000" bold={true}>
                  Control Unit Details :
                </Body>
              </Spacer>
            </SLRowLayout>
            <SLRowLayout css={{ marginTop: "1.00rem" }}>
              <Col>
                <SmallText>ESN: </SmallText>
                <SmallText bold>{controlUnitData?.esn}</SmallText>
              </Col>
              <Col>
                <SmallText>State </SmallText>
                <SmallText bold>{controlUnitData?.state ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Account:</SmallText>
                <SmallText bold>{controlUnitData?.universalAccountId ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Account Name: </SmallText>
                <SmallText bold>{controlUnitData?.accountName ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Status</SmallText>
                <SmallText bold style={{ color: "green" }}>
                  {controlUnitData?.status ?? "--"}
                </SmallText>
              </Col>
            </SLRowLayout>
            <SLRowLayout css={{ marginTop: "1.25rem" }}>
              <Col>
                <SmallText>Product ID : </SmallText>
                <SmallText bold>{controlUnitData?.sku ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Box Name: </SmallText>
                <SmallText bold>{controlUnitData?.boxName ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Location : </SmallText>
                <SmallText bold> {controlUnitData?.location ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Box Model : </SmallText>
                <SmallText bold>{controlUnitData?.boxModel ?? "--"}</SmallText>
              </Col>
              <Col>
                <SmallText>Is Confirmed : </SmallText>
                <SmallText bold>{controlUnitData?.isConfirmed ? "Confirmed" : "Not Confirmed" ?? "--"}</SmallText>
              </Col>
            </SLRowLayout>
          </SLGridLayout>
        </>
      )}
    </>
  );
};
