import React, { useState, useEffect, lazy } from "react";
import { CheckStatusService } from "../../services/Aggregator/CheckStatus";
import styled from "@emotion/styled";
import { SLNotification } from "../../components/core/Utility";
import { GridComponent } from "../../components/core/Table/GridComponent";
import { CHECK_STATUS_COL } from "../../constants/UpdateActiveUnitsConstants";
import { Header } from "../../components/core/Controls";
import { Loader } from "@vds/loaders";
const ContentWrapper = styled.div`
  padding: 10px 10px;
`;
export default function FirstPage() {
  const [data, setData] = useState([]);
  const [errValue, setErrValue] = useState("");
  const [IsLoading, setLoading] = useState(false);
  const CheckStatusVtuAndCamera = async () => {
    setLoading(true);
    return CheckStatusService()
      .then((response) => {
        if (response?.rows === null || response?.rows === undefined) {
          setLoading(false);
          setErrValue("An Error Occurred, Please Try Again Later");
        } else {
          const dt = response.rows?.cameraData.concat(response.rows?.vtuData);
          setLoading(false);
          setData(dt);
        }
      })
      .catch(() => {
        setLoading(false);
        setErrValue("Couldn't load the data");
      });
  };

  const formattedRows = (data) => {
    return [...(data || [])].map((d) => {
      d.activationRequestedOn = new Date(d.activationRequestedOn);
      return d;
    });
  };

  useEffect(() => {
    CheckStatusVtuAndCamera();
  }, []);
  return (
    <>
      <Header>Update Status - Activation Status</Header>
      <ContentWrapper>
        <Loader active={IsLoading} />
        {errValue != "" ? (
          <SLNotification
            type="error"
            title
            onCloseButtonClick={() => setErrValue("")}
            subtitle={errValue}
            fullBleed={false}
            inlineTreatment={false}
          />
        ) : (
          <></>
        )}
        <GridComponent isExportEnabled={true} rows={formattedRows(data)} columns={CHECK_STATUS_COL} title="List of Cameras and VTUs" />
      </ContentWrapper>
    </>
  );
}

export const CheckStatusMetadata = {
  name: "Check Status Page",
  id: "checkstatuspage",
  component: lazy(() => import("../CheckStatusPage/index")),
  route: "/checkstatus",
};
