import { useState } from "react";
import { postRequestFile } from "../RestServices";
import * as urls from "../../api/aggregator";
export const upLoadDataFile = async (payload, headers, location) => {
  try {
    const _response = await postRequestFile(urls.UPLOADDATA + `/${location}`, payload, headers);
    return _response.data.data;
  } catch (err) {
    if (err.message.includes("409")) {
      return err.response.data;
    } else {
      return 500;
    }
  }
};

export const useToUploadUnits = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const headerConfig = {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
  const uploadUnits = async (payload) => {
    try {
      setData();
      setError("");
      setLoading(true);
      const _response = await postRequestFile(urls.UPLOADDATA, payload, headerConfig);
      setData(_response?.data ?? []);
    } catch (error) {
      setError(error?.response?.data?.message || "Unable to upload the file");
      setData(error?.response?.data ?? []);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    isLoading,
    uploadUnits,
  };
};

export const upLoadUnitsFile = async (payload, headers) => {
  try {
    const _response = await postRequestFile(urls.UPLOADDATA, payload, headers);
    return _response.data.data;
  } catch (err) {
    if (err.message.includes("409")) {
      return err.response.data;
    } else {
      return 500;
    }
  }
};
