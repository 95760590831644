import { Modal, ModalBody } from "@vds/modals";
import React, { useContext, useEffect, useState } from "react";
import { Title } from "@vds/typography";
import { css } from "@emotion/react";
import { CustomSLButton, FlexColumn, FlexRow, Spacer, SmallText, SLNotification } from "../../components/core/utility";
import Icon from "@vds/icons";
import { CustomDropdown, CustomInput } from "../C4S Decommission - Replacement/AddAVehicle";
import _ from "lodash";

import { Loader } from "@vds/loaders";
import styled from "styled-components";
import { MultiSelect } from "primereact/multiselect";
import { dropdownValuesList, VALIDATION_REGEX } from "./AddInstallerModal";
import { countryList } from "./AdminValidateAddress";
import { addUpdateVendor } from "../../services/scheduler/addUpdateVendor";
import { generate } from "shortid";
import { EMAIL_REGEX_C360 } from "../../constants/C4SValidationConstants";
import useOutsideClick from "../../customHook/use-outside-click";

export const CustomisedInput = styled(MultiSelect)`
  border: 0.0625rem solid #d8dada !important;
  border-bottom: 0.0625rem solid #000000 !important;
  width: 90%;
  height: 2.3rem;
  box-shadow: none !important;
  border-radius: 0;
  .p-inputtext,
  .p-button,
  .p-button:enabled:hover,
  .p-button:enabled:active,
  .p-dropdown:focus,
  .p-dropdown:active {
    font-size: 8px;
    font-family: Verizon-NHG-eTX;
    height: auto;
    margin: auto;
    position: relative;
    border: none;
    color: #000000 !important;
    background-color: transparent;
  }
  .p-multiselect-label {
    font-size: 0.75rem;
    white-space: wrap;
    padding: ${(props) => (props.placeholderPadding ? props.placeholderPadding : "0.5rem 0.5rem")};
  }
`;

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

export const CustomNotification = styled(SLNotification)`
  & div {
    justify-content: flex-start;
    max-width: 100%;
    & span:first-child {
      flex: 20 1 0%;
    }
  }
`;

export const AddVendorModal = ({ isVendorModalOpened, setIsVendorModalOpened, getVendors, editVendorRecord, setEditVendorRecord, vendorIdList }) => {
  const [vendorId, setVendorId] = useState({ value: "", error: false, errorText: "Enter valid Vendor ID", success: false });
  const [vendorName, setVendorName] = useState({ value: "", error: false, errorText: "Enter valid Vendor Name", success: false });
  const [salesOrg, setSalesOrg] = useState({ value: "", error: false, errorText: "Select Sales Org", success: false });
  const [active, setActive] = useState({ value: "", error: false, errorText: "Select Active", success: false });
  const [contactName, setContactName] = useState({ value: "", error: false, errorText: "Enter valid Contact Name", success: false });
  const [contactPhone, setContactPhone] = useState({ value: "", error: false, errorText: "Enter valid Contact Phone", success: false });
  const [email, setEmail] = useState({ value: "", error: false, errorText: "Enter valid Email", success: false });
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isLoading, setLoader] = useState(false);
  const [address, setAddress] = useState({
    verified: false,
    address1: { value: "", error: false, errorText: "Enter Street Address", success: false },
    address2: { value: "", error: false, errorText: "Enter Address 2", success: false },
    city: { value: "", error: false, errorText: "Enter City", success: false },
    state: { value: "", error: false, errorText: "Select State", success: false },
    zip: { value: "", error: false, errorText: "Enter Zip Code", success: false },
    country: { value: "", error: false, errorText: "Select Country", success: false },
  });
  const salesOrgList = [
    {
      code: "1101",
      description: "VZ Connect UK Ltd",
    },
    {
      code: "4211",
      description: "VZC Ireland Limited",
    },
    {
      code: "1201",
      description: "VZC France SAS",
    },
    {
      code: "3701",
      description: "VZC Netherlands",
    },
    {
      code: "1001",
      description: "VZ Connect Germ GmbH",
    },
    {
      code: "2901",
      description: "VZ Connect CanadaInc",
    },
    {
      code: "9001",
      description: "VZC Fleet USA LLC",
    },
    {
      code: "5501",
      description: "Mexico",
    },
    {
      code: "3001",
      description: "Verizon Connect Aust",
    },
    {
      code: "6901",
      description: "VZ Connect NZ",
    },
  ];
  const cspAccDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
  const [notification, setNotification] = useState(null);
  const updateVendor = editVendorRecord && Object.keys(editVendorRecord)?.length ? true : false;
  const currentVendorId = updateVendor ? editVendorRecord?.vendorId : "";
  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setIsVendorModalOpened(false);
        setEditVendorRecord(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    if (updateVendor) {
      handleFields("Vendor ID", editVendorRecord?.vendorId);
      handleFields("Active", editVendorRecord?.active);
      handleFields("Vendor Name", editVendorRecord?.vendorName);
      handleFields("Sales Org", editVendorRecord?.salesOrg ? editVendorRecord?.salesOrg.split(",") : []);
      handleFields("Contact Name", editVendorRecord?.contactName);
      handleFields("Contact Phone", editVendorRecord?.contactPhone);
      handleFields("Email", editVendorRecord?.contactEmail);
      handleFields("address 1", editVendorRecord?.address1?.trim());
      handleFields("address 2", editVendorRecord?.address2?.trim());
      handleFields("city", editVendorRecord?.city?.trim());
      handleFields("state", editVendorRecord?.state?.trim());
      handleFields("zip", editVendorRecord?.zip?.trim());
      handleFields("country", editVendorRecord?.country?.trim());
    }
  }, []);

  useEffect(() => {
    vendorId.success &&
    vendorName.success &&
    salesOrg.success &&
    active.success &&
    contactName.success &&
    contactPhone.success &&
    email.success &&
    address.country.success
      ? setIsSaveDisabled(false)
      : setIsSaveDisabled(true);
  }, [
    vendorId.success,
    vendorName.success,
    salesOrg.success,
    active.success,
    contactName.success,
    contactPhone.success,
    email.success,
    address.country.success,
  ]);

  const handleFields = (field, value) => {
    switch (field) {
      case "Vendor ID": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            vendorIdList.has(value) && value !== currentVendorId
              ? setVendorId({ value, error: true, errorText: "Vendor ID already exists", success: false })
              : setVendorId({ value, error: false, errorText: "Enter valid Vendor ID", success: true });
          } else {
            setVendorId({ value, error: true, errorText: "Enter valid Vendor ID", success: false });
          }
        } else {
          setVendorId({ value: "", error: true, errorText: "Enter Vendor ID", success: false });
        }
        break;
      }
      case "Vendor Name": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setVendorName({ value, error: false, errorText: "Enter valid Vendor Name", success: true });
          } else {
            setVendorName({ value, error: true, errorText: "Enter valid Vendor Name", success: false });
          }
        } else {
          setVendorName({ value: "", error: true, errorText: "Enter Vendor Name", success: false });
        }
        break;
      }
      case "Sales Org": {
        if (value && value.length) {
          setSalesOrg({ value, error: false, errorText: "", success: true });
        } else {
          setSalesOrg({ value, error: true, errorText: "Enter valid Sales Org", success: false });
        }
        break;
      }

      case "Active": {
        if (value && value.length) {
          setActive({ value, error: false, errorText: "", success: true });
        } else {
          setActive({ value: "Please select", error: true, errorText: "Select Active", success: false });
        }
        break;
      }
      case "Contact Name": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setContactName({ value, error: false, errorText: "Enter valid Contact Name", success: true });
          } else {
            setContactName({ value, error: true, errorText: "Enter valid Contact Name", success: false });
          }
        } else {
          setContactName({ value: "", error: true, errorText: "Enter Contact Name", success: false });
        }
        break;
      }
      case "Contact Phone": {
        let phoneRegex = /^([^a-zA-Z])+$/;
        if (value && value.length) {
          if (phoneRegex.test(value)) {
            setContactPhone({ value, error: false, errorText: "Enter valid Contact Phone", success: true });
          } else {
            setContactPhone({ value, error: true, errorText: "Enter valid Contact Phone", success: false });
          }
        } else {
          setContactPhone({ value: "", error: true, errorText: "Enter Contact Phone", success: false });
        }
        break;
      }
      case "Email": {
        if (value && value.length) {
          if (EMAIL_REGEX_C360.test(value)) {
            setEmail({ value, error: false, errorText: "Enter Email", success: true });
          } else {
            setEmail({ value, error: true, errorText: "Enter Email", success: false });
          }
        } else {
          setEmail({ value: "", error: true, errorText: "Enter Email", success: false });
        }
        break;
      }
      case "address 1": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: false, errorText: "Enter address 1", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: true, errorText: "Enter valid street address", success: false } }));
          }
        } else {
          setAddress((prevState) => ({
            ...prevState,
            address1: { value: "", error: false, errorText: "Enter valid street address", success: true },
          }));
        }
        break;
      }
      case "address 2": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: false, errorText: "Enter address 2", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: true, errorText: "Enter a valid address 2", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address2: { value: "", error: false, errorText: "Enter address 2", success: true } }));
        }
        break;
      }
      case "city": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, city: { value, error: false, errorText: "Enter city", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, city: { value, error: true, errorText: "Enter a valid city", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, city: { value: "", error: false, errorText: "Enter city", success: true } }));
        }
        break;
      }
      case "state": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, state: { value, error: false, errorText: "Enter state", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, state: { value, error: true, errorText: "Enter a valid state", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, state: { value: "", error: false, errorText: "Enter valid state", success: true } }));
        }
        break;
      }
      case "zip": {
        if (value && value.length) {
          if (VALIDATION_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: false, errorText: "Enter zip", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: true, errorText: "Enter a valid zip", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, zip: { value: "", error: false, errorText: "Enter zip", success: true } }));
        }
        break;
      }
      case "country": {
        if (value && value.length && countryList.includes(value?.toUpperCase())) {
          setAddress((prevState) => ({ ...prevState, country: { value, error: false, errorText: "Enter country", success: true } }));
        } else {
          setAddress((prevState) => ({ ...prevState, country: { value: "Please select", error: true, errorText: "Enter country", success: false } }));
        }
        break;
      }
    }
  };

  const notify = (content, type, isNotificationWithTimer = true) => {
    setNotification(
      <>
        <CustomNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />
      </>
    );
    if (isNotificationWithTimer) {
      setTimeout(() => {
        setNotification(null);
      }, 5000);
    }
  };

  const onShow = () => {
    let selectAllCheckbox = document.querySelector(".p-multiselect-header > .p-checkbox");
    selectAllCheckbox?.after(" Select All");
  };

  const handleVendorSave = async () => {
    let data = {
      id: updateVendor ? editVendorRecord.id : null,
      vendorId: vendorId.value,
      vendorName: vendorName.value,
      contactName: contactName.value,
      contactPhone: contactPhone.value,
      contactEmail: email.value,
      isActive: active.value === "Yes" ? true : false,
      salesOrgIds: salesOrg.value?.map((ids) => ids.split(" ")[0]),
      vendorAddress1: address.address1.value,
      vendorAddress2: address.address2.value,
      vendorCity: address.city.value,
      vendorState: address.state.value,
      vendorCountry: address.country.value ?? "",
      vendorZipcode: address.zip.value,
      createdBy: Object.keys(cspAccDetails)?.length ? `${cspAccDetails?.firstName} ${cspAccDetails?.lastName}` : "",
    };
    if (data.vendorCountry === "") {
      setIsVendorModalOpened(false);
      return notify(
        "Unable to save vendor details due to some missing mandatory information (Mandatory: Country). Kindly contact IT team for support.",
        "error",
        false
      );
    }
    setLoader(true);
    let response = await addUpdateVendor(data);
    if (response?.status === 200 && response?.data?.message?.toLowerCase() === "success") {
      notify("Vendor saved successfully", "success", false);
      setTimeout(() => {
        setNotification(null);
        setIsVendorModalOpened(false);
        setEditVendorRecord(null);
        setLoader(false);
        getVendors();
      }, 3000);
    } else if (response?.data?.statusCode === "404") {
      notify(response?.data?.message, "error", false);
      setLoader(false);
    } else {
      notify("We are currently experiencing issues, please try again", "error", false);
      setLoader(false);
    }
  };

  return (
    <>
      <Spacer top="10px" />
      <Modal
        role="dialog"
        ariaLabel="Add Vendor" //{action}
        hideCloseButton={false}
        opened={isVendorModalOpened}
        disableOutsideClick
        css={StyledModal}
        closeButton={
          <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
            <div css={{ paddingLeft: "1.5rem" }}>
              <Title size="medium">{updateVendor ? `Edit Vendor` : `Add Vendor`}</Title>
            </div>
            <div
              onClick={() => {
                setIsVendorModalOpened(false), setEditVendorRecord(null);
              }}
              style={{ marginRight: "1rem", marginTop: "0.3rem" }}
            >
              <Icon
                aria-label="close"
                alt="close-icon"
                role="img"
                name="close"
                size="medium"
                color="#000000"
                style={{ cursor: "pointer", outline: "none" }}
              />
            </div>
          </FlexRow>
        }
      >
        <ModalBody css={{ padding: "16px 0 16px 16px !important" }}>
          {notification}
          <Spacer top="1rem" />
          <FlexRow>
            <Loader active={isLoading} />
            <CustomInput
              formWidth="50%"
              type="text"
              label="Vendor ID"
              aria-label="Vendor ID"
              value={vendorId.value}
              error={vendorId.error}
              errorText={vendorId.errorText}
              success={vendorId.success}
              onChange={(e) => handleFields("Vendor ID", e.target.value)}
              paddingRight="5px"
              css={{ paddingBottom: "0.6rem" }}
            />
            <CustomDropdown
              width="50%"
              type="text"
              label="Active"
              aria-label="Active"
              value={active.value ? active.value : "Please select"}
              error={active.error}
              errorText={active.errorText}
              success={active.success}
              onChange={(e) => handleFields("Active", e.target.value)}
              paddingRight="5px"
              css={{ paddingBottom: "0.6rem" }}
            >
              <option disabled>Please select</option>
              {dropdownValuesList?.booleanDropdown.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </CustomDropdown>
            <CustomInput
              formWidth="100%"
              type="text"
              label="Vendor Name"
              aria-label="Vendor Name"
              value={vendorName.value}
              error={vendorName.error}
              errorText={vendorName.errorText}
              success={vendorName.success}
              onChange={(e) => handleFields("Vendor Name", e.target.value)}
              paddingRight="5px"
              css={{ paddingBottom: "0.6rem" }}
            />
            <div css={{ paddingBottom: "0.6rem", width: "100%", paddingRight: "5px" }}>
              <SmallText css={{ marginTop: "0px" }}>Sales Org</SmallText>
              <CustomisedInput
                label="Sales Org"
                aria-label="Sales Org"
                value={salesOrg.value}
                error={salesOrg.error}
                errorText={salesOrg.errorText}
                success={salesOrg.success}
                css={{ width: "100%", minHeight: "2.8rem" }}
                placeholderPadding="0.8rem 0.5rem"
                display="chip"
                placeholder="Please select"
                onShow={onShow}
                options={salesOrgList?.map((item) => {
                  return { label: `${item.code} - ${item.description}`, value: item.code };
                })}
                onChange={(e) => handleFields("Sales Org", e.value)}
              />
            </div>

            <CustomInput
              formWidth="50%"
              type="text"
              label="Contact Name"
              aria-label="Contact Name"
              value={contactName.value}
              error={contactName.error}
              errorText={contactName.errorText}
              success={contactName.success}
              onChange={(e) => handleFields("Contact Name", e.target.value)}
              paddingRight="5px"
              maxLength={50}
              css={{ paddingBottom: "0.6rem" }}
            />
            <CustomInput
              formWidth="50%"
              type="text"
              label="Contact Phone"
              aria-label="Contact Phone"
              value={contactPhone.value}
              error={contactPhone.error}
              errorText={contactPhone.errorText}
              success={contactPhone.success}
              onChange={(e) => handleFields("Contact Phone", e.target.value)}
              paddingRight="5px"
              css={{ paddingBottom: "0.6rem" }}
            />
            <CustomInput
              formWidth="100%"
              type="text"
              label="Email"
              aria-label="Email"
              value={email.value}
              error={email.error}
              errorText={email.errorText}
              success={email.success}
              onChange={(e) => handleFields("Email", e.target.value)}
              paddingRight="5px"
              maxLength={50}
              css={{ paddingBottom: "0.6rem" }}
            />
            <CustomInput
              formWidth="50%"
              type="text"
              label="Address 1 (Optional)"
              aria-label="Address 1 (Optional)"
              value={address.address1.value}
              error={address.address1.error}
              errorText={address.address1.errorText}
              success={address.address1.success}
              onChange={(e) => handleFields("address 1", e.target.value)}
              paddingRight="5px"
              maxLength={50}
              css={{ paddingBottom: "0.6rem" }}
              onBlur={(e) => handleFields("address 1", e.target.value?.trim())}
            />
            <CustomInput
              formWidth="50%"
              type="text"
              label="Address 2 (Optional)"
              aria-label="Address 2 (Optional)"
              value={address.address2.value}
              error={address.address2.error}
              errorText={address.address2.errorText}
              success={address.address2.success}
              onChange={(e) => handleFields("address 2", e.target.value)}
              paddingRight="5px"
              maxLength={50}
              css={{ paddingBottom: "0.6rem" }}
              onBlur={(e) => handleFields("address 2", e.target.value?.trim())}
            />
            <CustomInput
              formWidth="50%"
              type="text"
              label="City (Optional)"
              aria-label="City (Optional)"
              value={address.city.value}
              error={address.city.error}
              errorText={address.city.errorText}
              success={address.city.success}
              onChange={(e) => handleFields("city", e.target.value)}
              paddingRight="5px"
              maxLength={50}
              css={{ paddingBottom: "0.6rem" }}
              onBlur={(e) => handleFields("city", e.target.value?.trim())}
            />
            <CustomInput
              formWidth="50%"
              type="text"
              label="State (Optional)"
              aria-label="State (Optional)"
              value={address.state.value}
              error={address.state.error}
              errorText={address.state.errorText}
              success={address.state.success}
              onChange={(e) => handleFields("state", e.target.value)}
              paddingRight="5px"
              maxLength={50}
              css={{ paddingBottom: "0.6rem" }}
              onBlur={(e) => handleFields("state", e.target.value?.trim())}
            />
            <CustomInput
              formWidth="50%"
              type="text"
              label="Zip/Postal Code (Optional)"
              aria-label="Postal Code (Optional)"
              value={address.zip.value}
              error={address.zip.error}
              errorText={address.zip.errorText}
              success={address.zip.success}
              onChange={(e) => handleFields("zip", e.target.value)}
              paddingRight="5px"
              maxLength={50}
              css={{ paddingBottom: "0.6rem" }}
              onBlur={(e) => handleFields("zip", e.target.value?.trim())}
            />
            <CustomDropdown
              width="50%"
              type="text"
              label="Country / Region"
              aria-label="Country / Region"
              value={address.country.value ? address.country.value : "Please select"}
              error={address.country.error}
              errorText={address.country.errorText}
              success={address.country.success}
              css={{ paddingBottom: "0.6rem" }}
              onChange={(e) => handleFields("country", e.target.value)}
              paddingRight="5px"
            >
              <>
                <option disabled>Please select</option>
                {countryList.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
              </>
            </CustomDropdown>
          </FlexRow>
          <FlexRow style={{ marginTop: "10px" }}>
            <CustomSLButton
              css={{ padding: "0 10px 0 10px" }}
              primary
              aria-label="Save"
              role="button"
              disabled={isSaveDisabled}
              aria-disabled={isSaveDisabled}
              onClick={handleVendorSave}
            >
              Save
            </CustomSLButton>
            <CustomSLButton
              aria-label="Cancel"
              role="button"
              secondary
              onClick={() => {
                setIsVendorModalOpened(false), setEditVendorRecord(null);
              }}
            >
              Cancel
            </CustomSLButton>
          </FlexRow>
        </ModalBody>
      </Modal>
    </>
  );
};
