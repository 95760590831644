import styled from "@emotion/styled";
import { Tab, Tabs } from "@vds/tabs";
import { ColWithoutPadding } from "../../assets/styles/Theme.style";
import { isIframe } from "../../utility/navigation";

export const BodyWrapper = styled.div`
  position: relative;
`;

export const ColPaddingZero = styled(ColWithoutPadding)`
  position: relative;
`;

export const LeftSection = styled.div`
  flex: 0 0 auto;
  height: 100vh;
  z-index: 999999;
`;

export const RightSection = styled.div`
  margin-top: 45px;
  flex: 1 1 auto;
  overflow: auto;
  margin-top: ${isIframe(window) ? "0px" : "45px"};
  background: ${({ theme }) => theme.background} !important;
  color: ${({ theme }) => theme.text};
  height: ${({ theme }) =>
    theme.unAvailableTimer ? "calc( 100vh - " + theme.unAvailableHeight + "px )" : isIframe(window) ? "100vh" : "calc(100vh - 45px)"};
`;

export const CustomIconWrapper = styled.span`
  height: auto;
  width: auto;
  max-width: 16px;
  max-height: 16px;
  cursor: ${(prop) => (prop.disabled ? "default" : "pointer")};
  opacity: ${(prop) => (prop.disabled ? 0.5 : 1)};
  margin: ${(prop) => {
    let temp = prop.noMargin ? "auto 0" : "auto 12px auto 12px";
    return prop.marginLeft ? "auto 0 auto 10px" : temp;
  }};
  display: inline-block;
  svg {
    max-width: 16px;
    width: 16px;
    max-height: 16px;
  }
  svg > path {
    fill: ${({ theme }) => theme.text};
  }
  svg > g > path:not[class="st0"] {
    color: ${({ theme }) => theme.text};
    fill: ${({ theme }) => theme.text};
  }
  svg > g {
    fill: ${({ theme }) => theme.text};
  }
  svg g rect {
    fill: ${({ theme }) => theme.text};
  }
  svg > g > g {
    fill: ${({ theme }) => theme.text};
  }
  svg > g text,
  svg > g text tspan,
  svg > g tspan {
    color: ${({ theme }) => theme.text};
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  flex: ${(props) => (props.isOpenSM ? "0 0 100%" : "0 0 83%")};
  background: ${({ theme }) => theme.background1} !important;
  color: ${({ theme }) => theme.text};
`;
export const TabWithCross = styled(Tabs)`
  width: fit-content;
  max-width: calc(100% - 80px);
  padding: 0px 0px 0px 0px;
  max-height: 44px;
  background-color: #fff;
  background: ${({ theme }) => theme.background1} !important;
  color: ${({ theme }) => theme.text};
`;
export const TabLabelWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const TabLabel = styled.div`
  // padding-left: 5px;
  padding-right: 12px;
  font-size: 12px;
  line-height: 19px;
  overflow: hidden;
  // width: calc(100% - 14px);
  height: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
export const TabIcon = styled.span`
  svg {
    height: 12px;
    width: 12px;
    min-height: unset;
    min-width: unset;
  }
`;
export const TabHeadings = styled.div`
  display: flex;
  flex-wrap: nowrap;
  border-bottom: 1px solid ${({ theme }) => theme.border2};
  background-color: #f6f6f6;
  position: fixed;
  width: calc(100vw - 72px);
  z-index: 999999;
  top: 0px;
  justify-content: space-between;
  background: ${({ theme }) => theme.background1} !important;
  /* margin-left: -8px; */
`;
export const TabContentWrapper = styled.div`
  // display: none;
  margin-top: ${(props) => (props.noTabWindow && isIframe(window) ? "0px" : "0px")};
  max-width: ${(props) => (props.metricDashboard || props.mmidPortal || props.adminVendor ? "100%" : "100%")};
  padding: ${(props) => (props.metricDashboard ? "0px" : props.noTabWindow && isIframe(window) ? "0px 10px" : "10px 30px")};
  // padding-top: 42px;
`;

export const StyledTab = styled(Tab)`
  margin: 0px !important;
  width: ${(props) => (props.tabWidth ? props.tabWidth : "193px")};
  min-width: 110px;
  border-right: 1px solid ${({ theme }) => theme.border2};
  & > button {
    height: 44px;
    padding: 8px;
    min-height: 44px;
    width: 100%;
    &::before {
      left: 0px;
    }
  }
  span {
    display: inline-block;
  }
  .remove-link-style {
    color: ${({ theme }) => theme.text} !important;
  }
  svg {
    color: ${({ theme }) => theme.text} !important;
  }
`;
export const UnAvailableSection = styled.div`
  position: fixed;
  bottom: 0;
  // left: 72px;
  right: 0px;
  background-color: #ed7000;
  width: ${(props) => (props && props.setWidth ? props.setWidth : "96%")};
  z-index: 98;
  min-height: 38px;
  color: ${({ theme }) => theme.text};
  line-height: 38px;
  box-sizing: border-box;
`;

export const UnAvailableTimer = styled.div`
  width: ${(props) => (props.expand ? "90px" : "80px")};
  display: inline-block;
  padding: ${(props) => (props.expand ? "10px 5px" : "10px 0px")};
`;

export const MoreTabsWrapper = styled.div`
  padding: 10px;
  border-right: 1px solid #d8dada;
  background-color: ${({ theme }) => theme.background};
  width: 120px;
  position: relative;
`;
export const MoreTabsTitle = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
`;
export const MoreTabsIcon = styled.span``;
export const CloseIcon = styled.span``;
export const MoreTabsDropDownWrapper = styled.div`
  position: absolute;
  right: 0px;
  top: 40px;
  background: ${({ theme }) => theme.background};
  border: 1px solid ${({ theme }) => theme.text};
  padding-top: 0px;
  width: 300px;
  z-index: 99;
`;
export const MoreTabsDropdowntitle = styled.h4`
  padding: 0 15px;
`;

export const MoreTabsDropdownListEach = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  && > svg {
    float: right;
  }
`;

export const MoreTabsDropdownList = styled.div`
  max-height: 250px;
  overflow: auto;
  & ${MoreTabsDropdownListEach}:nth-child(2n) {
    background: ${({ theme }) => theme.background1};
  }
`;

export const TabTypeCount = styled.span`
  padding: 0px 6px 5px;
  width: 18px;
  height: 18px;
  background-color: #d8dada;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 8px;
  color: #000;
`;

export const MoreTabTypeCount = styled.span`
  padding: 4px 6px;
  width: 18px;
  height: 18px;
  background-color: #d8dada;
  border-radius: 50%;
  font-size: 10px;
  margin-right: 8px;
`;
