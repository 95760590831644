import { Modal, ModalBody } from "@vds/modals";
import { Icon } from "@vds/icons";
import { Title, Body } from "@vds/typography";
import { useState, useEffect } from "react";
import { SLButton } from "../../components/core/Controls";
import { FlexRow, Spacer } from "../../components/core/Utility/index";
import { css } from "@emotion/react";
import { DropdownSelect } from "@vds/selects";
import styled from "styled-components";
import { getRejectReasonsData } from "../../services/C4S Decommission - Replacement/getRejectReasonsData";

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    height: 2rem;
    & span {
      & span {
        & div {
          & select {
            padding: 0.2rem 1rem 0.2rem 0.5rem;
            font-size: 12px;
            color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
            text-wrap: wrap;
            line-height: 0.8rem;
            &:disabled {
              -webkit-text-fill-color: #000000;
              background-color: #ffffff;
              border-bottom: 1px solid #000000;
              opacity: ${(props) => (props.disabled ? "0.4" : "")};
            }
          }
          & span {
            top: 0.35rem;
            right: 0.5rem;
            & svg {
              width: 0.8rem;
              height: 0.8rem;
              min-width: 0.8rem;
              min-height: 0.8rem;
              display:${(props) => (props.hideErrorImg ? "none !important" : "")};
            }
          }
        }
        & div {
          & div {
            & p {
              display: none;
            }
          }
        }
      }
      }
    }
  }
`;

export const StyledModal = css`
  max-height: 99vmin;
  padding: 1rem 1rem 1rem 1rem;
  padding-right: 2px;
  width: 50vw;
  @media (max-width: 767px) {
    width: 560px !important;
    height: auto;
    min-height: 14.5rem;
    padding: 1rem 1rem 1rem 1rem;
    padding-right: 2px;
  }

  & footer {
    padding: 0;
  }
`;

const RejectReasonModal = ({ setRejectReasonModalStatus, handleCancelShipment, notify }) => {
  const [reason, setReason] = useState({});
  const [rejectReasons, setRejectReasons] = useState([]);
  const [isLoading, setLoader] = useState(false);
  const changeRescheduleReason = (e) => {
    let selectedReason = rejectReasons.filter((item) => item.description === e.target.value)[0];
    setReason(selectedReason);
  };

  useEffect(() => {
    fetchReasonsData();
  }, []);

  const fetchReasonsData = async () => {
    setLoader(true);
    let reasonsResponse = await getRejectReasonsData();
    if (reasonsResponse.status === 200 && reasonsResponse.data?.data?.length) {
      const data = reasonsResponse.data?.data;
      const tempNoShowReasons = data.filter((data) => data.category === "SO_REJECT" && data.subCategory === "C360_REASON");
      setRejectReasons(tempNoShowReasons);

      setLoader(false);
    } else {
      setLoader(false);
      notify("Unable to fetch Processing Codes due to an unexpected error.", "error");
      // setTimeout(() => {
      //   setNotification(null);
      // }, 5000);
    }
  };

  const handleConfirmation = async () => {
    handleCancelShipment(reason.description, reason.subCode);
  };
  return (
    <Modal
      id="Reschedule Reason Modal"
      ariaLabel="Reschedule Reason Modal"
      opened={true}
      fullScreenDialog={false}
      disableOutsideClick={true}
      css={StyledModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">Cancel Shipment?</Title>
          <div onClick={() => setRejectReasonModalStatus(false)} css={{ marginRight: "1rem", marginTop: "0.3rem" }}>
            <Icon
              role="img"
              aria-label="close"
              alt="close-icon"
              name="close"
              size="medium"
              color="#000000"
              style={{ cursor: "pointer", outline: "none" }}
            />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ paddingLeft: "0", paddingRight: "0" }}>
        <Body tabIndex={0}>Reject Reasons </Body>
        <Spacer top="1rem" />
        <div style={{ padding: "5px", paddingBottom: "0px", width: "70%", fontSize: "13px" }}>
          <CustomDropdown
            type="text"
            tabIndex={0}
            ariaLabel="Reschedule reason"
            value={reason.description || "Please Select"}
            onChange={(e) => changeRescheduleReason(e)}
            placeholder="Please Select"
          >
            <>
              <option disabled>Please Select</option>
              {rejectReasons?.map((code) => (
                <option key={code?.id}>{code?.description}</option>
              ))}
            </>
          </CustomDropdown>
        </div>
      </ModalBody>
      <FlexRow css={{ justifyContent: "flex-end", paddingTop: "6.5rem" }}>
        <SLButton
          aria-label="Confirm"
          role="button"
          size="large"
          css={{ width: "10rem" }}
          onClick={() => {
            handleConfirmation();
          }}
          disabled={!reason.code}
        >
          Confirm
        </SLButton>
        <SLButton size="large" secondary aria-label="Cancel" role="button" css={{ width: "10rem" }} onClick={() => setRejectReasonModalStatus(false)}>
          Cancel
        </SLButton>
      </FlexRow>
    </Modal>
  );
};

export default RejectReasonModal;
