import { useState, useEffect } from "react";
import { getRequest } from "../RestServices";
import * as urls from "../../api/aggregator";
import { getProperty } from "../../utility/getProperty";

export const batchHistory = async () => {
  try {
    const _response = await getRequest(urls.UPLOADUNITS);
    const _VtuDetails = getProperty(_response, "data.data", []);
    return { rows: _VtuDetails };
  } catch (err) {
    return {
      error: err,
    };
  }
};

export const useToGetEvents = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const fetchEvents = async () => {
    try {
      setData();
      setError("");
      setLoading(true);
      const _response = await getRequest(urls.GET_EVENTS);
      setData(_response?.data?.data ?? []);
    } catch (error) {
      setError(error?.response?.data?.message || "Unable to fetch the data");
      setData(error?.response?.data ?? []);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    error,
    isLoading,
    fetchEvents,
  };
};
