import React, { lazy, useContext, useState, useEffect } from "react";
import moment from "moment";
import { capitalize, isEqual, uniqBy, uniq, zip, groupBy, max } from "lodash";
import styled from "styled-components";
import { css } from "@emotion/react";
import { Loader } from "@vds/loaders";
import { Input, RadioButtonGroup } from "@vds/inputs";
import { Grid, Row } from "@vds/grids";
import { DropdownSelect } from "@vds/selects";
import { SLNotification } from "../../components/core/utility";
import { TextLink } from "@vds/buttons";
import { generate } from "shortid";
import { updateNewrelicStats } from "../../../utility/newrelic";
import { Header, SLButton } from "../../components/core/Controls";
import {
  FlexRow,
  FlexColumn,
  CompactCol,
  MediumText,
  SmallText,
  UltraMediumText,
  SLGridLayout,
  SLRowLayout,
} from "../../components/core/Utility/index";
import { addPage, resetTab } from "../../../utility/navigation";
import { sessionData, jobDurationFormatter, handleStartDate } from "../../utility/sessionData";
import {
  ADDRESS_REGEX,
  CITY_REGEX,
  WORKTICKETNUMBER_REGEX,
  EMAIL_REGEX_C360,
  NAME_REGEX,
  SCHEDULER_PHONE_REGEX,
  VEHICLECOUNT_REGEX,
  ZIP_REGEX,
  ZIP_CODE_LIMIT,
} from "../../constants/ValidationConstants";
import { AccountDetailsContext } from "../../context/accountDetailsContext";
import { ScheduleJobMetadata } from "./ScheduleJob";
import CustomerDetails from "./CustomerDetails";
import CSPJobLengthModal from "./CSPJobLengthModal";
import CaseJobLengthModal from "./CaseJobLengthModal";
import { validateAddress } from "../../services/scheduler/validateAddress";
import { addJob } from "../../services/scheduler/addJob";
import { updateJob } from "../../services/scheduler/updateJob";
import { salesOrgIdNameList, salesOrgBasedCurrency } from "../../constants/PartialCompletionReasons";
import { getJobDuration } from "../../services/scheduler/getJobDuration";
import SchedulingFeesModal from "./SchedulingFeesModal";
import { AlternateManualEntryMetadata } from "./AlternateManualSchedule";
import { getCSPDetails } from "../../services/scheduler/getCSPDetails";
import { DISTRIBUTION_CHANNEL_DROPDOWN_VALUES, DIVISION_DROPDOWN_VALUES } from "../../constants/C4SDecomConstants";
import { getFullName, switchTab, getMapUrl } from "../C4S Decommission - Replacement/utils";
import { getStatesList } from "../../services/C4S Decommission - Replacement/getStatesList";
import { getCountryList } from "../../services/scheduler/getCountryList";
import { addServiceJob } from "../../services/scheduler/addServiceJob";
import useOutsideClick from "../../customHook/use-outside-click";

export const CustomDropdown = styled(DropdownSelect)`
  & div {
    margin-top: ${(props) => (props.marginTop ? props.marginTop : "")};
    margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : "")};
    margin-left: ${(props) => (props.marginLeft ? props.marginLeft : "")};
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : "")};
    & label {
      & p {
        font-size: 12px;
        color: ${(props) => (props.disabled ? "#d8dada" : "")};
        & span {
          &::after {
            color: #747676;
          }
        }
      }
    }
    & span{
      & span{
        & div{
          & span{
            & svg{
              display:${(props) => (props.hideErrorImg ? "none !important" : "")};
            }
          }
        }
      }
    }
  }
  }
  & select {
    font-size: 12px;
    color: ${(props) => (props.disabled || props.disableValue ? "#666b6b" : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      // color: ${(props) => (props.disabled || props.disableValue ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
`;
export const CustomInput = styled(Input)`
  padding-bottom: 0.5rem;
  height: auto;
  & label {
    & p {
      font-size: 12px;
      font-weight: ${(props) => (props.fontWeight ? props.fontWeight : "400")};
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & span {
    & span {
      & span {
        width: ${(props) => (props.boxWidth ? props.boxWidth : "")};
      }
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: ${(props) => (props.width ? props.width : "")};
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;
export const CustomInputDynamicWidth = styled(Input)`
  padding-bottom: 0.5rem;
  height: auto;
  & label {
    & p {
      font-size: 12px;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
    }
  }
  & span {
    & span {
      & span {
        width: 100%;
      }
    }
  }
  & input {
    font-size: 12px;
    height: ${(props) => (props.height ? props.height : "")};
    &:disabled {
      -webkit-text-fill-color: #000000;
      background-color: #ffffff;
      border-bottom: 1px solid #000000;
      border-top: 1px solid #dfe0e0;
      color: ${(props) => (props.disabled ? "#d8dada" : "")};
      opacity: ${(props) => (props.disabled ? "0.4" : "")};
    }
  }
  & span {
    width: 100%;
    & span {
      & span {
        & div:not(:first-child) {
          & svg {
            display: none; // hide success tick icon
          }
        }
      }
    }
  }
`;
export const RadioButtonGroupWrapper = styled.div`
  div[role="radiogroup"] {
    border: 0.0625rem solid #d8dada;
    width: 70vmin;
    margin-bottom: 0.6rem;
    > div > label {
      > div:first-child > div {
        height: 1rem;
        width: 1rem;
        transform: translate(50%, 100%);
      }
      > div:last-child {
        font-size: 12px;
        font-family: Verizon-NHG-eTX;
        padding: 0.313rem 0.5rem;
        > span > span {
          font-size: 14px;
          font-family: Verizon-NHG-eTX;
        }
      }
    }
  }
`;
const CustomLoader = css`
  div[aria-label="loader overlay"] {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    top: 1.5rem;
    > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;

const CustomHistoricalLoader = css`
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  margin-top: 0.5rem;
  div[aria-label="loader overlay"] {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
    > div {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  & div {
    padding-left: 0.5rem;
  }
`;

const HistoricalAddressRow = css`
  @media screen and (min-width: 910px) {
    width: 50%;
    max-width: 37.25rem;
    min-width: 4.125rem;
  }
`;

const CustomSLButton = styled(SLButton)`
  & span {
    padding: 0.68rem 2rem;
  }
`;
export const CalendarWrapper = styled.div`
  > div > div:last-child {
    top: 3.5rem;
  }
`;

export const StyledRow = css`
  @media screen and (min-width: 1260px) {
  }
`;
export default function CreateNewJob({ jobData, isExpandJob }) {
  const {
    accountId,
    source,
    accountDetails,
    cspDetails,
    caseDetails,
    products,
    jobDurationDetails,
    jobLengthHours,
    jobLengthMinutes,
    historicalAddressDetails,
    countryListDetails,
    stateListDetails,
    // chargeableStates,
  } = useContext(AccountDetailsContext);

  const completedStatuses = ["Completed", "Partially Completed"];

  const [name, setName] = useState({
    value: "",
    error: false,
    errorText: "Enter name",
    success: false,
  });
  const [phone, setPhone] = useState({
    value: "",
    error: false,
    errorText: "Enter phone",
    success: false,
  });
  const [email, setEmail] = useState({
    value: "",
    error: false,
    errorText: "Enter email",
    success: false,
  });
  const [csp, setCsp] = useState({
    value: {
      id: "",
      name: "",
      owner: "",
    },
    error: true,
    errorText: "Unable to fetch csp details due to an unexpected error",
  });
  const [caseInfo, setCaseInfo] = useState({
    value: {
      id: "",
      number: "",
      owner: "",
    },
    error: true,
    errorText: "Unable to fetch case details due to an unexpected error",
  });
  const [workTicketNumber, setWorkTicketNumber] = useState({
    value: "",
    error: false,
    errorText: "Enter work ticket number",
    success: false,
  });
  const [jobType, setJobType] = useState(source.source === "case" ? "" : "INSTALL");
  const [startDate, setStartDate] = useState({
    value: null,
    error: true,
    errorText: "Select start date",
    success: false,
  });
  const [vehicleCount, setVehicleCount] = useState({
    value: "",
    error: false,
    errorText: "Enter vehicle count",
    success: false,
  });
  const [latitude, setLatitude] = useState({
    value: "",
    error: false,
    errorText: "Enter latitude",
    success: false,
  });
  const [longitude, setLongitude] = useState({
    value: "",
    error: false,
    errorText: "Enter longitude",
    success: false,
  });
  const [apptLength, setApptLength] = useState("");
  const countryZipLimitsList = {
    DEU: [5, 5],
    GBR: [4, 8],
    FRA: [5, 5],
    CAN: [6, 6],
    NLD: [4, 4],
    IRL: [7, 7],
    MEX: [5, 5],
    NZL: [4, 4],
    USA: [5, 5],
    AUS: [4, 4],
    AUT: [4, 4],
    BEL: [4, 4],
  };
  const [address, setAddress] = useState({
    verified: false,
    addressByPass: null,
    locationFound: null,
    historicalAddress: { value: "", error: false, errorText: "", success: false },
    address1: { value: "", error: false, errorText: "Enter address 1", success: false },
    address2: { value: "", error: false, errorText: "Enter address 2", success: false },
    city: { value: "", error: false, errorText: "Enter city", success: false },
    country: { value: null, options: [], error: false, errorText: "Please select country", success: false },
    state: { value: null, options: [], allStateOptions: null, error: false, errorText: "Please select state", success: false },
    zip: { value: "", error: false, errorText: "Enter zip code", success: false },
  });
  const [zipCodeLimit, setZipCodeLimit] = useState("");
  const [zipRegex, setZipRegex] = useState("");
  const [timezone, setTimezone] = useState("");
  const [addressFieldsIsDisable, setAddressFieldsIsDisable] = useState(true);
  const [isValidatingAddress, setIsValidatingAddress] = useState(false);
  const [isValidatingHistoricalAddress, setIsValidatingHistoricalAddress] = useState(false);
  const [verifyAddressIsDisable, setVerifyAddressIsDisable] = useState(true);
  const [suggestionsIsShown, setSuggestionsIsShown] = useState(false);
  const [showLatLong, setShowLatLong] = useState(false);
  const [isLoading, setLoader] = useState(false);
  const [suggestedAddress, setSuggestedAddress] = useState({
    address: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    longitude: "",
    latitude: "",
  });
  const [typedAddress, setTypedAddress] = useState("");

  const [isFieldsDisabled, setIsFieldsDisabled] = useState(true);
  const [isCAFieldsDisabled, setIsCAFieldsDisabled] = useState(true);
  const [checkAvailabilityIsDisable, setCheckAvailabilityIsDisable] = useState(true);
  const [notification, setNotification] = useState(null);
  const today = new Date(); // get today's date
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);
  let nextday = moment(tomorrow.toDateString()).format("MM/DD/YYYY");

  const [cspJobLengthModal, setCSPJobLengthModal] = useState(false);
  const [caseJobLengthModal, setCaseJobLengthModal] = useState(false);
  const [isNewJob, setIsNewJob] = useState(true);
  const [existingJobQuantityList, setExistingJobQuantityList] = useState([]);

  // const [schedulingFeesModal, setSchedulingFeesModal] = useState(null);

  const [isCSPAccountDetails, setIsCSPAccountDetails] = useState(false);
  const [scheduleType, setScheduleType] = useState("AUTO");
  const [workTicketRegex] = useState(WORKTICKETNUMBER_REGEX);
  // const latitudeLongitudeEnabledCountry = "MEX";
  // const mexicoDefaultCoordinates = { latitude: "19.26770622384666", longitude: "-99.09912411137512" };
  const [existingPO, setExistingPO] = useState({
    value: "",
    error: false,
    errorText: "Enter PO number",
    success: false,
  });
  const [division, setDivision] = useState({ value: accountDetails.division, error: false, errorText: "Select Division", success: true });
  const [distributionChannel, setDistributionChannel] = useState({
    value: accountDetails.distributionChannel,
    error: false,
    errorText: "Select Distribution Channel",
    success: true,
  });
  const [contractAccountList, setContractAccountList] = useState(jobData?.isC4SWorkTicket ? [] : [...accountDetails.contractAccount]);
  const [contractAccount, setContractAccount] = useState({
    value: jobData?.isC4SWorkTicket
      ? jobData?.contractAccount
      : jobData?.id && accountDetails?.contractAccount.includes(jobData?.contractAccountId)
      ? jobData?.contractAccountId
      : jobData?.id && !accountDetails?.contractAccount.includes(jobData?.contractAccountId)
      ? "Please Select"
      : accountDetails?.contractAccount.length > 1
      ? "Please Select"
      : accountDetails?.contractAccount.length === 1 && accountDetails?.contractAccount[0]
      ? accountDetails?.contractAccount[0]
      : "Please Select",
    error: false,
    errorText: "Select Contract Account",
    success: jobData?.isC4SWorkTicket
      ? true
      : jobData?.id && accountDetails?.contractAccount.includes(jobData?.contractAccountId)
      ? true
      : jobData?.id && !accountDetails?.contractAccount.includes(jobData?.contractAccountId)
      ? false
      : accountDetails?.contractAccount.length > 1
      ? true
      : accountDetails?.contractAccount.length === 1 && accountDetails?.contractAccount[0]
      ? true
      : false,
  });
  const [salesOrg, setSalesOrg] = useState({
    value: "",
    error: false,
    errorText: "Please select Sales Org",
    success: true,
    options: [],
  });

  const enableJobEndDate = address.country.value?.code !== "USA" && address.country.value?.code !== "UNITED STATES";
  const [endDate, setEndDate] = useState({
    value: null,
    error: false,
    errorText: "Select end date",
    success: false,
  });
  const [startTime, setStartTime] = useState({
    value: null,
    error: false,
    errorText: "Select start time",
    success: false,
  });
  const [endTime, setEndTime] = useState({
    value: null,
    error: false,
    errorText: "Select end time",
    success: false,
  });
  const [isAddServices, setIsAddServices] = useState(jobData?.isAddServices ? true : false);
  const handleClickOutside = () => {
    setNotification(null);
  };

  const clickRef = useOutsideClick(handleClickOutside);
  const cspSessionData = JSON.parse(sessionStorage.getItem("CSPDetailsSchedulingPortal"));
  const [autoSchedulingCountries, setAutoSchedulingCountries] = useState(cspSessionData?.autoSchedulingCountries);
  useEffect(() => {
    if (!jobData || !jobData?.isC4SWorkTicket) {
      !isCSPAccountDetails && handleAddress(historicalAddressDetails.historicalAddressDetails);
    }
    if (jobData?.isC4SWorkTicket && !isCSPAccountDetails) {
      source.setSource(jobData.source?.toLowerCase());
      getCSPAcccountDetails(jobData.AccountID, jobData.source?.toLowerCase(), jobData.id, jobData?.userId);
    } else if (source.source === "case" && !caseDetails.caseDetails.length) {
      !accountDetails.customerName || !accountDetails.customerNumber || !accountDetails.vztSegment
        ? notify("Unable to get account details and case details due to an unexpected error", "error")
        : notify("Unable to get case details due to an unexpected error", "error");
    } else if (source.source !== "case" && !cspDetails.cspDetails.length) {
      !accountDetails.customerName || !accountDetails.customerNumber || !accountDetails.vztSegment
        ? notify("Unable to get account details and CSP account details due to an unexpected error", "error")
        : notify("Unable to get CSP account details due to an unexpected error", "error");
    }
    if (jobData && Object.keys(jobData).length) {
      if (!isExpandJob && !jobData.isC4SWorkTicket) {
        setIsNewJob(false);
      }
      setScheduleType(jobData.scheduleType);
      handleFields("Name", jobData.contactName);
      handleFields("Phone", jobData.phone);
      handleFields("Email", jobData.email);
      handleFields("Division", jobData.division);
      handleFields("Distribution Channel", jobData.distributionChannel);
      //For support cases work ticket number is optional
      let workTicketNumber = jobData.jobDetails[0].workTickets.map((workTickets) => workTickets.workTicket)?.join(",");
      handleFields("Work Ticket Number", workTicketNumber || "");
      let purchaseOrder = jobData?.jobDurationDetails
        ?.map((jobDetails) => jobDetails.purchaseOrder)
        ?.filter((purchaseOrder) => purchaseOrder !== null);
      purchaseOrder = uniq(purchaseOrder).join(",");
      handleFields("Existing PO", purchaseOrder || "");
      handleFields("Start Date", new Date(jobData.startDate));
      handleFields("End Date", jobData.scheduleEndDate || "");
      handleFields("Start Time", jobData.scheduleStartTime);
      handleFields("End Time", jobData.scheduleEndTime);
      handleFields("Vehicle Count", jobData.jobDetails[0].vehicleCount);
      handleFields("Vehicle Count", jobData.jobDetails[0].vehicleCount);
      handleFields("Appt.Length", jobData.appointmentLength.toString());

      jobData.isC4SWorkTicket ? setJobType(source.source === "case" ? "" : "INSTALL") : setJobType(jobData.jobDetails[0].jobType);
      if (!isExpandJob) {
        jobLengthHours.setJobLengthHours(jobData.appointmentLength);
        jobLengthMinutes.setJobLengthMinutes(jobData.estAppointmentLength);
      }
      source.source === "case"
        ? setCaseInfo((prevState) => ({
            ...prevState,
            value: { id: jobData.jobDetails[0].caseSfdcId, number: jobData.jobDetails[0].caseNumber, owner: jobData.jobDetails[0].caseOwner },
            error: false,
          }))
        : setCsp((prevState) => ({
            ...prevState,
            value: { id: jobData.jobDetails[0].cspSfdcId, name: jobData.jobDetails[0].cspName, owner: jobData.jobDetails[0].cspOwner },
            error: false,
          }));
    } else {
      if (source.source === "case") {
        setIsCAFieldsDisabled(false);
        const contractAccounts = contractAccountList.filter((ca) => ca !== null && ca !== undefined && ca !== "");
        setContractAccount({
          value: contractAccounts?.length === 1 ? contractAccounts[0] : "Please Select",
          error: false,
          errorText: "",
          success: true,
        });
      }

      if (caseDetails.caseDetails.length && source.source === "case") {
        setCaseInfo((prevState) => ({
          ...prevState,
          value: {
            id: caseDetails.caseDetails[0].CaseSFDCID,
            number: caseDetails.caseDetails[0].CaseNumber,
            owner: caseDetails.caseDetails[0].CaseOwner,
          },
          error: false,
        }));
      } else if (cspDetails.cspDetails.length) {
        let cspAccDetails = cspDetails.cspDetails.filter((cspDetail) => cspDetail.DefaultCSP === "1")[0];
        setCsp((prevState) => ({
          ...prevState,
          value: { id: cspAccDetails.CSPID, name: cspAccDetails.CSPName, owner: cspAccDetails.CSPOwner },
          error: false,
        }));
      }
      //For support cases work ticket number is optional
      source.source === "case" && handleFields("Work Ticket Number", "");
    }
    if (jobData?.salesOrg && jobData?.salesOrgDescription) {
      let salesOrgIdName = jobData?.salesOrg && jobData?.salesOrgDescription ? `${jobData?.salesOrg} ${jobData?.salesOrgDescription}` : "";
      setSalesOrg((prevState) => ({
        ...prevState,
        value: salesOrgIdName,
        error: false,
        errorText: prevState.options.length ? "Please select Sales Org" : "Unable to fetch Sales Org due to an unexpected error",
        success: true,
      }));
    }
    handleSalesOrg(salesOrg.value);
  }, [isCSPAccountDetails]);

  // useEffect(() => {
  //   let showModal =
  //     chargeableStates?.chargeableStates &&
  //     chargeableStates?.chargeableStates.length !== 0 &&
  //     chargeableStates?.chargeableStates.find((item) => item === address.state.value) !== undefined;
  //   showModal && setSchedulingFeesModal(<SchedulingFeesModal handleCancel={() => resetTab()} handleConfirm={() => setSchedulingFeesModal(null)} />);
  // }, [chargeableStates.chargeableStates, address.state.value]);

  useEffect(() => {
    if (source.source === "case" && contractAccount?.value?.length === 0) {
      setCheckAvailabilityIsDisable(true);
    }

    if (source.source === "case" && contractAccount?.value === undefined) {
      setCheckAvailabilityIsDisable(true);
    }
    if (source.source === "case" && contractAccount?.value === "Please Select") {
      setCheckAvailabilityIsDisable(true);
    }
    if (
      address.address1.success &&
      !address.address2.error &&
      address.city.success &&
      address.state.success &&
      address.country.success &&
      address.zip.success
    ) {
      if (address.verified) {
        !address.historicalAddress.value.includes("Please")
          ? !addressFieldsIsDisable && setAddressFieldsIsDisable(true)
          : addressFieldsIsDisable && setAddressFieldsIsDisable(false);
      } else {
        if ((jobData?.isC4SWorkTicket || jobData?.id) && address.addressByPass === "NeedToValidateOnLoad" && !isAddServices) {
          if (!jobData?.addressByPass) {
            handleAddressVerification(address);
          } else {
            setAddress((prevState) => ({ ...prevState, verified: true, addressByPass: jobData.addressByPass, locationFound: jobData.locationFound }));
          }
        } else {
          if (suggestionsIsShown) {
            !addressFieldsIsDisable && setAddressFieldsIsDisable(true);
            !verifyAddressIsDisable && setVerifyAddressIsDisable(true);
            if (!suggestedAddress.address && suggestionsIsShown) {
              setSuggestionsIsShown(false);
              setAddress((prevState) => ({ ...prevState, verified: true }));
            }
          } else if (!address.historicalAddress.value.includes("Please") && address.historicalAddress.success) {
            handleAddressVerification(address);
          } else {
            addressFieldsIsDisable && setAddressFieldsIsDisable(false);
            verifyAddressIsDisable && setVerifyAddressIsDisable(false);
          }
        }
      }
    } else {
      if (address.addressByPass === "NeedToValidateOnLoad") {
        setAddress((prevState) => ({
          ...prevState,
          addressByPass: null,
        }));
      } else {
        if (!suggestedAddress.address && suggestionsIsShown) {
          setSuggestionsIsShown(false);
          setAddress((prevState) => ({ ...prevState, verified: false }));
        } else if (!address.historicalAddress.value) {
          !addressFieldsIsDisable && setAddressFieldsIsDisable(true);
        } else {
          !address.historicalAddress.value.includes("Please") &&
            setAddress((prevState) => ({ ...prevState, historicalAddress: { ...prevState.historicalAddress, error: true, success: false } }));
          addressFieldsIsDisable && setAddressFieldsIsDisable(false);
          !verifyAddressIsDisable && setVerifyAddressIsDisable(true);
        }
      }
    }

    if (address.verified) {
      if (isFieldsDisabled) {
        setIsFieldsDisabled(false);
        if (source.source !== "case") setIsCAFieldsDisabled(false);
        if (jobData && Object.keys(jobData).length) {
          if (source.source === "case") setIsCAFieldsDisabled(true);
        } else {
          if (source.source === "case") setIsCAFieldsDisabled(false);
        }

        let minDate = scheduleType === "AUTO" ? new Date(handleStartDate()) : new Date(nextday);
        if (!startDate.value || new Date(startDate.value) < minDate) {
          handleFields("Start Date", minDate);
        }
      }
      if (
        (source.source === "case" ? !caseInfo.error : !csp.error) &&
        // isWorkTicketNumberSuccess() &&
        contractAccount?.value &&
        contractAccount?.value !== "Please Select" &&
        contractAccount.success &&
        name.success &&
        phone.success &&
        email.success &&
        startDate.success &&
        vehicleCount.success &&
        jobLengthHours.jobLengthHours &&
        checkAvailabilityIsDisable &&
        salesOrg.success
        // && isLatitudeAndLongitudeSuccess()
      ) {
        setCheckAvailabilityIsDisable(false);
      } else if (
        ((source.source === "case" ? caseInfo.error : csp.error) ||
          // !isWorkTicketNumberSuccess() ||
          !name.success ||
          !phone.success ||
          !email.success ||
          !startDate.success ||
          !vehicleCount.success ||
          !jobLengthHours.jobLengthHours ||
          !salesOrg.success) &&
        // || !isLatitudeAndLongitudeSuccess()
        !checkAvailabilityIsDisable
      ) {
        setCheckAvailabilityIsDisable(true);
      }
    } else {
      setIsFieldsDisabled(true);
      setIsCAFieldsDisabled(true);
      setCheckAvailabilityIsDisable(true);
    }
    if (isAddServices) {
      setAddressFieldsIsDisable(true);
      setIsFieldsDisabled(true);
      setCheckAvailabilityIsDisable(true);
    }
  }, [
    address.verified,
    address.addressByPass,
    address.historicalAddress.value,
    address.address1.value,
    address.address2.value,
    address.city.value,
    address.state.value,
    address.zip.value,
    address.country.value,
    suggestedAddress.address,
    csp.error,
    workTicketNumber.value,
    contractAccount.success,
    contractAccount.value,
    name.value,
    phone.value,
    email.value,
    startDate.success,
    vehicleCount.success,
    jobLengthHours.jobLengthHours,
    salesOrg.success,
  ]);

  const notify = (content, type) => {
    setNotification(<SLNotification key={generate()} type={type} title={content} fullBleed={true} ref={clickRef} />);
  };

  const isWorkTicketNumberSuccess = () => {
    if (source.source === "case") return !workTicketNumber.error;
    return workTicketNumber.success;
  };

  const isLatitudeAndLongitudeSuccess = () => {
    // if (address.country.value === latitudeLongitudeEnabledCountry) {
    //   if ((latitude.value && !longitude.success) || (longitude.value && !latitude.success)) return false;
    //   return true;
    // }
    return true;
  };

  const getCSPAcccountDetails = async (accId, source, id, userId) => {
    updateNewrelicStats();
    setLoader(true);
    let response = await getCSPDetails(accId, id, userId);
    let addressArray = [];
    if (response.status === 200 && response?.data?.data) {
      let data = response.data.data;
      if (data.location && data.location.length > 0) {
        data.location.map((address) => {
          addressArray.push(address.address);
        });
      }
      setAutoSchedulingCountries(data.autoSchedulingCountries);
      historicalAddressDetails.setHistoricalAddressDetails(addressArray);
      let salesOrgId,
        salesOrgName,
        providerOrder = data.CSP.length ? data.CSP.find((csp) => csp.DefaultCSP === "1")?.Provider_Order ?? "" : "";
      let currencyCode = "";
      if (source === "csp") {
        currencyCode = data?.CSP?.length ? data?.CSP?.find((csp) => csp.DefaultCSP === "1")?.currencyCode ?? "" : "";
        salesOrgName = data?.CSP?.length ? data.CSP.find((csp) => csp.DefaultCSP === "1")?.salesOrgName ?? "" : "";
        salesOrgId = data.CSP.length ? data.CSP.find((csp) => csp.DefaultCSP === "1")?.Opp_Sales_Org ?? "" : "";
      } else {
        currencyCode = data?.CASEDetails?.length ? data?.CASEDetails?.find((caseDetails) => caseDetails.DefaultCase === "1")?.currencyCode ?? "" : "";
        salesOrgName = data?.CASEDetails?.length ? data?.CASEDetails?.find((caseDetails) => caseDetails.DefaultCase === "1")?.salesOrgName ?? "" : "";
        salesOrgId = data?.CASEDetails?.length ? data?.CASEDetails?.find((caseDetails) => caseDetails.DefaultCase === "1")?.salesOrg ?? "" : "";
      }

      accountDetails.setAccountDetails({
        customerName: data.AccountName,
        customerNumber: data.AccountNo,
        vztSegment: data.VZCSegement,
        sfdcUsername: data.SFDCUsername,
        division: data.division,
        distributionChannel: data.distributionChannel,
        salesOrgId,
        salesOrgName,
        providerOrder,
        erpBpNumber: data.ERP_Partner_Number,
        erpPrimaryContact: data.ERP_Primary_Contact_NUmber,
        resellerBusinessPartnerNumber: data.ResellerBusinessPartnerNumber,
        employeeId: data.Employee_ID,
        employeeEmail: data.email,
        employeeFullName: data?.firstName + " " + data?.lastName,
        billingCountry: data.BillingCountry,
        accountBrand: data.Account_Brand ?? "",
        mapUrl: data.mapUrl,
        currencyCode: currencyCode,
      });
      accountId.setAccountId(accId);
      data.Product?.length ? products.setProducts(uniqBy(data.Product, "TechRefId")) : products.setProducts([]);
      sessionStorage.setItem("Name", JSON.stringify(data.firstName + " " + data.lastName));
      if (data.CSP && Array.isArray(data.CSP)) {
        cspDetails.setCspDetails(data.CSP);
      }
      if (data.CASEDetails && Array.isArray(data.CASEDetails)) {
        caseDetails.setCaseDetails(data.CASEDetails);
      }
      setContractAccountList(data.CONTRACT_ACCOUNT_ID);
      handleSalesOrg(jobData.salesOrg, salesOrgId, salesOrgName, data.BillingCountry);
    } else {
      notify("Unable to fetch data due to an unexpected error", "error");
    }
    setIsCSPAccountDetails(true);
    handleAddress(addressArray);
  };

  const handleSalesOrg = (
    value,
    salesOrgId = accountDetails.salesOrgId,
    salesOrgName = accountDetails.salesOrgName,
    billingCountry = accountDetails.billingCountry
  ) => {
    if (!value) {
      let salesOrgIdName = salesOrg.value,
        salesOrgList = salesOrgIdNameList[billingCountry] || [];
      if (!salesOrgIdName) {
        salesOrgIdName = salesOrgId && salesOrgName ? `${salesOrgId} ${salesOrgName}` : "";
        if (!salesOrgIdName) {
          salesOrgIdName = salesOrgList.length === 1 ? salesOrgList[0] : "";
        }
      }
      setSalesOrg((prevState) => ({
        ...prevState,
        value: salesOrgIdName,
        error: !salesOrgIdName,
        success: !!salesOrgIdName,
        errorText: salesOrgList.length ? "Please select Sales Org" : "Unable to fetch Sales Org due to an unexpected error",
        options: salesOrgList,
      }));
    } else {
      setSalesOrg((prevState) => ({
        ...prevState,
        value,
        error: false,
        errorText: prevState.options.length ? "Please select Sales Org" : "Unable to fetch Sales Org due to an unexpected error",
        success: true,
      }));
    }
  };

  const getCountries = async () => {
    updateNewrelicStats();
    let countryOptions = [],
      allCountryStateOptions = null;
    let countryListResponse = countryListDetails.countryListDetails ? countryListDetails.countryListDetails : await getCountryList();
    if (countryListResponse.status === 200 && countryListResponse?.data?.data?.length) {
      countryOptions = countryListResponse.data.data;
      countryListDetails.setCountryListDetails(countryListResponse);
      ({ allCountryStateOptions } = await getStateList());
    } else {
      notify("Unable to fetch country list due to an unexpected error", "error");
    }
    setAddress((prevState) => ({
      ...prevState,
      country: {
        ...prevState.country,
        options: countryOptions,
        error: !countryOptions.length,
        errorText: countryOptions.length ? prevState.country.errorText : "Unable to fetch country list due to an unexpected error",
        success: false,
      },
      state: {
        ...prevState.state,
        options: [],
        allStateOptions: allCountryStateOptions,
        error: !allCountryStateOptions,
        errorText: allCountryStateOptions ? prevState.state.errorText : "Unable to fetch state list due to an unexpected error",
        success: false,
      },
    }));
    return { countryOptions, allCountryStateOptions };
  };

  const getStateList = async () => {
    updateNewrelicStats();
    let allCountryStateOptions = null;
    let stateListResponse = stateListDetails.stateListDetails ? stateListDetails.stateListDetails : await getStatesList();
    if (stateListResponse.status === 200 && stateListResponse?.data?.data?.length) {
      stateListDetails.setStateListDetails(stateListResponse);
      allCountryStateOptions = stateListResponse.data.data.map((state) => ({
        ...state,
        stateName: state.erpStateCode + " - " + state.stateCode,
      }));
      allCountryStateOptions = groupBy(allCountryStateOptions, "countryCode");
    } else {
      notify("Unable to fetch state list due to an unexpected error", "error");
    }
    return { allCountryStateOptions };
  };

  const handleAddress = async (historicalAddressList) => {
    !isLoading && setLoader(true);
    let { countryOptions, allCountryStateOptions } = await getCountries();
    setLoader(false);
    if (jobData && Object.keys(jobData).length) {
      let usedAddress =
        jobData.addressOne +
        ", " +
        (jobData.addressTwo ? `${jobData.addressTwo}, ` : "") +
        jobData.city +
        ", " +
        jobData.stateCode +
        ", " +
        jobData.zipCode +
        ", " +
        jobData.country;
      setAddress((prevState) => ({
        ...prevState,
        historicalAddress: {
          value: historicalAddressList.includes(usedAddress) ? usedAddress : "Please select an address or choose Add New to add a new address",
          error: false,
          errorText: "",
          success: true,
        },
      }));
      handleAddressFields("Address 1", jobData.addressOne?.trim());
      handleAddressFields("Address 2", jobData.addressTwo?.trim());
      handleAddressFields("City", jobData.city?.trim());
      handleAddressFields(
        "Country",
        jobData.country?.trim(),
        jobData.stateCode?.trim(),
        jobData.zipCode?.trim(),
        countryOptions,
        allCountryStateOptions
      );
      handleFields("Latitude", jobData?.latitude ?? "");
      handleFields("Longitude", jobData?.longitude ?? "");
      setTimezone(jobData.timezone ?? "");
      setAddress((prevState) => ({
        ...prevState,
        addressByPass: "NeedToValidateOnLoad",
      }));
    }
  };

  const handleAddressFields = (
    field,
    value,
    customValue1 = false,
    customValue2 = false,
    countryOptions = false,
    allCountryStateOptions = false,
    trim = false
  ) => {
    if (!trim) {
      if (address.verified) {
        setAddress((prevState) => ({ ...prevState, verified: false }));
        latitude.value &&
          setLatitude({
            value: "",
            error: false,
            errorText: "Enter latitude",
            success: false,
          });
        longitude.value &&
          setLongitude({
            value: "",
            error: false,
            errorText: "Enter longitude",
            success: false,
          });
        showLatLong && setShowLatLong(false);
      }
    }
    switch (field) {
      case "Address 1": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: false, errorText: "Enter address 1", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address1: { value, error: true, errorText: "Enter a valid address 1", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address1: { value: "", error: true, errorText: "Enter address 1", success: false } }));
        }
        break;
      }
      case "Address 2": {
        if (value && value.length && value.toLowerCase() !== "null") {
          if (ADDRESS_REGEX.test(value)) {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: false, errorText: "Enter address 2", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, address2: { value, error: true, errorText: "Enter a valid address 2", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, address2: { value: "", error: false, errorText: "Enter address 2", success: false } }));
        }
        break;
      }
      case "City": {
        value && value.length && value.toLowerCase() !== "null"
          ? ADDRESS_REGEX.test(value)
            ? setAddress((prevState) => ({ ...prevState, city: { ...prevState.city, value, error: false, errorText: "Enter city", success: true } }))
            : setAddress((prevState) => ({
                ...prevState,
                city: { ...prevState.city, value, error: true, errorText: "Enter valid city", success: false },
              }))
          : setAddress((prevState) => ({
              ...prevState,
              city: { ...prevState.city, value: "", error: true, errorText: "Enter city", success: false },
            }));
        break;
      }
      case "State": {
        value = customValue1.find((state) => value === state.erpStateCode || value?.toLowerCase() === state.stateCode.toLowerCase()) ?? null;
        if (value) {
          setAddress((prevState) => ({
            ...prevState,
            state: { ...prevState.state, value, error: false, errorText: "Please select state", success: true },
          }));
        } else {
          setAddress((prevState) => ({
            ...prevState,
            state: { ...prevState.state, value: null, error: true, errorText: "Please select state", success: false },
          }));
        }
        break;
      }
      case "Zip": {
        value = value.replace(/[^a-zA-Z0-9 ]/g, "");
        if (value && value.length && value.toLowerCase() !== "null") {
          if (customValue1.test(value)) {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: false, errorText: "Enter zip code", success: true } }));
          } else {
            setAddress((prevState) => ({ ...prevState, zip: { value, error: true, errorText: "Enter a valid zip code", success: false } }));
          }
        } else {
          setAddress((prevState) => ({ ...prevState, zip: { value: "", error: true, errorText: "Enter zip code", success: false } }));
        }
        break;
      }
      case "Country": {
        countryOptions = countryOptions || address.country.options;
        let stateOptions = allCountryStateOptions || address.state.allStateOptions;
        value =
          countryOptions.find(
            (country) => country.code.toLowerCase() === value?.toLowerCase() || country.description.toLowerCase() === value?.toLowerCase()
          ) ?? null;
        let { zipCodeRegex } = handleZipCodeBasedOnCountry(value?.code ?? null);
        if (value) {
          stateOptions = stateOptions ? stateOptions[value.code] : [];
          setAddress((prevState) => ({
            ...prevState,
            country: { ...prevState.country, value, error: false, errorText: "Please select country", success: true },
            state: {
              ...prevState.state,
              value: null,
              options: stateOptions,
              error: !stateOptions.length || prevState.state.value || prevState.state.error ? true : false,
              success: false,
            },
          }));
          stateOptions.length && customValue1 && handleAddressFields("State", customValue1, stateOptions);
        } else {
          setAddress((prevState) => ({
            ...prevState,
            country: {
              ...prevState.country,
              value: null,
              error: true,
              errorText: prevState.country.error ? prevState.country.errorText : "Please select country",
              success: false,
            },
            state: {
              ...prevState.state,
              value: null,
              options: [],
              error: prevState.state.value || prevState.state.error ? true : false,
              success: false,
            },
          }));
        }
        customValue2
          ? handleAddressFields("Zip", customValue2, zipCodeRegex)
          : address.zip.value && handleAddressFields("Zip", address.zip.value, zipCodeRegex);
        break;
      }
    }
  };

  const handleZipCodeBasedOnCountry = (country) => {
    let zipCodeRegex = ZIP_REGEX,
      zipCodeLimit = ZIP_CODE_LIMIT;
    if (country) {
      let limits = countryZipLimitsList[country];
      (zipCodeRegex = new RegExp(`^\\d{${limits[0]},${limits[1]}}$`)), (zipCodeLimit = limits[1]);
      if (["GBR", "CAN", "IRL", "NLD"].includes(country)) {
        if (country === "IRL") {
          zipCodeRegex = new RegExp("^[a-zA-Z0-9]{3} [a-zA-Z0-9]{4}$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "CAN") {
          zipCodeRegex = new RegExp("^[a-zA-Z]\\d[a-zA-Z] \\d[a-zA-Z]\\d$");
          zipCodeLimit = limits[1] + 1;
        }
        if (country === "GBR") {
          zipCodeRegex = new RegExp(`^[a-zA-Z0-9 ]{${limits[0]},${limits[1]}}$`);
          zipCodeLimit = limits[1];
        }
        if (country === "NLD") {
          zipCodeRegex = new RegExp("^[0-9]{4} [a-zA-Z]{2}$");
          zipCodeLimit = 7;
        }
      } else {
        zipCodeLimit = limits[1];
      }
    }
    setZipRegex(zipCodeRegex);
    setZipCodeLimit(zipCodeLimit);
    return { zipCodeRegex };
  };

  const splitAddress = (address) => {
    const addressParts = address.split(",");
    let address1 = "";
    let address2 = "";
    let city = "";
    let state = "";
    let zipCode = "";
    let country = "";
    if (addressParts.length <= 6) {
      address1 = addressParts[0].trim();
      city = addressParts[addressParts.length - 4].trim();
      state = addressParts[addressParts.length - 3].trim();
      zipCode = addressParts[addressParts.length - 2].trim();
      country = addressParts[addressParts.length - 1]?.trim();
      if (addressParts.length > 5) {
        address2 = addressParts.slice(1, -4).join(",").trim();
      }
    }
    const addressObj = { address1, address2, city, state, zipCode, country };
    return addressObj;
  };

  const handleHistoricalAddressSelection = (addressSelected) => {
    latitude.value &&
      setLatitude({
        value: "",
        error: false,
        errorText: "Enter latitude",
        success: false,
      });
    longitude.value &&
      setLongitude({
        value: "",
        error: false,
        errorText: "Enter longitude",
        success: false,
      });
    showLatLong && setShowLatLong(false);
    if (addressSelected === "Add New") {
      setAddress((prevState) => ({
        ...prevState,
        address1: { value: "", error: false, errorText: "Enter address 1", success: false },
        address2: { value: "", error: false, errorText: "Enter address 2", success: false },
        city: { value: "", error: false, errorText: "Enter city", success: false },
        state: {
          ...prevState.state,
          value: null,
          options: [],
          error: !prevState.state.allStateOptions,
          errorText: prevState.state.options.length ? "Please select state" : prevState.state.errorText,
          success: false,
        },
        zip: { value: "", error: false, errorText: "Enter zip code", success: false },
        country: {
          ...prevState.country,
          value: null,
          error: !prevState.country.options.length,
          errorText: prevState.country.options.length ? "Please select country" : prevState.country.errorText,
          success: false,
        },
        historicalAddress: { value: "Please select an address or choose Add New to add a new address", error: false, errorText: "", success: false },
        verified: false,
      }));
    } else {
      let splittedAddress = splitAddress(addressSelected);
      handleAddressFields("Address 1", splittedAddress.address1);
      handleAddressFields("Address 2", splittedAddress.address2);
      handleAddressFields("City", splittedAddress.city);
      handleAddressFields("Country", splittedAddress.country, splittedAddress.state, splittedAddress.zipCode);
      setAddress((prevState) => ({
        ...prevState,
        historicalAddress: { value: addressSelected, error: false, errorText: "", success: true },
        verified: false,
      }));
    }
  };

  const handleAddressVerification = async (address) => {
    updateNewrelicStats();
    !address.historicalAddress.value.includes("Please") ? setIsValidatingHistoricalAddress(true) : setIsValidatingAddress(true);
    !addressFieldsIsDisable && setAddressFieldsIsDisable(true);
    !verifyAddressIsDisable && setVerifyAddressIsDisable(true);
    showLatLong && setShowLatLong(false);
    let data = {
      inputAddress: {
        addressLine1: address?.address1?.value,
        addressLine2: address?.address2?.value ?? "",
        city: address?.city?.value,
        stateProvince: address?.state?.value?.erpStateCode,
        postalCode: address?.zip?.value,
        country: address?.country?.value?.code,
      },
    };
    let response = await validateAddress(data);
    if (response.status === 200 && response?.data?.message?.toLowerCase() === "success") {
      let responsedata = response?.data?.data,
        addressByPass = responsedata?.addressByPass,
        locationFound = responsedata?.locationFound,
        stdAddress = responsedata?.standardizedInfo?.standardizedAddress,
        userAddress = responsedata?.inputAddress,
        standardAddress = {
          address1: stdAddress?.addressLine1?.toLowerCase(),
          address2: stdAddress?.subLocation?.toLowerCase() ?? "",
          city: stdAddress?.city?.toLowerCase(),
          state: stdAddress?.stateProvince?.toLowerCase(),
          zip: stdAddress?.postalCodeBase?.toLowerCase(),
          country: stdAddress?.iso3CountryCode?.toLowerCase(),
        },
        userTypedAddress = {
          address1: userAddress?.addressLine1?.toLowerCase(),
          address2: userAddress?.addressLine2?.toLowerCase() ?? "",
          city: userAddress?.city?.toLowerCase(),
          state: userAddress?.stateProvince?.toLowerCase(),
          zip: userAddress?.postalCode?.toLowerCase(),
          country: userAddress?.country?.toLowerCase(),
        },
        isExactMatch = isEqual(standardAddress, userTypedAddress),
        latitude = stdAddress?.latLong?.latitude ?? "",
        longitude = stdAddress?.latLong?.longitude ?? "";
      setTimezone(responsedata?.location?.timezone ?? "");
      //setAddress((prevState) => ({ ...prevState, addressByPass, locationFound }));
      if (addressByPass === "YES") {
        setAddress((prevState) => ({ ...prevState, verified: true }));
        if (locationFound) {
          if (isExactMatch) {
            handleFields("Latitude", latitude);
            handleFields("Longitude", longitude);
          } else {
            address.country.value.code === "MEX" && setShowLatLong(true);
            address.country.value.code === "USA" &&
              notify(
                `Please note that the entered address is not verified and couldn't find
            a close match. However, you may continue with scheduling utilizing the entered address`,
                "warning"
              );
          }
        } else {
          address.country.value.code === "MEX" && setShowLatLong(true);
          notify(
            `Please note that the entered address is not verified and couldn't find
            a close match. However, you may continue with scheduling utilizing the entered address`,
            "warning"
          );
        }
      }
      // else {
      //   if (locationFound) {
      //     if (isExactMatch) {
      //       setAddress((prevState) => ({ ...prevState, verified: true }));
      //       handleFields("Latitude", latitude);
      //       handleFields("Longitude", longitude);
      //     } else {
      //       setSuggestionsIsShown(true);
      //       setSuggestedAddress({
      //         address: `${stdAddress?.addressLine1}, ${stdAddress?.subLocation ? stdAddress?.subLocation + ", " : ""}${stdAddress?.city}, ${
      //           stdAddress?.stateProvince
      //         }, ${stdAddress?.postalCodeBase}, ${stdAddress?.iso3CountryCode}`,
      //         address1: stdAddress?.addressLine1,
      //         address2: stdAddress?.subLocation,
      //         city: stdAddress?.city,
      //         state: stdAddress?.stateProvince,
      //         zip: stdAddress?.postalCodeBase,
      //         country: stdAddress?.iso3CountryCode,
      //         longitude,
      //         latitude,
      //       });
      //       setTypedAddress(
      //         `${userAddress?.addressLine1}, ${userAddress?.addressLine2 ? userAddress?.addressLine2 + ", " : ""}${userAddress?.city}, ${
      //           userAddress?.stateProvince
      //         }, ${userAddress?.postalCode}, ${userAddress?.country}`
      //       );
      //     }
      //   } else {
      //     notify("We couldn't verify the entered address and couldn't find a close match", "error");
      //   }
      // }
      setAddress((prevState) => ({ ...prevState, addressByPass, locationFound }));
    } else {
      setTimezone("");
      notify("Address validation failed due to an unexpected error", "error");
    }
    if (!address.verified) {
      setVerifyAddressIsDisable(false);
      address.historicalAddress.value.includes("Please") && setAddressFieldsIsDisable(false);
    }
    !address.historicalAddress.value.includes("Please") ? setIsValidatingHistoricalAddress(false) : setIsValidatingAddress(false);
    if (autoSchedulingCountries.includes(address?.country?.value?.code.toUpperCase())) {
      setScheduleType("AUTO");
    } else {
      setScheduleType("MANUAL");
    }
  };

  const handleAddressSelection = (verified = false) => {
    if (verified) {
      handleAddressFields("Address 1", suggestedAddress.address1);
      handleAddressFields("Address 2", suggestedAddress.address2);
      handleAddressFields("City", suggestedAddress.city);
      handleAddressFields("Country", suggestedAddress.country, suggestedAddress.state, suggestedAddress.zip);
      handleFields("Latitude", suggestedAddress.latitude);
      handleFields("Longitude", suggestedAddress.longitude);
    }
    setSuggestedAddress((prevState) => ({
      ...prevState,
      address: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      longitude: "",
      latitude: "",
    }));
    setTypedAddress("");
  };

  const handleFields = (field, value) => {
    switch (field) {
      case "Name": {
        if (value && value.length) {
          setName({ value, error: false, errorText: "Enter name", success: true });
        } else {
          setName({ value: "", error: true, errorText: "Enter name", success: false });
        }
        break;
      }
      case "Phone": {
        let phoneRegex = /^([^a-zA-Z])+$/;
        if (value && value.length) {
          if (phoneRegex.test(value)) {
            setPhone({ value, error: false, errorText: "Enter phone number", success: true });
          }
          // else {
          //   setPhone({ value, error: true, errorText: "Enter a valid phone number", success: false });
          // }
        } else {
          setPhone({ value: "", error: true, errorText: "Enter phone number", success: false });
        }
        break;
      }
      case "Email": {
        if (value && value.length) {
          if (EMAIL_REGEX_C360.test(value)) {
            setEmail({ value, error: false, errorText: "Enter email", success: true });
          } else {
            setEmail({ value, error: true, errorText: "Enter a valid email", success: false });
          }
        } else {
          setEmail({ value: "", error: true, errorText: "Enter email", success: false });
        }
        break;
      }
      case "CSP": {
        let cspAccDetails = cspDetails.cspDetails.filter((cspDetail) => cspDetail.CSPName === value)[0];
        setCsp((prevState) => ({
          ...prevState,
          value: { id: cspAccDetails.CSPID, name: cspAccDetails.CSPName, owner: cspAccDetails.CSPOwner },
          error: false,
        }));
        break;
      }
      case "Work Ticket Number": {
        if (value.length) {
          if (workTicketRegex.test(value)) {
            setWorkTicketNumber({ value, error: false, errorText: "Enter work ticket number", success: true });
          } else {
            setWorkTicketNumber({ value, error: true, errorText: "Enter a valid work ticket number", success: false });
          }
        } else {
          //For support cases work ticket number is optional
          // source.source?.toLowerCase() === "case"
          //   ? setWorkTicketNumber({ value: "", error: false, errorText: "Enter work ticket number", success: false })
          //   : setWorkTicketNumber({ value: "", error: true, errorText: "Enter work ticket number", success: false });
          setWorkTicketNumber({ value: "", error: false, errorText: "Enter work ticket number", success: false });
        }
        break;
      }
      case "Division": {
        value && value.length
          ? setDivision({ value, error: false, errorText: "Please Select Division", success: true })
          : setDivision({ value: "Please Select", error: true, errorText: "Please Select Division", success: false });
        break;
      }
      case "Distribution Channel": {
        value && value.length
          ? setDistributionChannel({ value, error: false, errorText: "Please Select Distribution Channel", success: true })
          : setDistributionChannel({ value: "Please Select", error: true, errorText: "Please Select Distribution Channel", success: false });
        break;
      }
      case "Start Date": {
        let date = moment(value, "MMMM DD,YYYY").format("MM/DD/YYYY");
        setStartDate((prevState) => ({ ...prevState, value: date, error: false, errorText: "Please Select End Date", success: true }));
        if (endDate.value && new Date(endDate.value) < new Date(value)) {
          setEndDate((prevState) => ({
            ...prevState,
            error: true,
            errorText: "End date should be start date and later",
            success: false,
          }));
        }
        break;
      }
      case "End Date": {
        if (value && value.length) {
          let date = moment(new Date(value), "MMMM DD,YYYY").format("MM/DD/YYYY");
          setEndDate((prevState) => ({ ...prevState, value: date, error: false, errorText: "Please Select End Date", success: true }));
        } else {
          setEndDate((prevState) => ({ ...prevState, value: "", error: true, errorText: "Please Select End Date", success: false }));
        }
        break;
      }
      case "Start Time": {
        value && value.length
          ? setStartTime({ value: value, error: false, errorText: "Please Select Start Time", success: true })
          : setStartTime({ value: "", error: true, errorText: "Please Select Start Time", success: false });
        break;
      }
      case "End Time": {
        value && value.length
          ? setEndTime({ value: value, error: false, errorText: "Please Select End Time", success: true })
          : setEndTime({ value: "", error: true, errorText: "Please Select End Time", success: false });
        break;
      }
      case "Vehicle Count": {
        value
          ? VEHICLECOUNT_REGEX.test(value) &&
            setVehicleCount((prevState) => ({ ...prevState, value, error: false, errorText: "Enter vehicle count", success: true }))
          : setVehicleCount((prevState) => ({ ...prevState, value: "", error: true, errorText: "Enter vehicle count", success: false }));
        break;
      }
      case "Latitude": {
        if (value && value.length) {
          if (isFinite(value) && Math.abs(value) <= 90) {
            setLatitude((prevState) => ({ ...prevState, value, error: false, errorText: "Enter latitude", success: true }));
          } else {
            setLatitude((prevState) => ({ ...prevState, value, error: true, errorText: "Enter a valid latitude", success: false }));
          }
        } else {
          setLatitude((prevState) => ({ ...prevState, value: "", error: false, errorText: "Enter latitude", success: false }));
        }
        break;
      }
      case "Longitude": {
        if (value && value.length) {
          if (isFinite(value) && Math.abs(value) <= 180) {
            setLongitude((prevState) => ({ ...prevState, value, error: false, errorText: "Enter longitude", success: true }));
          } else {
            setLongitude((prevState) => ({ ...prevState, value, error: true, errorText: "Enter a valid longitude", success: false }));
          }
        } else {
          setLongitude((prevState) => ({ ...prevState, value: "", error: false, errorText: "Enter longitude", success: false }));
        }
        break;
      }
      case "Existing PO": {
        if (value.length) {
          setExistingPO({ value, error: false, errorText: "Enter Existing PO", success: true });
        } else {
          setExistingPO({ value: "", error: false, errorText: "Enter Existing PO", success: false });
        }
        break;
      }
      case "Contract Account": {
        if (source.source === "case") {
          value && value.length
            ? setContractAccount({ value, error: false, errorText: "Please Enter Contract Account Number", success: true })
            : setContractAccount({ value: "", error: true, errorText: "Please Enter Contract Account Number", success: false });
        } else {
          value && value.length
            ? setContractAccount({ value, error: false, errorText: "Please Select Contract Account Number", success: true })
            : setContractAccount({ value: "", error: true, errorText: "Please Select Contract Account Number", success: false });
        }

        break;
      }
    }
  };

  const handleSubmit = () => {
    let minDate = scheduleType === "AUTO" ? handleStartDate() : moment().format("MM/DD/YYYY");
    let selectedDate = new Date(startDate.value) < new Date(minDate) ? minDate : startDate.value;
    // let selectedLatitude = "";
    // let selectedLongitude = "";
    // if (address.country.value === latitudeLongitudeEnabledCountry) {
    //   selectedLatitude = latitude.value || mexicoDefaultCoordinates.latitude;
    //   selectedLongitude = longitude.value || mexicoDefaultCoordinates.longitude;
    // } else {
    //   selectedLatitude = validatedLatitude;
    //   selectedLongitude = validatedLongitude;
    // }
    let cspCaseDetails = {
      assignedTo: null,
      assignedToEmail: null,
      assignedToEid: null,
    };
    if (source.source?.toLowerCase() === "case") {
      let caseData = caseDetails.caseDetails.length ? caseDetails.caseDetails.find((item) => item.DefaultCase === "1") : {};
      cspCaseDetails = {
        assignedTo: caseData?.CaseOwner || null,
        assignedToEmail: caseData?.CaseOwnerEmail || null,
        assignedToEid: caseData?.CaseOwnerEId || null,
      };
    } else {
      let cspData = cspDetails.cspDetails.length ? cspDetails.cspDetails.find((item) => item.DefaultCSP === "1") : {};
      cspCaseDetails = {
        assignedTo: cspData?.CSPOwner || null,
        assignedToEmail: cspData?.CSPOwnerEmail || null,
        assignedToEid: cspData?.CSPOwnerEId || null,
      };
    }
    let selectedScheduleType = "";
    if (autoSchedulingCountries.includes(address.country.value.code.toUpperCase()) && jobLengthHours.jobLengthHours > 10) {
      selectedScheduleType = "MANUAL";
    } else {
      selectedScheduleType = isExpandJob ? jobData?.scheduleType : scheduleType;
    }
    let data = {
      status: "OPEN",
      startDate: selectedDate,
      appointmentLength: jobLengthHours.jobLengthHours,
      estAppointmentLength: jobLengthMinutes.jobLengthMinutes,
      contactName: name.value,
      phone: phone.value,
      email: email.value,
      accountId: accountId.accountId,
      address1: address.address1.value,
      address2: address.address2.value ?? null,
      city: address.city.value,
      stateCode: address.state.value.erpStateCode,
      country: address.country.value.code,
      zipCode: address.zip.value,
      timezone: timezone,
      addressByPass: address.addressByPass,
      locationFound: address.locationFound,
      division: division.value ?? "",
      distributionChannel: distributionChannel.value ?? "",
      customerName: accountDetails.customerName,
      customerNumber: accountDetails.customerNumber,
      vztSegment: accountDetails.vztSegment,
      isDeleted: false,
      erpLastModifiedAt: new Date(),
      extendedJobId: isExpandJob ? jobData.id : null,
      latitude: latitude.value,
      longitude: longitude.value,
      scheduleType: selectedScheduleType,
      scheduleStartTime: "",
      scheduleEndDate: "",
      scheduleEndTime: "",
      erpBusinessPartnerId: accountDetails.erpBpNumber,
      providerOrder: source.source === "csp" ? accountDetails.providerOrder : "",
      erpTicketType: source.source === "csp" ? "ZINS" : "ZSRQ",
      assignToName: JSON.parse(sessionStorage.getItem("Name")),
      erpProductRecipientPartyId: accountDetails.erpBpNumber,
      salesOrg: jobData?.salesOrg?.split(" ")[0] ?? salesOrg.value.split(" ")[0],
      resellerBusinessPartnerNumber: jobData?.resellerBusinessPartnerNumber ?? accountDetails.resellerBusinessPartnerNumber,
      brand: jobData?.brand ?? accountDetails.accountBrand,
      createdAt: new Date(),
      createdBy: isNewJob ? accountDetails?.employeeFullName : null,
      createdByEmail: isNewJob ? accountDetails?.employeeEmail : null,
      createdByEid: isNewJob ? accountDetails?.employeeId : null,
      modifiedAt: new Date(),
      modifiedBy: isNewJob ? null : accountDetails?.employeeFullName,
      modifiedByEmail: isNewJob ? null : accountDetails?.employeeEmail,
      modifiedByEid: isNewJob ? null : accountDetails?.employeeId,
      assignedTo: cspCaseDetails?.assignedTo,
      assignedToEmail: cspCaseDetails?.assignedToEmail,
      assignedToEid: cspCaseDetails?.assignedToEid,
      jobDetails: [
        {
          cspSfdcId: csp.value.id,
          primaryDevice: null,
          addOns: isNewJob ? "updated" : "changed",
          vehicleCount: vehicleCount.value,
          jobType: jobType,
          opportunityId: null,
          cspOwner: csp.value.owner,
          cspName: csp.value.name,
          caseSfdcId: caseInfo.value.id,
          caseOwner: caseInfo.value.owner,
          caseNumber: caseInfo.value.number,
          modifiedAt: new Date(),
          modifiedBy: jobData && Object.keys(jobData).length ? accountDetails.sfdcUsername : "",
          createdAt: new Date(),
          createdBy: jobData && Object.keys(jobData).length ? "" : accountDetails.sfdcUsername,
          isDeleted: false,
          workTickets: [
            {
              workTicket: workTicketNumber.value,
            },
          ],
        },
      ],
      contractAccountId: contractAccount.value,
      crmReferenceId: source.source === "csp" ? cspDetails?.cspDetails[0]?.CRM_Ref_ID : caseDetails?.caseDetails[0]?.CaseNumber,
    };
    let newDurationDetails = [];
    let durationDetails = jobDurationDetails.jobDurationDetails;
    if (durationDetails.length) {
      if (isNewJob) {
        if (source.source === "csp") {
          durationDetails = jobDurationDetails.jobDurationDetails.filter((job) => job.techRefQuantity);
        } else {
          durationDetails = jobDurationDetails.jobDurationDetails.filter((job) => job.quantity);
        }
      } else {
        if (source.source === "csp") {
          durationDetails = jobDurationDetails.jobDurationDetails.filter(
            (job) =>
              existingJobQuantityList.find((oldJobData) => oldJobData.techRefId === job.techRefId && oldJobData.materialId === job.materialId) ||
              job.techRefQuantity
          );
        } else {
          if (
            accountDetails.accountBrand === "GSA" ||
            accountDetails.accountBrand === "Sourcewell" ||
            accountDetails.accountBrand === "NASPO" ||
            accountDetails.accountBrand === "AssetWorks"
          ) {
            durationDetails = jobDurationDetails.jobDurationDetails.filter(
              (job) => existingJobQuantityList.find((oldJobData) => oldJobData.materialId === job.materialId) || job.quantity
            );
          } else {
            durationDetails = jobDurationDetails.jobDurationDetails.filter(
              (job) =>
                existingJobQuantityList.find((oldJobData) => oldJobData.materialId === job.materialId) ||
                job.quantity ||
                job.processingCode === "ZBTP"
            );
          }
        }
      }

      if (
        accountDetails.accountBrand === "GSA" ||
        accountDetails.accountBrand === "Sourcewell" ||
        accountDetails.accountBrand === "NASPO" ||
        accountDetails.accountBrand === "AssetWorks"
      ) {
        durationDetails = durationDetails.map((job) => {
          return {
            id: isNewJob ? null : job.id,
            jobDurationId: null,
            quantity: source.source === "csp" ? job.techRefQuantity : job.quantity,
            materialLineId: job.materialLineId,
            processingCode: job.processingCode,
            orderType: job.orderType,
            techRefId: job.techRefId,
            unitMeasure: job.baseMeasureUnit,
            materialCode: job.materialId,
            itemDescription: job.materialName,
            contractId: job.wtMaterialContactId ? job.wtMaterialContactId : null,
            wtMaterialId: job.wtMaterialId ?? "",
            contractCode: job.sapId ? job.sapId : null,
            contractName: job.contractName?.length > 0 ? job?.contractName : null,
            customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            itemDuration: job.duration,
          };
        });
      } else {
        durationDetails = durationDetails.map((job) => {
          let obj = {
            id: isNewJob ? null : job.id,
            jobDurationId: null,
            quantity: source.source === "csp" ? job.techRefQuantity : job.quantity,
            materialLineId: job.materialLineId,
            processingCode: job.processingCode,
            orderType: job.orderType,
            techRefId: job.techRefId,
            unitMeasure: job.baseMeasureUnit,
            materialCode: job.materialId,
            itemDescription: job.materialName,
            contractId: job.wtMaterialContactId ? job.wtMaterialContactId : null,
            wtMaterialId: job.wtMaterialId ?? "",
            contractCode: job.sapId ? job.sapId : null,
            contractName: job.contractName?.length > 0 ? job?.contractName : null,
            customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            itemDuration: job.duration,
            reasonCode: null,
            adjustmentReasonCode: job.isBillCustomer ? null : job.reasonCode,
            linkedMaterialLineId: job.linkedMaterialLineId,
            customerServiceCharge: !isNewJob && job.processingCode === "ZBTP" ? job.stdCustomerFee : null,
          };

          newDurationDetails.push(obj);

          if (job.isCustomerBillable & (job.id === null)) {
            let newObj = {
              id: isNewJob ? null : job.id,
              jobDurationId: null,
              quantity: 0,
              materialLineId: job.materialLineId,
              processingCode: "ZBTP",
              orderType: job.orderType,
              techRefId: job.techRefId,
              unitMeasure: job.baseMeasureUnit,
              materialCode: job.materialId,
              itemDescription: job.materialName,
              contractId: job.wtMaterialContactId ? job.wtMaterialContactId : null,
              wtMaterialId: job.wtMaterialId ?? "",
              contractCode: job.sapId ? job.sapId : null,
              contractName: job.contractName?.length > 0 ? job?.contractName : null,
              customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
              customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
              itemDuration: job.duration,
              reasonCode: null,
              adjustmentReasonCode: job.isBillCustomer ? null : job.reasonCode,
              linkedMaterialLineId: job.linkedMaterialLineId,
              customerServiceCharge: job.stdCustomerFee,
            };
            newDurationDetails.push(newObj);
          }
        });
      }
    }

    if (
      accountDetails.accountBrand === "GSA" ||
      accountDetails.accountBrand === "Sourcewell" ||
      accountDetails.accountBrand === "NASPO" ||
      accountDetails.accountBrand === "AssetWorks"
    ) {
      data.jobDurationDetails = durationDetails;
    } else {
      data.jobDurationDetails = newDurationDetails;
    }

    let missingFieldsArray = [];
    for (const key of Object.keys(data)) {
      if (key === "crmReferenceId" || key === "distributionChannel" || key === "division") {
        if (data[key] === "" || data[key] === undefined || data[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to create job due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    }

    if (isNewJob) {
      handleJobCreation(data);
    } else {
      handleJobUpdate(data);
    }
  };

  const getJobDurationDetails = async (jobId) => {
    updateNewrelicStats();
    let jobItemsList = [];
    let salesOrgId = salesOrg.value.split(" ")[0];
    let sapId = source.source !== "case" ? uniq(products.products.map((product) => product.SAPProductID)).join() : null;
    let jobItemsResponse = await getJobDuration(jobId, salesOrgId, sapId);
    if (jobItemsResponse.data?.data?.length) {
      jobItemsList = jobItemsResponse.data.data
        .filter((job) => job.quantity)
        .map((job) => ({
          id: isExpandJob ? null : job.id,
          jobDurationId: null,
          quantity: job.quantity,
          materialLineId: job.materialLineId,
          processingCode: job.processingCode,
          orderType: job.orderType,
          techRefId: job.techRefId,
          unitMeasure: job.baseMeasureUnit,
          materialCode: job.materialId,
          itemDescription: job.materialName,
          contractId: job.wtMaterialContactId ? job.wtMaterialContactId : null,
          wtMaterialId: job.wtMaterialId ?? "",
          contractCode: job.sapId ? job.sapId : null,
          contractName: job.contractName?.length > 0 ? job?.contractName : null,
          customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
          customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
          itemDuration: job.duration,
        }));
    }
    return jobItemsList;
  };

  const getLineIdForJobDurationDetails = (jobDuration, action) => {
    if (action === "add") {
      let materialLineId = 0;
      let jobDurationDetailsWithLineId = jobDuration.map((job) => {
        let materialUniqueId;
        if (job?.quantity > 0) {
          materialLineId += 10;
          materialUniqueId = materialLineId.toString().padStart(6, "0");
        } else {
          if (
            accountDetails.accountBrand === "GSA" ||
            accountDetails.accountBrand === "Sourcewell" ||
            accountDetails.accountBrand === "NASPO" ||
            accountDetails.accountBrand === "AssetWorks"
          ) {
            materialUniqueId = null;
          } else {
            if ((job.processingCode === "ZBTP") & (job?.quantity === 0)) {
              materialLineId += 10;
              materialUniqueId = materialLineId.toString().padStart(6, "0");
            } else {
              materialUniqueId = null;
            }
          }
        }
        return {
          ...job,
          materialLineId: materialUniqueId,
        };
      });
      return jobDurationDetailsWithLineId;
    } else {
      let maxId = max(jobDuration.map((item) => (item.materialLineId ? Number(item.materialLineId) : 0)));
      let jobDurationDetailsWithLineId = jobDuration.map((job) => {
        let materialUniqueId;
        if (!job?.id) {
          maxId += 10;
          materialUniqueId = maxId.toString().padStart(6, "0");
        } else if (job?.quantity === 0) {
          // materialUniqueId = null;
          materialUniqueId = job?.materialLineId;
        } else {
          materialUniqueId = job?.materialLineId;
        }
        return {
          ...job,
          materialLineId: materialUniqueId,
        };
      });
      return jobDurationDetailsWithLineId;
    }
  };

  const handleJobCreation = async (data) => {
    updateNewrelicStats();
    setLoader(true);
    // if (!data.jobDurationDetails.length && isExpandJob) {
    //   data.jobDurationDetails = await getJobDurationDetails(jobData.id);
    // }
    if (data.jobDurationDetails.length) {
      let newJobDurationDetails = getLineIdForJobDurationDetails(data.jobDurationDetails, "add");

      if (
        accountDetails.accountBrand !== "GSA" &&
        accountDetails.accountBrand !== "Sourcewell" &&
        accountDetails.accountBrand !== "NASPO" &&
        accountDetails.accountBrand !== "AssetWorks"
      ) {
        newJobDurationDetails.forEach((item, index, array) => {
          if (index < array.length - 1) {
            const nextItem = array[index + 1];
            if (item.processingCode === "ZSPO" && nextItem.processingCode === "ZBTP") {
              item.linkedMaterialLineId = nextItem.materialLineId;
              nextItem.linkedMaterialLineId = item.materialLineId;
            }
          }
        });
      }

      data.jobDurationDetails = newJobDurationDetails;
      let response = await addJob(data);
      if (response.status === 200 && response?.data?.data?.id) {
        clearFields();
        notify("Job created successfully", "success");
        sessionData(response.data.data.id);
        setTimeout(() => {
          setNotification(null);
          setLoader(false);
          //if selected country other than USA, naviagte into manual schedule page
          if (data.scheduleType === "AUTO") {
            addPage(ScheduleJobMetadata.id, {
              jobData: { ...response.data.data, contractAccount: contractAccount.value, salesOrg: salesOrg.value, isNewJob: isNewJob },
              expandJobVendorID: isExpandJob ? jobData.vendorId : "",
              // mapUrl: getMapUrl(response.data.data, latitude, response.data.data.longitude),
              mapUrl: getMapUrl(
                accountDetails.mapUrl,
                response.data.data.latitude,
                response.data.data.longitude,
                accountDetails.customerNumber ?? ""
              ),
            });
          } else {
            addPage(AlternateManualEntryMetadata.id, {
              jobData: {
                ...response.data.data,
                salesOrg: salesOrg.value,
                contractAccount: contractAccount.value,
                isExpand: isExpandJob,
                manualVendorName: jobData?.manualVendorName, // installer name
                manualVendorId: jobData?.manualVendorId, // installer id
                installerId: jobData?.installerId, //erp vendor id
                totalMiles: jobData?.totalMiles,
                isNewJob: isNewJob,
              },
              mapUrl: getMapUrl(
                accountDetails.mapUrl,
                response.data.data.latitude,
                response.data.data.longitude,
                accountDetails.customerNumber ?? ""
              ),
            });
          }
        }, 5000);
      } else {
        setLoader(false);
        notify("Job creation failed due to an unexpected error", "error");
      }
    } else {
      setLoader(false);
      notify("Unable to fetch Job Duration Details due to an unexpected error", "error");
    }
  };

  const handleJobUpdate = async (data) => {
    updateNewrelicStats();
    setLoader(true);
    if (!data.jobDurationDetails.length) {
      data.jobDurationDetails = await getJobDurationDetails(jobData.id);
    }
    if (data.jobDurationDetails.length) {
      data.jobDetails[0].id = jobData.jobDetails[0].jobDetailId;
      let newJobDurationDetails = getLineIdForJobDurationDetails(data.jobDurationDetails, "update");

      if (
        accountDetails.accountBrand !== "GSA" &&
        accountDetails.accountBrand !== "Sourcewell" &&
        accountDetails.accountBrand !== "NASPO" &&
        accountDetails.accountBrand !== "AssetWorks"
      ) {
        newJobDurationDetails.forEach((item, index, array) => {
          if (index < array.length - 1) {
            const nextItem = array[index + 1];
            if (item.processingCode === "ZSPO" && nextItem.processingCode === "ZBTP") {
              item.linkedMaterialLineId = nextItem.materialLineId;
              nextItem.linkedMaterialLineId = item.materialLineId;
            }
          }
        });
      }

      data.jobDurationDetails = newJobDurationDetails;
      let response = await updateJob(jobData.id, data);
      if (response.status === 200 && response?.data?.data?.id) {
        clearFields();
        notify("Job updated successfully", "success");
        sessionData(response.data.data.id);
        setTimeout(() => {
          setNotification(null);
          setLoader(false);
          //if selected country other than USA, naviagte into manual schedule page
          if (data.scheduleType === "AUTO") {
            addPage(ScheduleJobMetadata.id, {
              jobData: { ...response.data.data, contractAccount: contractAccount.value, salesOrg: salesOrg.value, isNewJob: isNewJob },
              expandJobVendorID: "",
              mapUrl: getMapUrl(
                accountDetails.mapUrl,
                response.data.data.latitude,
                response.data.data.longitude,
                accountDetails.customerNumber ?? ""
              ),
            });
          } else {
            addPage(AlternateManualEntryMetadata.id, {
              jobData: {
                ...response.data.data,
                contractAccount: contractAccount.value,
                isExpand: isExpandJob,
                salesOrg: salesOrg.value,
                manualVendorName: jobData?.manualVendorName, // installer name
                manualVendorId: jobData?.manualVendorId, // installer id
                installerId: jobData?.installerId, //erp vendor id
                totalMiles: jobData?.totalMiles,
                isNewJob: isNewJob,
              },
              mapUrl: getMapUrl(
                accountDetails.mapUrl,
                response.data.data.latitude,
                response.data.data.longitude,
                accountDetails.customerNumber ?? ""
              ),
            });
          }
        }, 5000);
      } else {
        setLoader(false);
        notify("Job update failed due to an unexpected error", "error");
      }
    } else {
      setLoader(false);
      notify("Unable to fetch Job Duration Details due to an unexpected error", "error");
    }
  };

  const clearFields = () => {
    setExistingJobQuantityList([]);
    setName({ value: "", error: false, success: false, errorText: "Enter name" });
    setPhone({ value: "", error: false, success: false, errorText: "Enter phone" });
    setEmail({ value: "", error: false, success: false, errorText: "Enter email" });
    setCsp({ value: { id: "", name: "", owner: "" }, error: false, errorText: "Unable to fetch csp details due to an unexpected error" });
    setCaseInfo({ value: { id: "", number: "", owner: "" }, error: false, errorText: "Unable to fetch case details due to an unexpected error" });
    setWorkTicketNumber({ value: "", error: false, success: false, errorText: "Enter work ticket number" });
    setStartDate({ value: "", error: false, success: false, errorText: "Select start date" });
    setEndDate({ value: "", error: false, success: false, errorText: "Select end date" });
    setStartTime({ value: "", error: false, success: false, errorText: "Select start time" });
    setEndTime({ value: "", error: false, success: false, errorText: "Select end time" });
    setVehicleCount({ value: "", error: false, success: false, errorText: "Enter vehicle count" });
    jobDurationDetails.setJobDurationDetails([]);
    jobLengthHours.setJobLengthHours(0);
    jobLengthMinutes.setJobLengthMinutes(0);
    setAddress((prevState) => ({
      verified: false,
      addressByPass: null,
      locationFound: null,
      historicalAddress: { value: "", error: false, errorText: "", success: false },
      address1: { value: "", error: false, errorText: "Enter valid street address", success: false },
      address2: { value: "", error: false, errorText: "Enter address 2", success: false },
      city: { value: "", error: false, errorText: "Enter city", success: false },
      state: { ...prevState.state, value: null, options: [], error: false, errorText: "Please Select State", success: false },
      zip: { value: "", error: false, errorText: "Enter zip code", success: false },
      country: { ...prevState.country, value: null, error: false, errorText: "Please select country", success: false },
    }));
    setTimezone("");
    setLatitude({
      value: "",
      error: false,
      errorText: "Enter latitude",
      success: false,
    });
    setLongitude({
      value: "",
      error: false,
      errorText: "Enter longitude",
      success: false,
    });
  };

  // const handleSchFeesModal = (state) => {
  //   let showModal =
  //     chargeableStates?.chargeableStates &&
  //     chargeableStates?.chargeableStates.length !== 0 &&
  //     chargeableStates?.chargeableStates.find((item) => item === state) !== undefined;
  //   showModal && setSchedulingFeesModal(<SchedulingFeesModal handleCancel={() => resetTab()} handleConfirm={() => setSchedulingFeesModal(null)} />);
  // };

  let emptyMaterialLineId = [],
    emptyMaterialId = [],
    emptyItemDescription = [],
    emptyQuantity = [],
    emptyProcessingCode = [],
    emptyLineItemUniqueId = [];

  const addServicesJob = async () => {
    updateNewrelicStats();
    let cspAccDetails = JSON.parse(sessionStorage.getItem("CSPDetails"));
    let durationDetails = jobDurationDetails.jobDurationDetails;
    if (durationDetails.length) {
      if (isNewJob) {
        if (source.source === "csp") {
          durationDetails = jobDurationDetails.jobDurationDetails.filter((job) => job.techRefQuantity);
        } else {
          durationDetails = jobDurationDetails.jobDurationDetails.filter((job) => job.quantity);
        }
      } else {
        if (source.source === "csp") {
          durationDetails = jobDurationDetails.jobDurationDetails.filter(
            (job) =>
              existingJobQuantityList.find((oldJobData) => oldJobData.techRefId === job.techRefId && oldJobData.materialId === job.materialId) ||
              job.techRefQuantity
          );
        } else {
          durationDetails = jobDurationDetails.jobDurationDetails.filter(
            (job) => existingJobQuantityList.find((oldJobData) => oldJobData.materialId === job.materialId) || job.quantity
          );
        }
      }
    }
    let materialLineId = max(jobData.jobDurationDetails.map((job) => (job.materialLineId ? Number(job.materialLineId) : 0)));
    let data = {};
    if (
      accountDetails.accountBrand === "GSA" ||
      accountDetails.accountBrand === "Sourcewell" ||
      accountDetails.accountBrand === "NASPO" ||
      accountDetails.accountBrand === "AssetWorks"
    ) {
      durationDetails = durationDetails.map((job) => {
        let materialUniqueId;
        materialLineId += 10;
        materialUniqueId = materialLineId.toString().padStart(6, "0");
        return {
          ...job,
          materialLineId: materialUniqueId,
        };
      });
      data = {
        serviceJobId: jobData.serviceJobId,
        jobBatchId: `${jobData.id}_${Date.now()}`,
        serviceAddress: {
          lastName: cspAccDetails?.lastName || "",
          firstName: cspAccDetails?.firstName || "",
          address1: jobData.addressOne,
          address2: jobData.addressTwo ?? null,
          city: jobData.city,
          state: jobData.stateCode,
          postalCode: jobData.zipCode,
          country: jobData.country,
        },
        documentType: source.source === "csp" ? "ZINS" : "ZSRQ",
        salesOrg: salesOrg.value.split(" ")[0] ?? jobData?.salesOrg?.split(" ")[0],
        currencyCode: accountDetails?.currencyCode ?? "",
        distributionChannel: distributionChannel.value ?? "",
        division: division.value ?? "",
        crmReferenceId: source.source === "csp" ? cspDetails?.cspDetails[0]?.CRM_Ref_ID : caseDetails?.caseDetails[0]?.CaseNumber,
        contractAccount: contractAccount.value,
        erpVendorId: jobData.installerId,
        assignedTo: accountDetails?.employeeId ? accountDetails.employeeId : "",
        vendorMessage: jobData.vendorMessages || "",
        subStatus: "",
        oldDeliveryNo: "",
        erpStatus: jobData.erpStatus || "",
        erpSubStatus: jobData.erpSubStatus || "",
        soldToParty: accountDetails?.erpBpNumber ? accountDetails.erpBpNumber : "",
        shipToParty: accountDetails?.erpBpNumber ? accountDetails.erpBpNumber : "",
        resellerBPNumber: jobData?.resellerBusinessPartnerNumber ?? accountDetails.resellerBusinessPartnerNumber,
        jobVendorScheduleId: jobData.jobVendorScheduleId || "",
        startDate: jobData.scheduledDate,
        startTime: jobData.scheduledTime,
        endDate: jobData.scheduleEndDate || null,
        endTime: jobData.scheduleEndTime || null,
        modifiedAt: new Date(),
        modifiedBy: accountDetails?.employeeFullName,
        modifiedByEmail: accountDetails?.employeeEmail,
        modifiedByEid: accountDetails?.employeeId,
        partners: [
          { party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "", partRoleCode: "AG" },
          { party: accountDetails?.erpBpNumber ?? "", partRoleCode: "WE" },
          {
            party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "",
            partRoleCode: "Z6",
            accountName: accountDetails?.customerName ? accountDetails.customerName : "",
            contactName: name?.value ? name?.value : "",
            contactPhone: phone?.value ? phone?.value : "",
            contactEmail: email?.value ? email?.value : "",
            street: jobData.addressOne,
            city: jobData.city,
            state: jobData.stateCode,
            country: jobData.country,
            zip: jobData.zipCode,
            telephone: "",
          },
        ],
        itemDetails: durationDetails.map((job) => {
          return {
            id: null,
            materialLineId: job.materialLineId,
            materialId: job.materialId,
            itemDescription: job.materialName,
            itemDuration: job.duration,
            quantity: source.source === "csp" ? job.techRefQuantity : job.quantity,
            unitMeasure: job.baseMeasureUnit,
            techRefId: job.techRefId,
            processingCode: job.processingCode,
            lineItemUniqueId: jobData.id + "_" + job.materialLineId,
            customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            baseItemId: "",
            esnToBeReturned: "",
            installedQuantity: "",
            manualAdjustments: 0,
            rhiInstalledQuantity: 0,
            customerCharge: "",
            contractId: job.wtMaterialContactId ? job.wtMaterialContactId : null,
            wtMaterialId: job.wtMaterialId ?? "",
            materialContractId: job.sapId ? job.sapId : null,
            contractName: job.contractName?.length > 0 ? job?.contractName : null,
            orderType: job.orderType ?? "",
          };
        }),
      };
    } else {
      let newDurationDetails = [];
      durationDetails.map((job) => {
        let obj = {
          id: null,
          materialLineId: job.materialLineId,
          materialId: job.materialId,
          itemDescription: job.materialName,
          itemDuration: job.duration,
          quantity: source.source === "csp" ? job.techRefQuantity : job.quantity,
          unitMeasure: job.baseMeasureUnit,
          techRefId: job.techRefId,
          processingCode: job.processingCode,
          lineItemUniqueId: jobData.id + "_" + job.materialLineId,
          customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
          customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
          baseItemId: "",
          esnToBeReturned: "",
          installedQuantity: "",
          manualAdjustments: 0,
          rhiInstalledQuantity: 0,
          customerCharge: "",
          contractId: job.wtMaterialContactId ? job.wtMaterialContactId : null,
          wtMaterialId: job.wtMaterialId ?? "",
          materialContractId: job.sapId ? job.sapId : null,
          contractName: job.contractName?.length > 0 ? job?.contractName : null,
          orderType: job.orderType ?? "",
          reasonCode: null,
          adjustmentReasonCode: job.isBillCustomer ? null : job.reasonCode,
          linkedMaterialLineId: job.linkedMaterialLineId,
          customerServiceCharge: null,
        };

        newDurationDetails.push(obj);

        if (job.isCustomerBillable) {
          let newObj = {
            id: null,
            materialLineId: job.materialLineId,
            materialId: job.materialId,
            itemDescription: job.materialName,
            itemDuration: job.duration,
            quantity: 0,
            unitMeasure: job.baseMeasureUnit,
            techRefId: job.techRefId,
            processingCode: "ZBTP",
            lineItemUniqueId: jobData.id + "_" + job.materialLineId,
            customerPONumber: source.source === "csp" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            customerPONumberKut: source.source === "case" ? cspDetails?.cspDetails[0]?.Provider_Order : null,
            baseItemId: "",
            esnToBeReturned: "",
            installedQuantity: "",
            manualAdjustments: 0,
            rhiInstalledQuantity: 0,
            customerCharge: "",
            contractId: job.wtMaterialContactId ? job.wtMaterialContactId : null,
            wtMaterialId: job.wtMaterialId ?? "",
            materialContractId: job.sapId ? job.sapId : null,
            contractName: job.contractName?.length > 0 ? job?.contractName : null,
            orderType: job.orderType ?? "",
            reasonCode: null,
            adjustmentReasonCode: job.isBillCustomer ? null : job.reasonCode,
            linkedMaterialLineId: job.linkedMaterialLineId,
            customerServiceCharge: job.stdCustomerFee,
          };
          newDurationDetails.push(newObj);
        }
      });

      newDurationDetails = newDurationDetails.map((job) => {
        let materialUniqueId;
        materialLineId += 10;
        materialUniqueId = materialLineId.toString().padStart(6, "0");
        return {
          ...job,
          materialLineId: materialUniqueId,
          lineItemUniqueId: jobData.id + "_" + materialUniqueId,
        };
      });

      newDurationDetails.forEach((item, index, array) => {
        if (index < array.length - 1) {
          const nextItem = array[index + 1];
          if (item.processingCode === "ZSPO" && nextItem.processingCode === "ZBTP") {
            item.linkedMaterialLineId = nextItem.materialLineId;
            nextItem.linkedMaterialLineId = item.materialLineId;
          }
        }
      });

      data = {
        serviceJobId: jobData.serviceJobId,
        jobBatchId: `${jobData.id}_${Date.now()}`,
        serviceAddress: {
          lastName: cspAccDetails?.lastName || "",
          firstName: cspAccDetails?.firstName || "",
          address1: jobData.addressOne,
          address2: jobData.addressTwo ?? null,
          city: jobData.city,
          state: jobData.stateCode,
          postalCode: jobData.zipCode,
          country: jobData.country,
        },
        documentType: source.source === "csp" ? "ZINS" : "ZSRQ",
        salesOrg: salesOrg.value.split(" ")[0] ?? jobData?.salesOrg?.split(" ")[0],
        currencyCode: accountDetails?.currencyCode ?? "",
        distributionChannel: distributionChannel.value ?? "",
        division: division.value ?? "",
        crmReferenceId: source.source === "csp" ? cspDetails?.cspDetails[0]?.CRM_Ref_ID : caseDetails?.caseDetails[0]?.CaseNumber,
        contractAccount: contractAccount.value,
        erpVendorId: jobData.installerId,
        assignedTo: accountDetails?.employeeId ? accountDetails.employeeId : "",
        vendorMessage: jobData.vendorMessages || "",
        subStatus: "",
        oldDeliveryNo: "",
        erpStatus: jobData.erpStatus || "",
        erpSubStatus: jobData.erpSubStatus || "",
        soldToParty: accountDetails?.erpBpNumber ? accountDetails.erpBpNumber : "",
        shipToParty: accountDetails?.erpBpNumber ? accountDetails.erpBpNumber : "",
        resellerBPNumber: jobData?.resellerBusinessPartnerNumber ?? accountDetails.resellerBusinessPartnerNumber,
        jobVendorScheduleId: jobData.jobVendorScheduleId || "",
        startDate: jobData.scheduledDate,
        startTime: jobData.scheduledTime,
        endDate: jobData.scheduleEndDate || null,
        endTime: jobData.scheduleEndTime || null,
        modifiedAt: new Date(),
        modifiedBy: accountDetails?.employeeFullName,
        modifiedByEmail: accountDetails?.employeeEmail,
        modifiedByEid: accountDetails?.employeeId,
        partners: [
          { party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "", partRoleCode: "AG" },
          { party: accountDetails?.erpBpNumber ?? "", partRoleCode: "WE" },
          {
            party: accountDetails?.resellerBusinessPartnerNumber || accountDetails?.erpBpNumber || "",
            partRoleCode: "Z6",
            accountName: accountDetails?.customerName ? accountDetails.customerName : "",
            contactName: name?.value ? name?.value : "",
            contactPhone: phone?.value ? phone?.value : "",
            contactEmail: email?.value ? email?.value : "",
            street: jobData.addressOne,
            city: jobData.city,
            state: jobData.stateCode,
            country: jobData.country,
            zip: jobData.zipCode,
            telephone: "",
          },
        ],
        itemDetails: newDurationDetails,
      };
    }

    let errorMsg = [];
    let missingFieldsArray = [];
    for (const key of Object.keys(data)) {
      if (
        key === "serviceJobId" ||
        key === "jobBatchId" ||
        key === "salesOrg" ||
        key === "currencyCode" ||
        key === "distributionChannel" ||
        key === "division" ||
        key === "crmReferenceId" ||
        key === "erpVendorId" ||
        key === "contractAccount" ||
        key === "assignedTo"
      ) {
        if (data[key] === "" || data[key] === undefined || data[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    for (const key of Object.keys(data.serviceAddress)) {
      if (key === "address1" || key === "city" || key === "state" || key === "postalCode" || key === "country") {
        if (data.serviceAddress[key] === "" || data.serviceAddress[key] === undefined || data.serviceAddress[key] === null) {
          missingFieldsArray.push(key);
        }
      }
    }
    if (missingFieldsArray.length > 0) {
      return setTimeout(() => {
        notify(
          "Unable to add the services due to missing information. Kindly contact IT team for support. Missing information: " +
            missingFieldsArray.toString() +
            ".",
          "error",
          false
        );
      }, 10);
    } else if (accountDetails.resellerBusinessPartnerNumber === null && accountDetails.erpBpNumber === null) {
      return setTimeout(() => {
        notify(
          "Unable to add the services due to missing mandatory information (Mandatory: Reseller Business Partner Number). Kindly contact IT team for support",
          "error",
          false
        );
      }, 10);
    } else {
      data?.itemDetails?.filter((items) => {
        for (const key of Object.keys(items)) {
          if (
            key === "materialLineId" ||
            key === "materialId" ||
            key === "itemDescription" ||
            key === "quantity" ||
            key === "processingCode" ||
            key === "lineItemUniqueId"
          ) {
            if (items[key] === "" || items[key] === null || items[key] === undefined) {
              captureMaterialId(key, items.materialId);
            }
          }
        }
      });
      if (emptyMaterialId.length > 0) errorMsg.push(` ${"Material Id"} is missing for ${emptyMaterialId.toString()}`);
      if (emptyMaterialLineId.length > 0) errorMsg.push(` ${"Material Line Id"} is missing for ${emptyMaterialLineId.toString()}`);
      if (emptyItemDescription.length > 0) errorMsg.push(` ${"Item Description"} is missing for ${emptyItemDescription.toString()}`);
      if (emptyQuantity.length > 0) errorMsg.push(` ${"Quantity"} is missing for ${emptyQuantity.toString()}`);
      if (emptyProcessingCode.length > 0) errorMsg.push(` ${"Processing Code"} is missing for ${emptyProcessingCode.toString()}`);
      if (emptyLineItemUniqueId.length > 0) errorMsg.push(` ${"LineItem Unique Id"} is missing for ${emptyLineItemUniqueId.toString()}`);

      if (errorMsg.length > 0) {
        return setTimeout(() => {
          notify(
            <>
              {"Unable to add the services due to below missing information. Kindly contact IT team for support. Missing Information: "}
              {errorMsg.map((msg) => (
                <div key={generate()}>{msg}</div>
              ))}
            </>,
            "error",
            false
          );
        }, 10);
      }
    }
    setLoader(true);
    let response = await addServiceJob(jobData.id, data);
    if (response?.status === 200 && response?.data?.message?.toLowerCase() === "success") {
      clearFields();
      notify("Added services successfully", "success");
      sessionData(jobData.id);
      setTimeout(() => {
        setNotification(null);
        setLoader(false);
        switchTab(2, { data: { jobId: jobData.id } });
      }, 5000);
    } else {
      setLoader(false);
      notify("Add services failed due to an unexpected error", "error");
    }
  };

  const captureMaterialId = (materialName, materialId) => {
    switch (materialName) {
      case "materialLineId": {
        emptyMaterialLineId.push(materialId);
        break;
      }
      case "materialId": {
        emptyMaterialId.push(materialId);
        break;
      }
      case "itemDescription": {
        emptyItemDescription.push(materialId);
        break;
      }
      case "quantity": {
        emptyQuantity.push(materialId);
        break;
      }
      case "processingCode": {
        emptyProcessingCode.push(materialId);
        break;
      }
      case "lineItemUniqueId": {
        emptyLineItemUniqueId.push(materialId);
        break;
      }
    }
  };

  return (
    <>
      {source.source === "case" && caseJobLengthModal && (
        <CaseJobLengthModal
          caseJobLengthModal={caseJobLengthModal}
          setCaseJobLengthModal={setCaseJobLengthModal}
          setApptLength={setApptLength}
          jobId={jobData?.id ?? null}
          isNewJob={isNewJob}
          salesOrgId={salesOrg.value.split(" ")[0]}
          setExistingJobQuantityList={setExistingJobQuantityList}
          notification={(content) => {
            notify(content, "error");
          }}
          isAddServices={isAddServices}
          scheduleType={scheduleType}
        />
      )}
      {source.source === "csp" && cspJobLengthModal && (
        <CSPJobLengthModal
          cspJobLengthModal={cspJobLengthModal}
          setCSPJobLengthModal={setCSPJobLengthModal}
          setApptLength={setApptLength}
          jobId={jobData?.id ?? null}
          isNewJob={isNewJob}
          salesOrgId={salesOrg.value.split(" ")[0]}
          setExistingJobQuantityList={setExistingJobQuantityList}
          notification={(content) => {
            notify(content, "error");
          }}
          isAddServices={isAddServices}
          scheduleType={scheduleType}
        />
      )}

      <Loader active={isLoading} fullscreen />
      <Header role="main" ariaLabel="Job Details">
        Job Details
      </Header>
      {notification}
      <CustomerDetails />
      <FlexRow>
        <SmallText bold css={{ marginBottom: "0" }}>
          {source.source === "case" ? "Support Agent: " : "CSP Owner Name: "}&nbsp;
        </SmallText>
        <SmallText> {source.source === "case" ? caseInfo.value.owner || "--" : csp.value.owner || "--"}</SmallText>
      </FlexRow>
      {jobData?.isC4SWorkTicket && (
        <>
          <FlexRow>
            <SmallText bold css={{ marginBottom: "0" }}>
              Distribution Channel: &nbsp;
            </SmallText>
            <SmallText>{getFullName(DISTRIBUTION_CHANNEL_DROPDOWN_VALUES, jobData.distributionChannel) || "--"}</SmallText>
          </FlexRow>
          <FlexRow>
            <SmallText bold css={{ marginBottom: "0" }}>
              Division: &nbsp;
            </SmallText>
            <SmallText>{getFullName(DIVISION_DROPDOWN_VALUES, jobData.division) || "--"}</SmallText>
          </FlexRow>
        </>
      )}
      <UltraMediumText css={{ marginTop: "1.25rem" }} eDSFont fontSize="18px" marginBottom="0.7rem">
        Installation Address
      </UltraMediumText>
      <FlexRow>
        <div css={HistoricalAddressRow}>
          <CustomDropdown
            type="text"
            ariaLabel={
              address.historicalAddress.value ? address.historicalAddress.value : "Please select an address or choose Add New to add a new address"
            }
            disabled={
              isExpandJob ||
              !address.country.options.length ||
              !address.state.allStateOptions ||
              isValidatingAddress ||
              isValidatingHistoricalAddress ||
              isAddServices
            }
            errorText={address.historicalAddress.errorText}
            error={false}
            success={true}
            value={
              address.historicalAddress.value ? address.historicalAddress.value : "Please select an address or choose Add New to add a new address"
            }
            onChange={(event) => handleHistoricalAddressSelection(event.target.value)}
          >
            {historicalAddressDetails.historicalAddressDetails.length > 0 ? (
              <>
                <option disabled>Please select an address or choose Add New to add a new address</option>
                {historicalAddressDetails.historicalAddressDetails.map((address) => (
                  <option key={generate()}>{address}</option>
                ))}
                <option>Add New</option>
              </>
            ) : (
              <>
                <option disabled>Please select an address or choose Add New to add a new address</option>
                <option disabled>No Address Available</option>
                <option>Add New</option>
              </>
            )}
          </CustomDropdown>
        </div>
        {isValidatingHistoricalAddress && (
          <FlexColumn css={CustomHistoricalLoader}>
            <Loader active fullscreen={false} />
            <SmallText bold css={{ paddingLeft: "2rem" }}>
              Verifying address...
            </SmallText>
          </FlexColumn>
        )}
      </FlexRow>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomInput
            type="text"
            label="Address 1"
            aria-label="Address 1"
            disabled={addressFieldsIsDisable}
            error={address.address1.error}
            errorText={address.address1.errorText}
            success={address.address1.success}
            value={address.address1.value}
            onChange={(event) => handleAddressFields("Address 1", event.target.value)}
            onBlur={(e) => handleAddressFields("Address 1", e.target.value?.trim(), false, false, false, false, true)}
          />
        </div>
      </FlexRow>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomInput
            type="text"
            label="Address 2 (Optional)"
            aria-label="Address 2 (Optional)"
            disabled={addressFieldsIsDisable}
            error={address.address2.error}
            errorText={address.address2.errorText}
            success={address.address2.success}
            value={address.address2.value}
            onChange={(event) => handleAddressFields("Address 2", event.target.value)}
            onBlur={(e) => handleAddressFields("Address 2", e.target.value?.trim(), false, false, false, false, true)}
          />
        </div>
      </FlexRow>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomInput
            width="100%"
            type="text"
            label="City"
            araia-label="City"
            paddingRight="5px"
            disabled={addressFieldsIsDisable}
            error={address.city.error}
            errorText={address.city.errorText}
            success={address.city.success}
            value={address.city.value}
            onChange={(event) => handleAddressFields("City", event.target.value)}
            onBlur={(e) => handleAddressFields("City", e.target.value?.trim(), false, false, false, false, true)}
          />
        </div>
      </FlexRow>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomDropdown
            width="100%"
            type="text"
            label="Country"
            aria-label="Select Country"
            paddingRight="5px"
            error={address.country.error}
            errorText={address.country.errorText}
            success={address.country.success}
            value={address.country.value?.code || "Please Select"}
            disabled={addressFieldsIsDisable}
            hideErrorImg={address.country.error}
            onChange={(e) => handleAddressFields("Country", e.target.value, false, false)}
          >
            <>
              <option disabled>Please Select</option>
              {address.country.options.map((country) => (
                <option key={country.code}>{country.code}</option>
              ))}
            </>
          </CustomDropdown>
        </div>
      </FlexRow>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomDropdown
            width="100%"
            paddingRight="5px"
            type="text"
            label="State"
            disabled={addressFieldsIsDisable || !address.country.value}
            hideErrorImg={address.state.error}
            error={address.state.error}
            errorText={address.state.errorText}
            value={address.state.value?.stateName || "Please Select"}
            onChange={(event) => handleAddressFields("State", event.target.value.split(" - ")[0], address.state.options)}
          >
            <>
              <option disabled style={{ backgroundColor: "lightgrey" }}>
                Please Select
              </option>
              {address.state.options.map((stateCode) => (
                <option key={stateCode.stateCode + stateCode.countryCode}>{stateCode.stateName}</option>
              ))}
            </>
          </CustomDropdown>
        </div>
      </FlexRow>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomInput
            width="100%"
            type="text"
            label="Zip/Postal Code"
            aria-label="Zip"
            error={address.zip.error}
            errorText={address.zip.errorText}
            success={address.zip.success}
            value={address.zip.value}
            onChange={(event) => handleAddressFields("Zip", event.target.value, zipRegex)}
            disabled={addressFieldsIsDisable || !address.country.value}
            maxLength={zipCodeLimit}
            onBlur={(e) => handleAddressFields("Zip", e.target.value?.trim(), zipRegex, false, false, false, true)}
          />
        </div>
      </FlexRow>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          {(!addressFieldsIsDisable || isValidatingAddress) && !address.verified && (
            <CustomSLButton
              aria-label="Verify address"
              role="button"
              disabled={verifyAddressIsDisable}
              aria-disabled={verifyAddressIsDisable}
              onClick={() => handleAddressVerification(address)}
            >
              Verify address
            </CustomSLButton>
          )}
          {isValidatingAddress && (
            <FlexColumn css={CustomLoader}>
              <Loader active fullscreen={false} />
              <SmallText bold css={{ paddingLeft: "2rem" }}>
                Verifying address...
              </SmallText>
            </FlexColumn>
          )}
        </div>
      </FlexRow>
      {suggestionsIsShown && (
        <>
          <UltraMediumText eDSFont fontSize="18px" marginBottom="0" marginTop="1rem">
            Choose an option
          </UltraMediumText>
          <SmallText>We couldn&apos;t verify the entered address. We found a close match</SmallText>
          <RadioButtonGroupWrapper>
            <RadioButtonGroup
              onChange={() => handleAddressSelection(true)}
              error={false}
              data={[
                {
                  name: "group",
                  label: (
                    <>
                      <span>Use suggested address</span>
                      <mark css={{ fontSize: "10px", backgroundColor: "#d8dada", padding: "0rem 0.313rem", marginLeft: "0.313rem" }}>Verified</mark>
                    </>
                  ),
                  children: suggestedAddress.address,
                  value: "radioOne",
                  ariaLabel: "Use suggested address",
                },
              ]}
            />
            <RadioButtonGroup
              onChange={() => handleAddressSelection()}
              error={false}
              data={[
                {
                  name: "group",
                  label: (
                    <>
                      <span>Use address as typed</span>
                    </>
                  ),
                  children: typedAddress,
                  value: "radioTwo",
                  ariaLabel: "Use address as typed",
                },
              ]}
            />
          </RadioButtonGroupWrapper>
        </>
      )}
      {showLatLong && (
        <>
          <FlexRow css={{ marginTop: "1.25rem" }}>
            <div css={HistoricalAddressRow}>
              <CustomInput
                width="100%"
                type="text"
                label={`Latitude (Optional)`}
                aria-label={`Latitude (Optional)`}
                paddingRight="5px"
                disabled={!address.verified}
                error={latitude.error}
                errorText={latitude.errorText}
                success={latitude.success}
                value={latitude.value}
                onChange={(event) => handleFields("Latitude", event.target.value)}
              />
            </div>
          </FlexRow>
        </>
      )}
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomInput
            width="100%"
            marginBottom="0.6rem"
            paddingRight="5px"
            type="text"
            label={`Longitude (Optional)`}
            aria-label={`Longitude (Optional)`}
            error={longitude.error}
            errorText={longitude.errorText}
            success={longitude.success}
            value={longitude.value}
            disabled={!address.verified}
            onChange={(e) => handleFields("Longitude", e.target.value)}
          />
        </div>
      </FlexRow>
      <UltraMediumText eDSFont fontSize="18px" marginBottom="0" marginTop="2rem">
        Contact Details
      </UltraMediumText>
      <SmallText>Customer contact details for install address</SmallText>
      <FlexRow css={{ marginTop: "1.25rem" }}>
        <div css={HistoricalAddressRow}>
          <CustomInput
            width="85%"
            type="text"
            label="Contact Name"
            aria-label="Contact Name"
            disabled={isFieldsDisabled}
            error={name.error}
            errorText={name.errorText}
            success={name.success}
            value={name.value}
            maxLength={50}
            onChange={(event) => handleFields("Name", event.target.value)}
          />
          <CustomInput
            width="75%"
            type="text"
            label="Phone"
            aria-label="Phone"
            disabled={isFieldsDisabled}
            error={phone.error}
            errorText={phone.errorText}
            success={phone.success}
            value={phone.value}
            onChange={(event) => handleFields("Phone", event.target.value)}
          />
          <CustomInput
            width="85%"
            type="text"
            label="Email"
            aria-label="Email"
            disabled={isFieldsDisabled}
            error={email.error}
            errorText={email.errorText}
            success={email.success}
            value={email.value}
            onChange={(event) => handleFields("Email", event.target.value)}
          />
          <UltraMediumText eDSFont fontSize="18px" marginBottom="0.4rem" marginTop="2.2rem">
            Job Details
          </UltraMediumText>
          {source.source === "case" ? (
            <>
              <SmallText color={isFieldsDisabled ? "#d8dada" : ""} css={{ marginTop: "0.8rem" }}>
                Case
              </SmallText>
              <SmallText color={isFieldsDisabled ? "#d8dada" : ""} css={{ marginRight: "1rem", marginBottom: "0.8rem" }}>
                {caseInfo.value.number || "N/A"}
              </SmallText>
            </>
          ) : (
            <CustomDropdown
              width="61%"
              type="text"
              label="CSP"
              aria-label="CSP"
              marginBottom="0.3rem"
              disabled={isFieldsDisabled}
              disableValue={!csp.value.name}
              error={csp.error}
              errorText={csp.errorText}
              value={csp.value.name ? csp.value.name : "Please select"}
              onChange={(event) => handleFields("CSP", event.target.value)}
            >
              <>
                {cspDetails.cspDetails.length ? (
                  cspDetails.cspDetails.sort().map((item) => (
                    <option key={item.CSPName} value={item.CSPName}>
                      {item.CSPName}
                    </option>
                  ))
                ) : (
                  <option disabled style={{ backgroundColor: "lightgrey" }}>
                    Please select
                  </option>
                )}
              </>
            </CustomDropdown>
          )}
          <CustomInput
            width="85%"
            type="text"
            aria-label={`Work Ticket Number (Optional)`}
            label={`Work Ticket Number (Optional)`}
            disabled={isFieldsDisabled}
            error={workTicketNumber.error}
            errorText={workTicketNumber.errorText}
            success={workTicketNumber.success}
            value={workTicketNumber.value}
            onChange={(event) => handleFields("Work Ticket Number", event.target.value)}
          />
          {salesOrg.options.length > 1 ? (
            <CustomDropdown
              width="61%"
              type="text"
              label="Sales Org"
              aria-label="Sales Org"
              marginBottom="0.3rem"
              disabled={isFieldsDisabled}
              error={salesOrg.error}
              errorText={salesOrg.errorText}
              value={salesOrg.value ? salesOrg.value : "Please select"}
              onChange={(event) => handleSalesOrg(event.target.value)}
            >
              <>
                <option disabled style={{ backgroundColor: "lightgrey" }}>
                  Please select
                </option>
                {salesOrg.options.length &&
                  salesOrg.options.map((salesOrg) => (
                    <option key={salesOrg} value={salesOrg}>
                      {salesOrg}
                    </option>
                  ))}
              </>
            </CustomDropdown>
          ) : (
            <CustomInput
              width="85%"
              label="Sales Org"
              aria-label="Sales Org"
              marginBottom="0.3rem"
              disabled={true}
              error={salesOrg.error}
              errorText={salesOrg.errorText}
              value={salesOrg.value}
            />
          )}

          <CustomDropdown
            width="61%"
            label="Contract Account"
            aria-label="Contract Account"
            errorText={contractAccount.errorText}
            marginBottom="0.3rem"
            disabled={isFieldsDisabled || jobData?.isC4SWorkTicket}
            error={contractAccount.error}
            // value={contractAccount.value ? contractAccount.value : "Please Select"}
            value={contractAccount.value}
            onChange={(event) => handleFields("Contract Account", event.target.value)}
          >
            <>
              <option disabled>Please Select</option>

              {contractAccountList &&
                contractAccountList?.map((ca) => (
                  <option key={ca} value={ca}>
                    {ca}
                  </option>
                ))}
            </>
          </CustomDropdown>

          <CustomDropdown
            width="61%"
            label="Division"
            aria-label="Division"
            errorText={division.errorText}
            marginBottom="0.3rem"
            disabled={isFieldsDisabled || jobData?.isC4SWorkTicket}
            error={division.error}
            value={division.value ? division.value : "Please Select"}
            onChange={(event) => handleFields("Division", event.target.value)}
          >
            <>
              <option disabled>Please Select</option>
              {DIVISION_DROPDOWN_VALUES.map((div) => (
                <option key={div.code} value={div.code}>{`${div.code} - ${div.description}`}</option>
              ))}
            </>
          </CustomDropdown>
          <CustomDropdown
            width="61%"
            label="Distribution Channel"
            aria-label="Distribution Channel"
            errorText={distributionChannel.errorText}
            marginBottom="0.3rem"
            disabled={isFieldsDisabled || jobData?.isC4SWorkTicket}
            error={distributionChannel.error}
            value={distributionChannel.value ? distributionChannel.value : "Please Select"}
            onChange={(event) => handleFields("Distribution Channel", event.target.value)}
          >
            <>
              <option disabled>Please Select</option>
              {DISTRIBUTION_CHANNEL_DROPDOWN_VALUES.map((dist) => (
                <option key={dist.code} value={dist.code}>{`${dist.code} - ${dist.description}`}</option>
              ))}
            </>
          </CustomDropdown>
          {source.source === "case" ? (
            <CustomDropdown
              width="61%"
              label="Job Type"
              aria-label="Job Type"
              errorText="Select a Job Type"
              marginBottom="0.3rem"
              disabled={isFieldsDisabled}
              disableValue={!jobType}
              error={false}
              value={jobType ? jobType : "Please Select"}
              onChange={(event) => setJobType(event.target.value)}
            >
              <>
                <option disabled style={{ backgroundColor: "lightgrey" }}>
                  Please Select
                </option>
                <option key="Support/RMA" value="Support/RMA">
                  Support/RMA
                </option>
                <option key="Support/De-Re" value="Support/De-Re">
                  Support/De-Re
                </option>
                <option key="3G-4G/Migration" value="3G-4G/Migration">
                  3G-4G/Migration
                </option>
              </>
            </CustomDropdown>
          ) : (
            <CustomInput
              width="85%"
              type="text"
              readOnly={true}
              aria-label="Job Type"
              label="Job Type"
              paddingRight="5px"
              value={jobType}
              disabled={isFieldsDisabled}
            />
          )}
          {jobData && completedStatuses.includes(jobData.status) && (
            <CustomInput
              width="76%"
              type="text"
              label="Existing PO"
              aria-label="Existing PO"
              disabled={isFieldsDisabled}
              error={existingPO.error}
              errorText={existingPO.errorText}
              success={existingPO.success}
              value={existingPO.value}
              onChange={(event) => handleFields("Existing PO", event.target.value)}
            />
          )}
          <CalendarWrapper>
            <CustomInput
              width="88.5%"
              type="calendar"
              label="Select Job Start Date"
              aria-label="Select Job Start Date"
              disabled={isFieldsDisabled}
              error={startDate.error}
              errorText={startDate.errorText}
              success={startDate.success}
              value={startDate.value ? moment(startDate.value, "MM/DD/YYYY").format("MMM D, yyyy") : ""}
              selectedDate={startDate.value ? new Date(startDate.value) : new Date(nextday)}
              minDate={scheduleType === "AUTO" ? new Date(handleStartDate()) : new Date(nextday)}
              onChange={(event) => handleFields("Start Date", event)}
            />
          </CalendarWrapper>
          <CustomInput
            width="75%"
            type="text"
            label="Vehicle Count"
            aria-label="Vehicle Count"
            helperText="Enter a whole number."
            disabled={isFieldsDisabled}
            error={vehicleCount.error}
            errorText={vehicleCount.errorText}
            success={vehicleCount.success}
            value={vehicleCount.value}
            onChange={(event) => handleFields("Vehicle Count", parseInt(event.target.value))}
          />
          <SmallText color={!isAddServices && isFieldsDisabled ? "#d8dada" : ""}>Job Length (Hours)</SmallText>
          <FlexRow>
            {jobLengthHours.jobLengthHours > 0 && (
              <MediumText color={!isAddServices && isFieldsDisabled ? "#d8dada" : ""} css={{ marginRight: "1rem" }}>
                {jobDurationFormatter(jobLengthHours.jobLengthHours)}
              </MediumText>
            )}
            <TextLink
              role="link"
              ariaLabel={jobLengthHours.jobLengthHours ? `Recalculate` : `Calculate`}
              disabled={isAddServices ? false : isFieldsDisabled || !jobType}
              onClick={() => (source.source === "csp" ? setCSPJobLengthModal(true) : setCaseJobLengthModal(true))}
            >
              {jobLengthHours.jobLengthHours ? `Recalculate` : `Calculate`}
            </TextLink>
          </FlexRow>
        </div>
      </FlexRow>
      <FlexRow css={{ marginTop: "1rem" }}>
        {isAddServices ? (
          <SLButton
            aria-label="Submit"
            role="button"
            onClick={addServicesJob}
            disabled={isAddServices && !jobLengthHours.jobLengthHours ? true : false}
            aria-disabled={isAddServices && !jobLengthHours.jobLengthHours ? true : false}
          >
            Submit
          </SLButton>
        ) : (
          <SLButton
            aria-label="Check availability"
            role="button"
            onClick={handleSubmit}
            disabled={checkAvailabilityIsDisable}
            aria-disabled={checkAvailabilityIsDisable}
          >
            Check availability
          </SLButton>
        )}
        <SLButton aria-label="Cancel" role="button" secondary onClick={() => switchTab(2)}>
          Cancel
        </SLButton>
      </FlexRow>
    </>
  );
}

export const CreateNewJobMetadata = {
  name: "Create New Job",
  id: "create_new_job",
  component: lazy(() => import("./CreateNewJob")),
  route: "/create_new_job",
};
