import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalTitle, ModalFooter } from "@vds/modals";
import { Dropdown } from "primereact/dropdown";
import { SLButton } from "../../components/core/Controls";
import Icon from "@vds/icons";
import { StyledModal } from "./RescheduleModal";
import moment from "moment";
import { FlexRow, SmallText, Spacer } from "../../components/core/Utility/index";
import { SLNotification } from "../../components/core/utility";
import { TextArea } from "@vds/inputs";
import { Title } from "@vds/typography";
import { CustomisedInput } from "./CompleteJob";
import { generate } from "shortid";
import { css } from "@emotion/react";

export const CustomisedNotification = css`
  & span:last-child {
    max-width: fit-content;
  }
`;

const ConfirmationModal = ({ setConfirmModal, type, reschedule, selectedSlot, timezone, handleSupplyNotes }) => {
  const [disabled, setDisabled] = useState(true);
  const [notesToTech, setNotesToTech] = useState("");
  const [notesToVendor, setNotesToVendor] = useState("");
  const [initialCancelReason, setInitialCancelReason] = useState("");
  const [warning, setWarning] = useState(null);

  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === "Escape") {
        setConfirmModal(null);
      }
    };
    window.addEventListener("keydown", handleEscape);
    return () => window.removeEventListener("keydown", handleEscape);
  }, []);

  useEffect(() => {
    setWarning(
      <SLNotification
        type="warning"
        title="Please ensure notes to the vendor/technician are captured. You will not be able to edit it after the Job is created."
        key={generate()}
        hideCloseButton={true}
        css={CustomisedNotification}
      />
    );
  }, []);

  const handleNotes = (techNotes, vendorNotes) => {
    techNotes.length < 1 || vendorNotes.length < 1 || techNotes.length > 500 || vendorNotes.length > 500 ? setDisabled(true) : setDisabled(false);
    setNotesToTech(techNotes);
    setNotesToVendor(vendorNotes);
  };

  const initialCancelReasonList = [
    "Checking availability for date/time",
    "Checking for Installer Coverage Area",
    "Enter Incorrect Time/Date/Duration",
    "Date/Time didn't work out for Customer",
    "Customer not ready for schedule",
  ];
  return (
    <Modal
      role="dialog"
      ariaLabel={
        type === "schedule"
          ? `Schedule Job?`
          : type === "reschedule"
          ? `Reschedule job?`
          : reschedule
          ? `Cancel rescheduling job?`
          : `Cancel scheduling job?`
      }
      hideCloseButton={false}
      opened={true}
      disableOutsideClick
      css={StyledModal}
      closeButton={
        <FlexRow justifyContent="space-between" onClick={(event) => event.preventDefault()}>
          <Title size="medium">
            {type === "schedule"
              ? `Schedule Job?`
              : type === "reschedule"
              ? `Reschedule job?`
              : reschedule
              ? `Cancel rescheduling job?`
              : `Cancel scheduling job?`}
          </Title>

          <div onClick={() => setConfirmModal(null)} style={{ marginRight: "1rem", marginTop: "0.3rem" }}>
            <Icon
              role="img"
              aria-label="close"
              alt="close-icon"
              name="close"
              size="medium"
              color="#000000"
              style={{ cursor: "pointer", outline: "none" }}
            />
          </div>
        </FlexRow>
      }
    >
      <ModalBody css={{ paddingLeft: "0", paddingRight: "0" }}>
        {type === "cancel" ? (
          <>
            <FlexRow justifyContent="space-between" marginBottom="8.5rem">
              <SmallText>Please Provide Reason for Abandoning Job</SmallText>
              <Dropdown
                aria-label="Please Provide Reason for Abandoning Job"
                value={initialCancelReason || "Please select"}
                options={initialCancelReasonList}
                onChange={(e) => setInitialCancelReason(e.target.value)}
                css={[CustomisedInput, { width: "71%", marginTop: "0.2rem" }]}
                scrollHeight="7.5rem"
                placeholder="Please select"
              />
            </FlexRow>
            <SmallText fontWeight="normal">You will lose any changes you made</SmallText>
          </>
        ) : type === "schedule" ? (
          <>
            {warning}
            <Spacer top="8px" />
            <SmallText fontWeight="normal">
              The job will be scheduled for {moment(selectedSlot.availableDate).format("MMM D, yyyy")} at{" "}
              {moment(selectedSlot.slotStartTime, "HHmm").format("h.mm A")} {timezone}.
            </SmallText>
            <Spacer top="10px" />
            <TextArea
              label="Notes To Technician"
              aria-label="Notes To Technician"
              required={true}
              helperText="Notes will be sent to the technician"
              helperTextPlacement="bottom"
              maxLength={500}
              value={notesToTech}
              onChange={(e) => handleNotes(e.target.value, notesToVendor)}
              css={{
                fontSize: "12px",
                fontFamily: "Verizon-NHG-eTX",
                letterSpacing: "0",
              }}
            />
            <Spacer top="10px" />
            <TextArea
              label="Notes To Vendor"
              aria-label="Notes To Vendor"
              required={true}
              helperText="Notes will be sent to the vendor"
              helperTextPlacement="bottom"
              maxLength={500}
              value={notesToVendor}
              onChange={(e) => handleNotes(notesToTech, e.target.value)}
              css={{
                fontSize: "12px",
                fontFamily: "Verizon-NHG-eTX",
                letterSpacing: "0",
              }}
            />
          </>
        ) : (
          <>
            <SmallText fontWeight="normal">
              The job will be rescheduled for {moment(selectedSlot.availableDate).format("MMM D, yyyy")} at{" "}
              {moment(selectedSlot.slotStartTime, "HHmm").format("h.mm A")} {timezone}
            </SmallText>
          </>
        )}
      </ModalBody>
      {/* <ModalFooter> */}
      <FlexRow css={{ justifyContent: "flex-end" }}>
        <SLButton
          css={{ width: "10rem", marginLeft: "auto" }}
          size="large"
          aria-label={type === "cancel" ? `Yes` : `Confirm`}
          role="button"
          disabled={type !== "cancel" ? disabled : false}
          aria-disabled={type !== "cancel" ? disabled : false}
          onClick={() => {
            setConfirmModal(null);
            handleSupplyNotes(type === "cancel" ? initialCancelReason : notesToTech, notesToVendor);
          }}
        >
          {type === "cancel" ? `Yes` : `Confirm`}
        </SLButton>
        <SLButton
          aria-label={type === "cancel" ? `No` : `Cancel`}
          role="button"
          secondary
          css={{ width: "10rem" }}
          size="large"
          onClick={() => setConfirmModal(null)}
        >
          {type === "cancel" ? `No` : `Cancel`}
        </SLButton>
      </FlexRow>
      {/* </ModalFooter> */}
    </Modal>
  );
};
export default ConfirmationModal;
